import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { Grid, TextField, Divider } from '@mui/material';
import { endpoints } from '../../hooks/use-api';
import { useNotification } from '../../hooks/use-notification';
import axios from 'axios'
import EntityHistory from './EntityHistory'
import { useDebouncePost } from '../../hooks/use-api';
export const AddorEditNote = ({ entity_id,  showAddButton, label, required = false }) => {

  const [addNoteMode, setAddNoteMode] = useState({
    showNote: false,
    required: required,
    noteLabel: label
  });
 
  const [history, setHistory] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(()=>{
    if(isInitialLoad){
    axios
    .get(endpoints.entity.entityhistory.get(entity_id))
    .then((response) => {
      console.log(response);
      setHistory(response.data)
      setIsInitialLoad(false)
    })
    .catch((response) => {
      return null
    })
  }}
  , [isInitialLoad])

  const { set: setNotification } = useNotification();
  const [actionNote, setActionNote] = useState("");

  const saveNote = (id, action) => {
    if (action) {
      axios
        .post(endpoints.entity.entityhistory.update(id), action)
        .then((response) => {
          //handle success
          console.log(response);
          setNotification({
            message: 'Note updated',
              type: 'success',
          })
          if(response.data){
            setHistory(response.data)
            setAddNoteMode({ showNote: false, required: true, noteLabel: "Type the note" })
            setActionNote("");
          } else{
            console.log(response);
            setNotification({
              message: `Failed to update: ${response.message}`,
              type: 'error',
            });
            setAddNoteMode({ showNote: true, required: true, noteLabel: "Type the note" })
          }
        })
        .catch((response) => {
          //handle error
          console.log(response);
          setNotification({
            message: `Failed to update: ${response.message}`,
            type: 'error',
          });
          setAddNoteMode({ showNote: true, required: true, noteLabel: "Type the note" })
        })
    }
    else {
      setNotification({
        message: "Nothing to Save",
        type: "error",
      });
    }
  }
  return (
    <React.Fragment>
      <React.Fragment>
        {showAddButton && !addNoteMode.showNote &&
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
              <Button variant='contained' fullWidth
                color="primary" size='large'
                onClick={() => setAddNoteMode({
                  showNote: true,
                  required: true, noteLabel: "Type the note", action: "addNoteOnly"
                })}>
                Add Note
              </Button>
            </Grid>
          </Grid>
        }
      </React.Fragment>
      <React.Fragment>
        {addNoteMode.showNote &&
          <Grid container spacing={1} style={{ marginTop: 10 }} >
            <Grid item xs={12}>
              <Divider style={{ marginTop: '10px', marginBottom: '12px' }} />
              <TextField
                label={addNoteMode.noteLabel}
                required={addNoteMode.required}
                fullWidth
                multiline
                minRows={4}
                defaultValue={actionNote}
                onChange={(e) => setActionNote(e.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
                <Button variant='contained' size="large" color='primary'  disabled={actionNote.length == 0} onClick={() => saveNote(entity_id, { note: actionNote })}>Save Note</Button>
            </Grid>
            <Grid item xs={9}>
              <Button variant='contained' size="large" color='primary'  onClick={() => setAddNoteMode({ showNote: false, required: true, noteLabel: "Type the note" })}>Cancel</Button>
            </Grid>
          </Grid>
        }
      </React.Fragment>

      <React.Fragment>
        {
          <Grid container spacing={1} style={{ marginTop: 10 }} >
            <Grid item xs={12}>
              <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
              <EntityHistory action={history} />
            </Grid>
          </Grid>
        }
      </React.Fragment>
    </React.Fragment>
  )
}

export default AddorEditNote;