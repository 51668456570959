import React, { useState } from "react";
import MultiStepForm from "../../DataEntry/MultiStepForm";
import ApplicantForm from "./Applicant";
import ApplicantFinancial from "./Financial";
import { getEmptyVoucher } from "./Model";
import PreQualForm from "./PreQual";
import FinalPreview from "./Preview";
import { MultiFormContext } from "../../DataEntry/MultiFormContext";
import Payment from "./Payment";

const steps = [
  "Prequalification",
  "Applicant Info",
  "Financial Info",
  "Review",
];

const MainForm = ({ individualApplication, indUser, onExit, onClose }) => {

  const [paymentInfo, setPaymentInfo] = useState({paymentRequired: false, paymentUrl: ""});
  const [formCompleted, setFormCompleted] = React.useState(false);

  return (
    <React.Fragment>
      {!formCompleted &&
        <MultiStepForm
          title="Create Voucher Application"
          steps={steps}
          initialValues={getEmptyVoucher(individualApplication, indUser)}
          showLogo={individualApplication}
          onClose={onClose}
          lastStepButtonLabel="Save Application and Continue to Payment"
        >
          {(step, page, formProps) => {
            switch (step) {
              case 0:
                return <MultiFormContext.Consumer>
                  {value => <PreQualForm page={page} formProps={formProps} multiStepState={value} />}
                </MultiFormContext.Consumer>
              case 1:
                return <ApplicantForm page={page} formProps={formProps} />;
              case 2:
                return <ApplicantFinancial page={page} formProps={formProps} />;
              default:
                return <FinalPreview page={page} formProps={formProps} onExit={(paymentRequired, paymentUrl) => {
                  setFormCompleted(true);
                  setPaymentInfo({paymentRequired, paymentUrl});
                }} />;
            }
          }}
        </MultiStepForm>
      }
      {formCompleted &&
        <Payment paymentRequired={paymentInfo.paymentRequired} paymentUrl={paymentInfo.paymentUrl} onExit={onExit} />
      }
    </React.Fragment>
  );
};

export default MainForm;
