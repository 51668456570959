import { Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { StateOptions } from "../../../../common/Constants";
import { BoxRow } from "../../../../components/DataEntry/StyledFields";
import {
  MoveNext,
  useMultiStepForm,
} from "../../../DataEntry/MultiFormContext";
import { VoucherValidator } from "../Model";
import { AutoCompleteRow, DateRow } from "../WizardPage";

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: ['"Times New Roman"'].join(","),
    fontSize: "1.3em",
    fontWeight: "bold",
    marginBottom: "24px",
  },
  splText: {
    fontFamily: ['"Times New Roman"'].join(","),
    marginBottom: "16px",
    fontWeight: "510",
  },
  subHeader: {
    fontFamily: ['"Times New Roman"'].join(","),
    fontSize: "0.9em",
    marginTop: "8px",
    marginBottom: "8px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
  },
}));

const visionFieldDef = {
  birthDate: {
    id: 40,
    label: "Applicant Date of Birth",
    property: "birth_date",
  },
  state: {
    id: 41,
    label: "Where does applicant reside currently?",
    property: "state",
  },
};

const VisionPreQualification = ({
  data,
  setFieldValue,
  setValues,
  isSubmitting,
  submitForm,
}) => {
  const classes = useStyles({});
  const { setEnableNext } = useMultiStepForm();

  useEffect(() => {
    console.log(data);
    if (
      isSubmitting &&
      // !VoucherValidator.isVisionTestQualifies(data) &&
      VoucherValidator.hasValidBirthDate(data) &&
      VoucherValidator.hasValidState(data)
      // && VoucherValidator.hasSph(data)
    ) {
      setEnableNext(MoveNext.Page);
    }
  }, [data, isSubmitting]);

  const InputField = ({ id, label, ...restProps }) => (
    <TextField
      id={id}
      name={id}
      label={label}
      type="number"
      value={data[id]}
      variant="outlined"
      size="small"
      className={classes.textField}
      disabled={data.visionQualResults.isVisionTestQualifies}
      onChange={(e) => {
        setFieldValue(id, e.target.value !== null ? e.target.value : "");
      }}
      {...restProps}
    />
  );

  return (
    <div>
      <BoxRow>
        <Box sx={{ fontWeight: "bold", my: 4 }}>
          Let's see if you qualify for the online vision test.
        </Box>
        <DateRow
          item={visionFieldDef.birthDate}
          min={100}
          onChangeOverride={(updatedItem) =>
            setValues({
              ...data,
              ...updatedItem,
              applicant: { ...data.applicant, ...updatedItem },
            })
          }
        />
        <AutoCompleteRow
          item={visionFieldDef.state}
          options={StateOptions}
          onChange={(updatedItem) =>
            setValues({
              ...data,
              ...updatedItem,
              applicant: {
                ...data.applicant,
                ...{ state: updatedItem.state.value },
              },
            })
          }
        />
        {/* <Typography variant="h6">Enter prescription details</Typography>
        <Box sx={{ fontWeight: "light" }}>Left Eye (OD)</Box>
        <BoxItem>
          <SpinnerTextInput
                  id="sph_left"
                  label="SPH"
                  value={data["sph_left"]}
                  step={0.25}
                  min={-12}
                  max={12}
                  setValue={(newVal) => {
                    setFieldValue("sph_left", newVal !== null ? newVal : "");
                  }}
            />
            <SpinnerTextInput
              id="cyl_left"
              label="CYL"
              value={data["cyl_left"]}
              step={0.25}
              min={-6}
              max={0}
              setValue={(newVal) => {
                setFieldValue("cyl_left", newVal !== null ? newVal : "");
              }}
            />
            <SpinnerTextInput
              id="axis_left"
              label="AXIS"
              value={data["axis_left"]}
              step={1}
              min={0}
              max={180}
              setValue={(newVal) => {
                setFieldValue("axis_left", newVal !== null ? newVal : "");
              }}
            />

            <SpinnerTextInput
              id="add_left"
              label="ADD"
              value={data["add_left"]}
              step={0.25}
              min={0}
              max={4}
              setValue={(newVal) => {
                setFieldValue("add_left", newVal !== null ? newVal : "");
              }}
            />

            <SpinnerTextInput
              id="pd_left"
              label="PD (mm)"
              value={data["pd_left"]}
              step={0.5}
              min={10}
              max={50}
              setValue={(newVal) => {
                setFieldValue("pd_left", newVal !== null ? newVal : "");
              }}
            />            

        </BoxItem>
        <Box sx={{ mt: 2, fontWeight: "light" }}>Right Eye (OD)</Box>
        <BoxItem>
          <SpinnerTextInput
                id="sph_right"
                label="SPH"
                value={data["sph_right"]}
                step={0.25}
                min={-12}
                max={12}
                setValue={(newVal) => {
                  setFieldValue("sph_right", newVal !== null ? newVal : "");
                }}
          />
          <SpinnerTextInput
            id="cyl_right"
            label="CYL"
            value={data["cyl_right"]}
            step={0.25}
            min={-6}
            max={0}
            setValue={(newVal) => {
              setFieldValue("cyl_right", newVal !== null ? newVal : "");
            }}
          />
          <SpinnerTextInput
            id="axis_right"
            label="AXIS"
            value={data["axis_right"]}
            step={1}
            min={0}
            max={180}
            setValue={(newVal) => {
              setFieldValue("axis_right", newVal !== null ? newVal : "");
            }}
          />

          <SpinnerTextInput
            id="add_right"
            label="ADD"
            value={data["add_right"]}
            step={0.25}
            min={0}
            max={4}
            setValue={(newVal) => {
              setFieldValue("add_right", newVal !== null ? newVal : "");
            }}
          />

          <SpinnerTextInput
            id="pd_right"
            label="PD (mm)"
            value={data["pd_right"]}
            step={0.5}
            min={10}
            max={50}
            setValue={(newVal) => {
              setFieldValue("pd_right", newVal !== null ? newVal : "");
            }}
          />

        </BoxItem> */}
      </BoxRow>
    </div>
  );
};

export default VisionPreQualification;
