import { CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { addYears, differenceInMonths, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { ApplicantCard } from "../../../../common/AddressView";

// continueClicked
const ApplicantPanel = ({ data, onSelect }) => {
  const { applicant, matchApi } = data;
  const [entityAsync, setEntityAsync] = useState({
    loading: null,
    error: null,
    data: null,
  });

  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(-1);

  const [match, setMatch] = useState({
    applicant: null,
    lastVoucher: null,
    eligible: false,
  });

  useEffect(() => {
    if (entityAsync.data && entityAsync.data.length > 0) {
      var matchingApplicant = entityAsync.data[0];
      var lastVoucher = matchingApplicant.last_voucher || {
        id: 0,
        status: "rejected",
      };
      var whenLastVoucher =
        lastVoucher.id > 0
          ? differenceInMonths(
              new Date(),
              new Date(matchingApplicant.last_voucher.datetime)
            )
          : 12;

      // Applicant is eligible if we do not have a issued or pending voucher in last 12 months (rejected are also eligible)
      var eligible =
        lastVoucher.id === 0 ||
        lastVoucher.status === "rejected" ||
        whenLastVoucher >= 12;
      setMatch({
        applicant: matchingApplicant,
        lastVoucher: lastVoucher,
        eligible: eligible,
      });
      if (eligible) {
        setSelected(matchingApplicant.id);
      } else {
        setSelected(-1);
      }
    } else {
      setMatch({ applicant: null, lastVoucher: null, eligible: false });
    }
  }, [entityAsync]);

  useEffect(() => {
    //console.log("selected::" + selected)
    if (error) {
      setError(false);
    } else {
      if (selected !== -1) {
        onSelect(selected);
      }
    }
  }, [selected]);

  useEffect(() => {
    // console.log("applicant.id: ")
    // console.log(applicant)
    // if (applicant.id === -1) {
    if (selected === -1) {
      //console.log("useEffect selected")
      setEntityAsync({ data: null, loading: true, error: false });
      // postData({data: applicant})
      axios
        .post(matchApi, applicant)
        .then(function (response) {
          setEntityAsync({ data: response.data, loading: false, error: false });
          if (response.data.length === 0) {
            setSelected(0);
          }
        })
        .catch(function (error) {
          setEntityAsync({ data: null, loading: false, error: error });
        });
    }
  }, [applicant]);

  return (
    <>
      {entityAsync.loading && (
        <>
          {" "}
          <CircularProgress /> Checking if applicant already exists{" "}
        </>
      )}
      {entityAsync.error && <div>{entityAsync.error.message}</div>}
      {entityAsync.data && entityAsync.data.length === 0 && (
        <>
          <Typography variant="h5">
            Please continue if following information looks correct. Use the back
            button to fix the information.
          </Typography>
          <p>&nbsp;</p>
          <ApplicantCard {...applicant} />
          <p>&nbsp;</p>
        </>
      )}
      {entityAsync.data && match.applicant && (
        <>
          <Typography variant="h5">
            We found following applicant with same name and birthdate:
          </Typography>
          <p>&nbsp;</p>
          <ApplicantCard {...match.applicant} />
          <p>&nbsp;</p>

          {/* Already pending application for this applicant in last 12 months */}
          {match.eligible ? (
            <Typography variant="h5" color="error">
              <b>{match.applicant.name}</b> is eligible to apply for a new
              voucher. Please click continue.
            </Typography>
          ) : (match.lastVoucher.status === "pending_payment" && match.lastVoucher.status === "pending_approval") ? (
            <Typography variant="h5" color="error">
              <b>{match.applicant.name}</b> already have a voucher application
              pending (Voucher Number: {match.lastVoucher.id}, Date Applied:{" "}
              {format(new Date(match.lastVoucher.datetime), "MMM dd, yyyy")}).
              You can not apply for another application for this applicant at
              this time.
            </Typography>
          ) : (
            <Typography variant="h5" color="error">
              <p>
                <b>{match.applicant.name}</b> already have been issued a voucher
                (Voucher Number: <b>{match.lastVoucher.id}</b>) on{" "}
                <b>
                  {format(new Date(match.lastVoucher.datetime), "MMM dd, yyyy")}
                </b>
                .
              </p>
              <p>
                We issue one voucher per applicant per year. You can apply for a
                new voucher for {match.applicant.name} on{" "}
                {format(
                  addYears(new Date(match.lastVoucher.datetime), 1),
                  "MMM dd, yyyy"
                )}
              </p>
              <p>&nbsp;</p>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default ApplicantPanel;
