import { ListItem, Typography, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import React, { useState } from "react";
import { format } from 'date-fns'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const EntityHistoryList = ({ history }) => {
    const [check, useCheck] = useState(true)
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <NavigateNextIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                secondary={
                    <Typography variant='body2' component="span" style={{ marginLeft: '24px' }}>
                        {history.updatedBy}
                    </Typography>
                }
            >
                <Typography variant='body2' component="span" style={{ marginLeft: '24px' }}>
                    {format(new Date(history.datetime + ".000Z"), 'MMM dd, yyyy hh:mm aa')}
                </Typography>

                <Typography variant='body2' component="span" style={{ marginLeft: '24px' }} >
                    {history.note}
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

const EntityActionList = ({ action = [] }) => {
    if (action == [] || action == null) {
        return <div></div>
    }
    return (
        action.map((value, idx) => <EntityHistoryList history={value} />)
    )
}

export default EntityActionList;


