import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Paper, Typography} from '@mui/material';
import cx from 'classnames'
import {displayDate, EntityTypes, howLongAgo} from '../../common/Constants'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import {ApplicantCard} from '../../common/AddressView'

/*
leftText:
date:
subText
status
note/error
*/

const Card1 = ({title, t1, d1, t2, t3, t4, status, note}) => {
    return (
      <React.Fragment>
        <Grid container>
            <Grid item xs={7}>
                <Typography variant='button' color='textSecondary'>{title}</Typography>                
            </Grid>
            <Grid item xs={5}>
              <Typography variant='button' className={cx("voucher-status", status)}>{ status }</Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography variant='subtitle2'>{ t1 }</Typography>
                {t4 && <Typography variant='caption'>{ t4 }</Typography>}
            </Grid>
            <Grid item xs={5}>
                <Grid item xs={12}>
                  {/* <Typography variant='caption' color='textSecondary'>{ format(parseISO(d1), 'MMM dd, yyyy') }</Typography> */}
                  <Typography variant='caption' color='textSecondary'>{ displayDate(d1) }</Typography>
                  <Typography variant='subtitle2'>{ t2 }</Typography>
                  {/* {status && <Typography variant='button' className={cx("voucher-status", status)}>{ status }</Typography>} */}
                  {t3 && <Typography variant='subtitle2'>{ t3 }</Typography>}                  
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} style={{ color: status === 'rejected' ? 'red' : 'inherit' }}>
                { note && <span className="text-small text-info">{ note }</span> }
            </Grid>
        </Grid>
      </React.Fragment>
    )    
}

//?? Unify: name, full_name, first_name, last_name
const AddressCard = ({id, name, first_name, last_name, address1, city, state, zip, phone, phone_extension}) => {
  name = first_name ? `${first_name} ${last_name}` : name;
  return (
      <Grid container>
          <Grid item xs={12}>
              <Typography variant='subtitle2'>{ name }</Typography>
          </Grid>
          <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{ address1 }</Typography>
          </Grid>
          <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{ city }, { state } { zip }</Typography>
          </Grid>
          <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{ phone }{phone_extension ? (' Ext. ' + phone_extension) : '' }</Typography>
          </Grid>                
      </Grid>
  )  
}
// include first registration date to agency card
const AgencyCard = ({id, name, first_name, last_name, address1, city, state, zip, phone, phone_extension, createdOn, required_invoice}) => {
  name = first_name ? `${first_name} ${last_name}` : name;
  return (
      <Grid container>
            <Grid item xs={8}>
              <Typography variant='button' color='textSecondary'> Agency ID: {id} </Typography>                
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle2'  color='textSecondary'>{ displayDate(createdOn) }</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>{name}</Typography>
            </Grid>
            {/* <Grid item xs={12}>
                  <Typography variant='subtitle2'>{  displayDate(createdOn)}</Typography>
              </Grid> */}
            <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{address1}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{city}, {state} {zip}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{phone}{phone_extension ? (' Ext. ' + phone_extension) : ''}</Typography>
            </Grid>
      </Grid>
      <Grid item xs={3} alignSelf="flex-end">
        {required_invoice && <Typography variant='subtitle2' color='textSecondary'>Invoice required</Typography>}
        {!required_invoice && <Typography variant='subtitle2' color='textSecondary'>Pay as you go</Typography>  }
      </Grid>      
      </Grid>
  )  
}

const UserCard = ({tt, name, first_name, last_name, email, phone, phone_extension, is_admin, createdOn}) => {
  name = first_name ? `${first_name} ${last_name}` : name;
  console.log(tt)
  return (
      <Grid container>
          <Grid item xs={10}>
              <Typography variant='subtitle2'>{ name }</Typography>
          </Grid>
          <Grid item xs={2}>
              {is_admin && <SupervisorAccountIcon />}
              {!is_admin && <PersonIcon />}
          </Grid>                    
          <Grid item xs={9}>
              <Typography variant='subtitle2' color='textSecondary'>{ email }</Typography>
          </Grid>
          {/* march 2023 enhancement include user first created on */}
          <Grid item xs={3}>
          <Typography variant='caption' color='textSecondary'>{ displayDate(createdOn) }</Typography>
          </Grid>
          <Grid item xs={9}>
              <Typography variant='subtitle2' color='textSecondary'>{ phone }{phone_extension ? (' Ext. ' + phone_extension) : '' }</Typography>
          </Grid>
          <Grid item xs={3}>
              {tt === EntityTypes.pendingUser && <Typography variant='subtitle2' color='error'>{ howLongAgo(createdOn) }</Typography>}
          </Grid>                    
      </Grid>
  )  
}

const CognitoCard = ({name, email, phone_number, email_verified, user_status, updatedOn}) => {
  return (
      <Grid container>
          <Grid item xs={8}>
              <Typography variant='subtitle2'>{ name }</Typography>
          </Grid>
          <Grid item xs={4}>
              {user_status}
          </Grid>                    
          <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{ email }</Typography>
          </Grid>
          <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>{ phone_number }</Typography>
          </Grid>                
      </Grid>
  )  
}

const TextCard = ({title}) => {
  return (
      <Grid container>
          <Grid item xs={12}>
              <Typography variant='subtitle2'>{ title }</Typography>
          </Grid>
      </Grid>
  )  
}

const EmptyItem = { id: 0 }

const CardGrid = ({ itemsAsync, itemsFn = data => data, itemfn, onSelect, itemTypeFn, gridHeader,
    selectedItemFn,
    emptyCardText }) => {

  const [displayCount, setDisplayCount] = useState(100)

  const [lastClickedItem, setLastClickedItem] = useState()

  useEffect(() => {
    if (itemsAsync.data) {
      let total = itemsAsync.data.length
      setDisplayCount(total > 100 ? 100: total)
    } else {
      setDisplayCount(100)
    }

    // Check if any item should be selected by default
    if (selectedItemFn) {
      // console.log("selectedItemFn")
      // console.log(itemsAsync.data)
      setLastClickedItem(itemsAsync.data.find(item => selectedItemFn(item)))
    }

  }, [itemsAsync.data])

//  console.log(displayCount)

  return (
      <React.Fragment>
        {itemsAsync.loading && <CircularProgress />}
        {itemsAsync.error && <div>{itemsAsync.error.message}</div>}
        {itemsAsync.data && (
          <Grid
            container
            spacing={3}
            justifyContent={'flex-start'}
            style={{ padding: '24px' }}
          >
            {gridHeader && gridHeader(itemsAsync.data)}
            {itemsFn(itemsAsync.data).slice(0, displayCount).map((item, index) => (
              <Grid key={index} item>
                <Paper onClick={() => { setLastClickedItem(item); onSelect && onSelect(item) } } className={cx("voucher-card", { clickable: true, selected: lastClickedItem === item })}>
                  {itemTypeFn(item) === EntityTypes.voucher && <Card1 {...itemfn(item)} />}
                  {(itemTypeFn(item) === EntityTypes.user || itemTypeFn(item) === EntityTypes.pendingUser) && <UserCard tt={itemTypeFn(item)} {...itemfn(item)} />}
                  {itemTypeFn(item) === EntityTypes.applicant && <ApplicantCard {...itemfn(item)} />}
                  {itemTypeFn(item) === EntityTypes.agency && <AgencyCard {...itemfn(item)} />}
                  {itemTypeFn(item) === EntityTypes.dispenser && <AddressCard {...itemfn(item)} />}
                  {itemTypeFn(item) === EntityTypes.cognito && <CognitoCard {...itemfn(item)} />}
                </Paper>
              </Grid>
            ))}
            {emptyCardText &&
              <Grid item>
                <Paper onClick={() => { setLastClickedItem(EmptyItem); onSelect && onSelect(EmptyItem) } } className={cx("voucher-card", { clickable: true, selected: lastClickedItem === EmptyItem })}>
                  <TextCard title={emptyCardText} />
                </Paper>
              </Grid>
            }
            {itemsAsync.data.length > displayCount && <Button onClick={() => setDisplayCount(displayCount => displayCount + 100)} color="primary">Show more</Button>}
          </Grid>
        )}
      </React.Fragment>
  );
};

export default CardGrid;

export { AddressCard, TextCard }