import React, {useState} from 'react'

import { Checkbox, CircularProgress, Divider, Grid, Typography, TextField } from '@mui/material';
import Button from '@mui/material/Button';

import OutlinedDiv from '../../common/OutlinedDiv';
import OneAgency from './OneAgency';

import EntitySearch from '../Entities/EntitySearch'
import {endpoints, useApi} from '../../hooks/use-api';
// import AgencySelector from './AgencySelector'
import TextSearch from '../../common/TextSearch';
import {EntityHome} from '../Entities';
import {EntityTypes} from '../../common/Constants'
import {AddressView1} from '../../common/AddressView';
import axios from 'axios'
import {useNotification} from '../../hooks/use-notification';
import AddorEditNote from '../Entities/EditNote';

const AgencyInvoicePanel = ({ agency, onClose, onCancel, reload }) => {

  const { set: setNotification } = useNotification()
  const [ackChecked, setAckChecked] = useState(false)
  const [actionNote, setActionNote] = useState("")
  const { required_invoice } = agency

  const invoiceFn = (invoice_on) => {
    setAckChecked(true)
    axios({
      method: 'post',
      url: endpoints.entity.agency.updateInvoiceMode(agency.id),
      data: {
        note: actionNote,
        required_invoice: invoice_on
      }
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setNotification({
          message: 'Agency invoiced',
          type: 'success',
        });
        reload()
        onClose()
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setNotification({
          message: `Failed to invoice agency: ${response.message}`,
          type: 'error',
        });
      });
  }

  return (
  <>
    {! required_invoice &&
      <>
        <Checkbox onChange={e => setAckChecked(e.target.checked)} /> <strong>I understand following statements:</strong>
        <ol>
          <li><strong>{agency.name}</strong> will not be asked for the application fee at the time of the evoucher
            creation.</li>
          <li>The agency will be invoiced for the application fee externally by the New Eyes staff at
            regular interval.</li>
          <li>Tracking of the payment will be done externally.</li>
        </ol>
        <TextField
          label="Provide detail notes of the communication with the agency"
          required={true}
          fullWidth
          multiline
          minRows={4}
          onChange={(e) => setActionNote(e.target.value)}
          margin="dense"
          variant="outlined"
        />
      
        <Grid container direction="row" justifyContent="space-around" mt="20px" >
          <Button disabled={!ackChecked || actionNote === ""} variant='outlined' size="large" color='primary' onClick={() => invoiceFn(true)}>
            SET INVOICE MODE FOR PAYMENT
          </Button>
          <Button variant='contained' size="large" color='primary' onClick={onCancel}>Cancel</Button>
        </Grid>
      </>
    }
      {required_invoice && <>
        <Checkbox onChange={e => setAckChecked(e.target.checked)} /> <strong>I understand following statements:</strong>
        <ol>
          <li><strong>{agency.name}</strong> must pay everytime for voucher creation.</li>
          <li>The previous vouchers made while in invoice mode stays the same and need to be invoiced.</li>
          <li>The settings will effect going forward.</li>
        </ol>
        <TextField
          label="Provide detail notes of the communication with the agency"
          required={true}
          fullWidth
          multiline
          minRows={4}
          onChange={(e) => setActionNote(e.target.value)}
          margin="dense"
          variant="outlined"
        />

        <Grid container direction="row" justifyContent="space-around" mt="20px" >
          <Button disabled={!ackChecked || actionNote === ""} variant='outlined' size="large" color='primary' onClick={() => invoiceFn(false)}>
            SET PAY AS YOU GO FOR PAYMENT
          </Button>
          <Button variant='contained' size="large" color='primary' onClick={onCancel}>Cancel</Button>
        </Grid>
      </>}
    </>
  )
}

const AgencyMigratePanel = ({agency, onClose, reload}) => {

    const { set: setNotification } = useNotification()
    const [agencyAsync, setAgencyAsync] = useState({loading: null, error: null, data: null})

    const [toAgency, setToAgency] = useState()

    const [disableMigrate, setDisableMigrate] = useState(true)

    const migrateFn = () => {
        setDisableMigrate(true)
        axios({
            method: 'post',
            url: endpoints.entity.agency.migrateAgency(agency.id, toAgency.id)
          })
          .then(function (response) {
              //handle success
              console.log(response);
              setNotification({
                  message: 'Agency migrated',
                  type: 'success',
              });
              reload()
              onClose()
          })
          .catch(function (response) {
              //handle error
              console.log(response);
              setNotification({
                  message: `Failed to migrate agency: ${response.message}`,
                  type: 'error',
              });              
          });
    }

    return (
        <>
            <Typography variant='h6' color='textSecondary'>Migrate to</Typography>
            <br />
            <br />
            {!toAgency &&
                <>
                    <TextSearch
                        placeholder="Agency name"
                        label="Search agency by name, city, zip, street..."
                        apiFn={endpoints.entity.agency.search}
                        dataFn={text => {return {searchText: text}}}
                        resultsFn={itemsAsync => setAgencyAsync(itemsAsync)}
                    />
                    <EntityHome
                        title="Select Agency"
                        entity_type={EntityTypes.agency}
                        entityAsync={agencyAsync}
                        onSelect={item => setToAgency(item)}
                    />
                </>
            }
            {toAgency &&
                <>
                    <AddressView1 { ...toAgency } />

                    <Checkbox onChange={e => setDisableMigrate(!e.target.checked)} /> I acknowledge that
                    <br /> 1) <strong>{agency.name}</strong> agency will be permantently deleted
                    <br /> 2) All of its users, applicants and vouchers will be permanently moved to agency <strong>{toAgency.name}</strong>
                    <br />
                    <br />
                    <Button disabled={disableMigrate} variant='outlined' size="large" color='primary' onClick={migrateFn}>
                        Migrate
                    </Button>
                </>
            }
        </>
    )
}

const AgencyDeletePanel = ({agency, onClose, reload}) => {
    const { set: setNotification } = useNotification()
    const [disableDelete, setDisableDelete] = useState(true)

    const deleteFn = () => {
        setDisableDelete(true)
        axios({
            method: 'delete',
            url: endpoints.entity.agency.deleteOne(agency.id)
          })
          .then(function (response) {
              //handle success
              console.log(response);
              setNotification({
                  message: 'Agency deleted',
                  type: 'success',
              });
              reload()
              onClose()
          })
          .catch(function (response) {
              //handle error
              console.log(response);
              setNotification({
                  message: `Failed to delete agency: ${response.message}`,
                  type: 'error',
              });              
          });
    }

    return (
        <>
            <Checkbox onChange={e => setDisableDelete(!e.target.checked)} /> I acknowledge that
            <br /> 1) <strong>{agency.name}</strong> agency will be permantently deleted
            <br /> 2) All of its users will be permanently removed from the agency
            <br /> 3) All the applicants and vouchers will still be searchable to new eyes user
            <br />
            <Button disabled={disableDelete} variant='outlined' size="large" color='error' onClick={deleteFn}>
                Delete permanently
            </Button>
        </>
    )
}

const AgentExtendPanel = ({agency, onClick, onClose, reload}) => {
    const [ { data: agencySummary, loading: agencySummaryLoading, error: agencySummaryError } ] = useApi(endpoints.entity.agency.loadSummary(agency.id));

    const [migrate, setMigrate] = useState(false)
  const [deleteAgency, setDeleteAgency] = useState(false)
  const [invoiceRequired, setInvoiceRequired] = useState(false)
  const { required_invoice } = agency
    
    return (
        <React.Fragment>
            { agencySummaryLoading && <CircularProgress /> }
            { agencySummaryError && <div>{agencySummaryError.message}</div> }
            { agencySummary &&
                <>
                    {deleteAgency &&
                        <>
                            <AgencyDeletePanel agency={agency} onClose={onClose} reload={reload} />
                        </>
                    }
                    {migrate &&
                        <>
                            <AgencyMigratePanel agency={agency} onClose={onClose} reload={reload} />
                        </>
          }
          {invoiceRequired &&
            <>
            <AgencyInvoicePanel agency={agency} onClose={onClose} reload={reload} onCancel={() => setInvoiceRequired(false)} />
            </>
          }
          {!migrate && !deleteAgency && !invoiceRequired && <React.Fragment>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                >
                                {/* <Button variant='outlined' size="large" color='primary' onClick={() => onClick(agency)}>AGENCY HOME</Button> */}
                                <Button  variant='contained'  fullWidth color="primary" style={{ marginTop: "10pxx" }} size="large" onClick={() => onClick(agency)}>AGENCY HOME</Button>

                            </Grid>
                            <Grid container spacing={1} style={{marginTop: 10}}>
                                <Grid item xs={6}>
                                    <OutlinedDiv label="Approved">{agencySummary.vouchers_recently_approved || 0}</OutlinedDiv>
                                </Grid>
                                <Grid item xs={6}>
                                    <OutlinedDiv label="Redeemed">{agencySummary.vouchers_recently_redeemed || 0}</OutlinedDiv>
                                </Grid>
                            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              style={{ marginTop: 10 }}
            >
              {/* <Button variant='outlined' size="large" color='primary' onClick={() => onClick(agency)}>AGENCY HOME</Button> */}
              <Button variant='contained' fullWidth color="primary" style={{ marginTop: "10pxx" }} size="large"
                onClick={() => setInvoiceRequired(true)}>{required_invoice ? "SET PAY AS YOU GO" : "SET INVOICE MODE FOR PAYMENT"}</Button>

            </Grid>                      
                            <Grid item xs={12}>
                                <Divider style={{ marginTop: '12px', marginBottom: '12px' }}/>
                                {/* <Button variant='outlined' size="large" color='primary' onClick={() => setMigrate(true)}>MIGRATE TO ANOTHER AGENCY</Button> */}
                                <Button  variant='contained'  fullWidth color="primary"   size="large"  onClick={() => setMigrate(true)}>MIGRATE TO ANOTHER AGENCY</Button>

                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ marginTop: '12px', marginBottom: '12px' }}/>
                              {/* <Button variant='outlined' size="large" color='primary' onClick={() => setDeleteAgency(true)}>Delete Agency</Button> */}
                                <Button   variant='contained'  fullWidth color="primary"   size="large"  onClick={() => setDeleteAgency(true)}>Delete Agency</Button>

                            </Grid>
                            <AddorEditNote entity_id={agency.id}
                              required={true} showAddButton={true} label="showAddButton" /> 
                         
                        </React.Fragment>
                    }
                </>
            }
        </React.Fragment>
    );
}

// Extend Agent Panel with Notes
// const AgentExtendPanel = ({item, onClick, onClose, reload}) => {
//     return (
//       <React.Fragment>        
//         <AgencySummaryPanel agency={item}  onClick={onClick} onClose={onClose} reload={reload} /> 
//         </React.Fragment>
//         )
// }
//TODO: Add tax id editing

const AgencyHome = () => {

    const [page, setPage] = useState('agencies')
    const [curAgency, setCurAgency] = useState()

    const onClick = agency => {
        setCurAgency(agency)
        setPage("oneAgency")
    }

    return page === 'agencies'
        ? <EntitySearch
                entityApi = {endpoints.entity.agency}
                extendPanel={({item, onClose, reload}) => <AgentExtendPanel agency={item} onClick={onClick} onClose={onClose} reload={reload} />}
          />
        : <OneAgency 
            agency={curAgency}
            onExit={() => setPage('agencies')}
        /> 

}

export default AgencyHome;
