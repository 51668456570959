import React from 'react'
import useConstant from 'use-constant'
import ReportView from '../Reports/ReportView'
import * as Constants from '../../common/Constants'
import {addDays, format} from 'date-fns'
import {Button} from '@mui/material'
import {endpoints} from '../../hooks/use-api'
import axios from 'axios'
import {useNotification,} from '../../hooks/use-notification';

const today = () => {
    return format(new Date(), "yyyy-MM-dd");
}

const PayVouchers = () => {
    const { set: setNotification } = useNotification();

    const dateRange = useConstant(() => ({
        from: format(addDays(new Date(), -90), "yyyy-MM-dd"),
        to: today()
    }))

    const filter = useConstant(() => ({
        // dateRange: [],
        reportType: Constants.ReportOptions[3],
        state: null,
        counties: [],
        cities: [],
        gender: null,
        fromAge: 0,
        toAge: 0,
        agency: null
    }))

    const markAsPaid = data => {
        setNotification({
            message: 'Changing vouchers to paid status',
            type: 'success',
        });  

        axios({
            method: 'post',
            url: endpoints.entity.dispenser.payVouchers(),
            data: data.map(d => d.id),
            headers: { 'Content-Type': 'application/json' },
        })
        .then(function (response) {
            //handle success
            console.log(response);
            setNotification({
                message: 'Paid success - reload data to check for unpaid vouchers',
                type: 'success',
            });             
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            setNotification({
                message: `Error ${response.message}`,
                type: 'error',
            });            
        });        
    }

    return (
        <ReportView
            filter={filter}
            dateRange={dateRange}
            additionalButtons={data => <Button variant='outlined' size="small" color='primary' onClick={() => markAsPaid(data)}>Mark as Paid</Button>}
        />
    )
}

export default PayVouchers;

