import { createContext, useContext, useState, useEffect } from "react";

const MoveNext = {
    ValidationRequired: 0,
    Step: 1,
    Page: 2,
    TwoPage: 3,
    Submitting: 4,
};

const MultiFormContext = createContext();

const useMultiStepForm = () => {
    return useContext(MultiFormContext);
};

const useMultiFormProvider = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [enableNext, setEnableNext1] = useState(MoveNext.ValidationRequired);

  const setEnableNext = (value) => {
    console.log("setEnableNext=" + value);
    setEnableNext1(old => {
      console.log("old=" + old);
      return value;
    });
  }

  useEffect(() => {
    console.log("enableNext=" + enableNext, ", activeStep=" + activeStep, ", activePage=" + activePage);
  }, [enableNext, activeStep, activePage]);

  return {
    activeStep,
    setActiveStep,
    activePage,
    setActivePage,
    enableNext,
    setEnableNext,
  };
};

const MultiStepProvider = props => {
  const multiStepState = useMultiFormProvider();
  return <MultiFormContext.Provider value={multiStepState} {...props} />;
};

export { MultiStepProvider, MultiFormContext, useMultiStepForm, MoveNext};
