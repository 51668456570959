import React from "react";
// import Preview from './Preview';
import RecentPrescription from "./RecentPrescription";
import VistionTestInstructions from "./VisionTestInstructions";
import VisionPreQualification from "./VisionQual";
import VisionPreQualificationResults from "./VisionQualResults";

const PreQualForm = ({ page, formProps, multiStepState }) => {
  const {
    values: data,
    setFieldValue,
    setValues,
    isSubmitting,
    isValidating,
    submitForm,
    errors,
  } = formProps;
  switch (page) {
    case 0:
      return (
        <RecentPrescription
          data={data}
          isSubmitting={isSubmitting}
          isValidating={isValidating}
          errors={errors}
          multiStepState={multiStepState}
        />
      );
    case 1:
      return <VistionTestInstructions
        data={data}
        isSubmitting={isSubmitting}
        isValidating={isValidating}
      />;
    case 2:
      return (
        <VisionPreQualification
          data={data}
          setFieldValue={setFieldValue}
          setValues={setValues}
          isSubmitting={isSubmitting}
          submitForm={submitForm}
        />
      );
    case 3:
      return (
        <VisionPreQualificationResults
          data={data}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
        />
      );
    default:
      return <React.Fragment />;
  }
};

export default PreQualForm;
