import React, {useState} from 'react'
import {Button, TextField, Typography} from '@mui/material';
import {endpoints} from '../../hooks/use-api'
import axios from 'axios';
import {useNotification} from '../../hooks/use-notification';
import EntitySearch from '../Entities/EntitySearch';
import {AddressView2} from '../../common/AddressView'

const PendingAgencyPanel = ({item, onApprove, onReject}) => {

    const [rejectMode, setRejectMode] = useState(false)
    const [reasonNote, setReasonNote] = useState('')

    // Show user address view
    return (
        <React.Fragment>
            <Typography variant='h6' color='textSecondary' component="div" style={{ marginBottom: '24px', textTransform: 'uppercase' }}>
                Admin User
            </Typography>
            <AddressView2 {...item.user} />
            <hr />
            { rejectMode ?
                (
                    <React.Fragment>
                        <br /><br/>
                        <Typography variant='h6' color='textSecondary' component="div">
                            Please provide a brief courteous message to inform <em>{item.user.name}</em> of your decision to reject.
                        </Typography>
                        <br /><br/>                        
                        <TextField
                            label="Reason for rejection"
                            fullWidth
                            multiline
                            rows="4"
                            defaultValue={reasonNote}
                            onChange={e => setReasonNote(e.target.value)}
                            margin="dense"
                            variant="outlined"
                            />
                        <Button variant="contained" fullWidth color="primary" style={{ marginTop: '24px' }}
                                onClick={e => onReject(reasonNote)}>Reject</Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <br /><br/>
                        <Typography variant='h6' color='textSecondary' component="div">
                            Click <em>Approve</em> to let agency work on the New Eyes voucher system. Click <em>Reject</em> to disallow the agency.
                        </Typography>
                        <br /><br/>
                        <Button variant='outlined' size="small" color='primary' onClick={onApprove}>Approve</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant='outlined' size="small" color='primary' onClick={e => setRejectMode(true)}>Provide reason to reject</Button>                        
                    </React.Fragment>
                )
            }            
        </React.Fragment>

    )
}

const PendingAgencies = ({reload: reloadParent}) => {
    // const { user } = useAuth();
    const { set: setNotification } = useNotification();
    // const [ entityAsync, reload ] = useApi(endpoints.agency.pendingAgency.get());

    const approveAgency = (pendingAgency, onClose, reload) => {
        axios.post(endpoints.entity.user.approveAgency(), pendingAgency)
            .then(function (response) {
                setNotification({
                    message: `Agency approved`,
                    type: 'success',
                })
                reload()
                reloadParent()
                onClose()
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
            });
    }    

    const rejectAgency = (pendingAgency, reason, onClose, reload) => {
        axios.post(endpoints.entity.user.rejectAgency(), {pendingAgency: pendingAgency, reason: reason})
            .then(function (response) {
                setNotification({
                    message: `Agency Rejected`,
                    type: 'success',
                })
                reload()
                onClose()
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
            });
    }    

    return (
        <EntitySearch
            entityApi={endpoints.entity.pendingagency}
            showUpdate={false}
            itemfn={data => data.agency}
            extendPanel={
                ({item, onClose, reload}) =>
                    <PendingAgencyPanel
                        item={item}
                        onApprove={() => approveAgency(item, onClose, reload)}
                        onReject={(reason) => rejectAgency(item, reason, onClose, reload)}
                        onClose={onClose} />
        }        
        />
    )
}

export default PendingAgencies
