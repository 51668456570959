import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import { BoxRow } from "../DataEntry/StyledFields";

const VerifyVoucherForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const { set: setNotification } = useNotification();
  const [saving, setSaving] = useState(false);
  const [done, setDone] = useState(false);
  const [howFoundNewEyes, setHowFoundNewEyes] = useState("");
  const [specificInfo, setSpecificInfo] = useState("");
  const [error, setError] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    if (done) {
      window.location.href = redirectUrl;
    }
  }, [done, redirectUrl]);

  const sendRequest = () => {
    // Check if all required field is populated.
    if (firstName.length === 0) {
      setError("First name is a required field.");
    } else if (lastName.length === 0) {
      setError("Last name is a required field.");
    } else {
      setSaving(true);
      axios
        .post(endpoints.order.new(), {
          firstName: firstName,
          lastName: lastName,
          voucherNumber: voucherNumber,
        })
        .then(function (response) {
          if (response.data.valid) {
            setRedirectUrl(response.data.redirectUrl);
            setDone(true);
          } else {
            setNotification({
              message: `Voucher number ${voucherNumber} is not setup for ordering`,
              type: "error",
            });
            setSaving(false);            
          }
        })
        .catch(function (error) {
          console.log(error);
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
          setSaving(false);
        });
    }
  };

  return (
    <>
      {done && (
        <Typography variant="h5" sx={{ my: 10 }}>
          Redirecting...
        </Typography>
      )}
      {!done && (
        <>
          <BoxRow>
            <Typography variant="h5">
              Please provide your name and voucher number as it appears in your email:
            </Typography>
          </BoxRow>
          <BoxRow>
            <TextField
              label="Voucher #"
              value={voucherNumber}
              onChange={(e) => setVoucherNumber(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>          
          <BoxRow>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>
          <BoxRow>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>
          <BoxRow>
            <Typography
              variant="error"
              color="error"
              style={{ marginTop: "12px" }}
            >
              &nbsp;&nbsp;{error}
            </Typography>
          </BoxRow>
          <BoxRow>
            <Button
              fullWidth
              type="button"
              variant="contained"
              disabled={saving}
              onClick={(e) => sendRequest()}
            >
              Submit
            </Button>
          </BoxRow>
        </>
      )}
    </>
  );
};

export default VerifyVoucherForm;
