import React, {useEffect, useState, useRef} from 'react';
import {Button, FormControl, Grid, Input, Paper, Typography,} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Email from '@mui/icons-material/Person';
import PersonIcon from '@mui/icons-material/Person';
import {Link} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {validate} from '../../common/helper';
import logo from '../../images/logo.png';
import {useAuth} from '../../hooks/use-auth';
import useCallbackStatus from '../../hooks/use-callback-status';
import { useNavigate } from "react-router-dom";
import {NotificationType, useNotification,} from '../../hooks/use-notification';
import { useFormState } from 'react-use-form-state';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3),
    padding: '10px',
    minWidth: '200px',
  },
  input: {
    margin: theme.spacing(2),
    padding: '10px',
  },
}));

const Login = () => {
  const classes = useStyles();
  // const [formState, { email }] = useFormState();
  const {
    user,
    login,
    resendSignupVerificationCode,
    resetPassword,
    completeNewPassword,
    apiError,
  } = useAuth();
  const navigate = useNavigate();
  const { isPending, isRejected, error, run } = useCallbackStatus();

  const [inputs, setInputs] = useState({
    email: {
      value: '',
      label: 'Email',
      rules: ['required', 'email'],
    },
    password: {
      value: '',
      label: 'Password',
      rules: ['required'],
    },
  });

  const [formState, { password }] = useFormState();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [resetUser, setResetUser] = useState();
  const [signInError, setSignInError] = useState([]);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { set: setNotification } = useNotification();

  const [userConfirmRequired, setUserConfirmRequired] = useState(false);

  const captchaRef = useRef(null);

  useEffect(() => {
    if (apiError) {
      setErrorMsg(apiError.message);
    } else {
      setErrorMsg('');
    }
  }, [apiError])

  // componentdidmount
  useEffect(() => {
    if (user) {
      navigate('/inbox');
    }
  }, [user]);

  const handleInputChange = event => {
    event.persist();
    if (event) {
      if (event.target.name !== '') {
        signInError[event.target.name] = '';
      }
      setSuccessMsg('');
      setErrorMsg('');

      setInputs(prevState => {
        return {
          ...prevState,
          [event.target.name]: {
            ...inputs[event.target.name],
            value: event.target.value,
          },
        };
      });
      setSignInError(prevState => {
        return { ...prevState, ...signInError };
      });
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    if (inputs) {
      let valid = validate(inputs);

      if (!valid.isValid) {
        formIsValid = false;
        let errorArr = [];
        errorArr = valid.errors;
        setSignInError(prevState => {
          return { ...prevState, ...errorArr };
        });
      } else if (!captchaRef.current.getValue()) {
        formIsValid = false;
        setSignInError(prevState => {
          return { ...prevState, captcha: 'Please complete the reCAPTCHA.' };
        });
      }
    }
    return formIsValid;
  };

  const handleNewPassword = event => {
    event.preventDefault();
    if (formState.values.password !== formState.values.password_again) {
      setNotification({
        type: NotificationType.error,
        message: `Passwords don't match`,
      });
      return;
    }

    completeNewPassword(resetUser, formState.values.password)
      .then(nodata => {
        setNotification({
          type: NotificationType.success,
          message: `Password reset completed. You may now login.`,
        });
        setDialogOpen(false);
      })
      .catch(err => {
        setNotification({
          type: NotificationType.error,
          message: err.message,
        });
      });
  };

  const handleDialogClose = event => {
    event.preventDefault();

    formState.reset();
    setDialogOpen(false);
  };

  const sendConfirmationAgain = event => {
    event.preventDefault();
    resendSignupVerificationCode(inputs.email.value)
      .then(data => {
        setNotification({
          type: NotificationType.success,
          message:
            'Please check your email for instructions on how to verify your account.',
        });
      })
      .catch(err => {
        setNotification({
          type: NotificationType.error,
          message:
            'Failed to request new verification code. Please see the site administrator.',
        });
      });    
  }

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
      if (handleValidation()) {
        run(
          login(inputs.email.value, inputs.password.value, captchaRef.current.getValue())
            .then(data => {
              if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // show new password dialige
                setResetUser(data);
                setDialogOpen(true);
              } else {
                navigate('/inbox');
                console.log(user);
              }
            })
            .catch(err => {
              // If error is not verified, show dialog and send execute `resendSignUp`
              if (err.code === 'UserNotConfirmedException') {
                // // setNotification({
                // //   type: NotificationType.error,
                // //   message:
                // //     'Email not verified. Please check your email for instructions on how to verify your account.',
                // // });
                setUserConfirmRequired(true);
                // resendSignupVerificationCode(inputs.email.value)
                //   .then(data => {
                //     setNotification({
                //       type: NotificationType.error,
                //       message:
                //         'Email not verified. Please check your email for instructions on how to verify your account.',
                //     });
                //   })
                //   .catch(err => {
                //     setNotification({
                //       type: NotificationType.error,
                //       message:
                //         'Failed to request new verification code. Please see the site administrator.',
                //     });
                //   });
                return;
              } else if (err.code === 'PasswordResetRequiredException') {
                resetPassword(inputs.email.value)
                  .then(data => {
                    console.log(data);
                    setNotification({
                      type: NotificationType.success,
                      message: `Password must be reset. Please check your email for instructions.`,
                    });
                  })
                  .catch(err => {
                    console.log(err);
                    setNotification({
                      type: NotificationType.error,
                      message: err.message,
                    });
                  });
              } else if (
                err.code === 'NotAuthorizedException' ||
                err.code === 'UserNotFoundException'
              ) {
                //bad password
                setNotification({
                  type: NotificationType.error,
                  message: 'Invalid email or password',
                });
              } else {
                console.log(err);
              }
            })
        );
      }
    }
  };

  return <>
    <Dialog
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Password Change</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You must change your password to continue.
        </DialogContentText>
        <FormControl fullWidth>
          <Input
            {...password('password')}
            required
            className={classes.input}
            placeholder="New Password"
            startAdornment={
              <InputAdornment position="start">
                <Email color="primary" />
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <Input
            {...password('password_again')}
            required
            className={classes.input}
            placeholder="Repeat Password"
            startAdornment={
              <InputAdornment position="start">
                <Email color="primary" />
              </InputAdornment>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={handleNewPassword} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
    <Paper>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        {successMsg ? <span className="successSpan">{successMsg}</span> : ''}
        {errorMsg ? <span className="errorSpan">{errorMsg}</span> : ''}

        <img src={logo} width="300px" alt="logo" className="logo-img" />

        {userConfirmRequired &&
          <Grid item xs={6} sm={6}>
            <p>
              You email is not verified. Please check your email for instructions on how to verify your account. It takes few minutes to receive the email.         
            </p>
            <p>
              If you have not received the email or the verification link has expired. You can click below to send the verification email again. Please make sure
              to use the latest email to verify your email.
            </p>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={sendConfirmationAgain}>
                Send account verification email again
              </Button>
          </Grid>
        }

      {!userConfirmRequired &&
        <Grid item xs={6} sm={6}>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <Input
                type="text"
                name="email"
                disabled={isPending}
                className={classes.input}
                placeholder="Email"
                value={inputs.email.value}
                onChange={event => handleInputChange(event)}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                }
              />
              {signInError && signInError.email ? (
                <Typography className="errorSpan">
                  {signInError.email}
                </Typography>
              ) : (
                ''
              )}
            </FormControl>
            <FormControl fullWidth>
              <Input
                type="password"
                name="password"
                disabled={isPending}
                className={classes.input}
                placeholder="Password"
                value={inputs.password.value}
                onChange={event => handleInputChange(event)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpenIcon color="primary" />
                  </InputAdornment>
                }
              />
              {signInError && signInError.password ? (
                <Typography className="errorSpan">
                  {signInError.password}
                </Typography>
              ) : (
                ''
              )}
              {signInError && signInError.captcha ? (
                <Typography className="errorSpan">
                  {signInError.captcha}
                </Typography>
              ) : (
                ''
              )}
            </FormControl>
              <Grid container item
                direction="row"
                alignItems="center"
                justifyContent="center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  ref={captchaRef}
                  onChange={() => setSignInError([])}
                />
              </Grid>
              <p>&nbsp;</p>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid
                item
                sm={12}
                xs={12}
                style={{ textAlign: 'right', margin: '0 20px' }}
              >
                <Link to="/reset-password" className="forgot-password">
                  Forgot Password
                </Link>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" spacing={4}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={isPending}
                className={classes.button}
              >
                Sign In
              </Button>
              <Button
                variant="outlined"
                disabled={isPending}
                className={classes.button}
                onClick={() => navigate('/registration')}>
                New User
              </Button>
            </Grid>
          </form>
        </Grid>
      }
      </Grid>
    </Paper>
  </>;
};

export default Login;
