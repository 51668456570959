import React from 'react'
import {EmptySearchOption, useAuth} from '../../hooks/use-auth'

import EntitySearchCore from './EntitySearchCore';

// NOTE: itemfn extract the entity for this common component to operate on for viewing, editing etc.
// To all outside callback the actual item object is send, for example to the extendPanel component
const EntitySearch = ({ entityApi, agencyId, showUpdate = true, itemfn = data => data,
        extendPanel, useSearchOption = true }) => {
    const { searchOption, setSearchOption } = useAuth()

    return <EntitySearchCore
        entityApi = {entityApi}
        agencyId = {agencyId}
        showUpdate = {showUpdate}
        itemfn = {itemfn}
        extendPanel = {extendPanel}
        searchOption = {useSearchOption ? searchOption : EmptySearchOption}
        setSearchOption = {setSearchOption}
    />
}

export default EntitySearch;
