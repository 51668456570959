import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  splText: {
    fontFamily: ['"Times New Roman"'].join(","),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const RadioGroupRow = ({
  item: { id, property, label, options },
  value,
  onChange,
}) => {
  const classes = useStyles({});
  var labelName = `label_${id}`;
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <label htmlFor={labelName}>
          <Typography className={classes.splText} variant="h6">
            {label}
          </Typography>
        </label>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <RadioGroup
          aria-label={labelName}
          color="primary"
          name={labelName}
          value={value}
          onChange={(e) => onChange({ [property]: e.target.value })}
        >
          {options.map((m, index) => (
            <FormControlLabel
              key={index}
              value={m.value}
              control={<Radio />}
              label={m.label}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const DateRow = ({ item: { id, label, property }, value, onChange }) => {
  const classes = useStyles({});
  var labelName = `label_${id}`;
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <label htmlFor={labelName}>
          <Typography className={classes.splText} variant="h6">
            {label}
          </Typography>
        </label>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <TextField
          id="date"
          type="date"
          placeholder="yyyy-mm-dd"
          required
          value={value}
          onChange={(e) => onChange({ [property]: e.target.value })}
          InputProps={{
            inputProps: {
              max: format(new Date(), "yyyy-MM-dd"),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const ErrorRow = ({ label }) => {
  const classes = useStyles({});
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          color="error"
          style={{ marginTop: "12px" }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ConfirmPdMeasurement = () => {
  const classes = useStyles({});
  return (
    <Typography variant="h6" className={classes.splText}>
      I confirm that the prescription includes Pupillary Distance (PD)
      measurements which is required to order New Eyes glasses.
      &nbsp;&nbsp;&nbsp;
      <Link
        href="https://new-eyes.org/pdmeasurement"
        target="_blank"
      >
        (Click to check how to measure PD)
      </Link>
    </Typography>
  );
};

const CheckBoxRow = ({
  item: { id, property, label, pre, options },
  value,
  onChange,
}) => {
  const classes = useStyles({});
  var labelName = `label_${id}`;
  return (
    <Grid
      container
      direction="row"
      style={{ marginTop: "54px", marginBottom: "54px" }}
    >
      {pre && (
        <Grid item xs={12}>
          {pre}
        </Grid>
      )}
      <Grid item xs={1}>
        <Checkbox
          id={labelName}
          color="primary"
          checked={value}
          onChange={(e, checked) => onChange({ [property]: checked })}
        />
      </Grid>
      <Grid item xs={11}>
        <label htmlFor={labelName}>{label}</label>
      </Grid>
    </Grid>
  );
};

// const AcceptTerms = ({
//   hasRecentPrescription,
//   canTakeVisionTest = false,
//   eye_exam_date,
//   dateValidatorFn,
//   prescriptionHasPdMeasurement,
//   onChange,
// }) => {
//   const classes = useStyles({});

//   const [dateError, setDateError] = useState();
//   const [hasPrescription, setHasPrescription] = useState(true);
//   const [needVisionTest, setNeedVisonTest] = useState(false);

//   const setValidDate = (dt) => {
//     let dateStatus = dateValidatorFn(dt);

//     let hasRecentPrescriptionLocal = false;

//     if (dateStatus === "future") {
//       setDateError("Prescription date can not be in the future");
//       setNeedVisonTest(false);
//       hasRecentPrescriptionLocal = false;
//     } else if (dateStatus === "old") {
//       setDateError(
//         "This prescription is older than 24 months. We need a more recent prescription. The applicant may be eligible to renew the prescription via the New Eyes Online Vision Test."
//       );
//       setNeedVisonTest(true);
//       hasRecentPrescriptionLocal = false;
//     } else if (dateStatus === "error") {
//       setDateError("Please provide the date in the yyyy-mm-dd format");
//       setNeedVisonTest(false);
//       hasRecentPrescriptionLocal = false;
//     } else {
//       setDateError(null);
//       setNeedVisonTest(false);
//       hasRecentPrescriptionLocal = !!dt;
//     }

//     onChange({
//       eye_exam_date: dt,
//       hasRecentPrescription: hasRecentPrescriptionLocal,
//     });
//   };

//   return (
//     <Grid container style={{ marginTop: "54px" }}>
//       <Grid container direction="row">
//         <Grid item xs={12}>
//           <label htmlFor="prescreiption-status">
//             <Typography className={classes.splText} variant="h6">
//               Does the applicant have an up-to-date prescription?
//             </Typography>
//           </label>
//         </Grid>
//         <Grid item xs={1} />
//         <Grid item xs={11}>
//           <RadioGroup
//             aria-label="prescreiption-status"
//             color="primary"
//             name="prescreiption-status"
//             value={hasPrescription ? "true" : "false"}
//             onChange={(e) => {
//               setHasPrescription(e.target.value === "true");
//               if (e.target.value === "false") {
//                 setNeedVisonTest(false);
//               } else {
//                 setValidDate({ eye_exam_date });
//               }
//             }}
//           >
//             <FormControlLabel value="true" control={<Radio />} label="Yes" />
//             <FormControlLabel value="false" control={<Radio />} label="No" />
//           </RadioGroup>
//         </Grid>
//       </Grid>

//       {hasPrescription && (
//         <Grid container direction="row" style={{ marginTop: "24px" }}>
//           <Grid item xs={12}>
//             <label htmlFor="date">
//               <Typography className={classes.splText} variant="h6">
//                 When was the prescription issued?
//               </Typography>
//             </label>
//           </Grid>
//           <Grid item xs={1} />
//           <Grid item xs={11}>
//             <TextField
//               id="date"
//               type="date"
//               placeholder="yyyy-mm-dd"
//               required
//               // format={'MM/DD/YYYY'}
//               value={eye_exam_date}
//               onChange={(e) => setValidDate(e.target.value)}
//               InputProps={{
//                 inputProps: {
//                   max: format(new Date(), "yyyy-MM-dd"),
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//       )}

//       {hasPrescription ? (
//         dateError && (
//           <Typography
//             variant="subtitle2"
//             color="error"
//             style={{ marginTop: "12px" }}
//           >
//             {dateError}
//           </Typography>
//         )
//       ) : (
//         <Typography
//           variant="subtitle2"
//           color="error"
//           style={{ marginTop: "12px" }}
//         >
//           Unfortunately, we can not process a voucher without a vision
//           prescription. Please try again when you have a prescription ready.
//         </Typography>
//       )}

//       {hasPrescription && hasRecentPrescription && (
//         <Grid
//           container
//           direction="row"
//           style={{ marginTop: "54px", marginBottom: "54px" }}
//         >
//           <Grid item xs={1}>
//             <Checkbox
//               id="accept-terms-pd"
//               color="primary"
//               checked={prescriptionHasPdMeasurement}
//               onChange={(e, checked) =>
//                 onChange({ prescriptionHasPdMeasurement: checked })
//               }
//             />
//           </Grid>
//           <Grid item xs={11}>
//             <label htmlFor="accept-terms-pd">
//               <Typography variant="h6" className={classes.splText}>
//                 I confirm that my client's prescription includes Pupilary
//                 Distance (PD) measurements which is required to order New Eyes
//                 glasses &nbsp;&nbsp;&nbsp;
//                 <Link
//                   href="https://new-eyes.org/pdmeasurement"
//                   target="_blank"
//                 >
//                   (Click to check how to measure PD)
//                 </Link>
//               </Typography>
//             </label>
//           </Grid>
//         </Grid>
//       )}

//       {needVisionTest && (
//         <Grid container direction="row" style={{ marginTop: "12px" }}>
//           <Grid item xs={12}>
//             <label htmlFor="vision-test-yes-no">
//               <Typography className={classes.splText} variant="h6">
//                 Can the applicant renew the prescription by taking an online
//                 vision test?
//               </Typography>
//             </label>
//           </Grid>
//           <Grid item xs={1} />
//           <Grid item xs={11}>
//             <RadioGroup
//               aria-label="vision-test-yes-no"
//               color="primary"
//               name="prescreiption-status"
//               value={canTakeVisionTest ? "true" : "false"}
//               onChange={(e) =>
//                 onChange({ canTakeVisionTest: e.target.value === "true" })
//               }
//             >
//               <FormControlLabel value="true" control={<Radio />} label="Yes" />
//               <FormControlLabel value="false" control={<Radio />} label="No" />
//             </RadioGroup>
//           </Grid>
//         </Grid>
//       )}
//     </Grid>
//   );
// };

export { RadioGroupRow, DateRow, ErrorRow, ConfirmPdMeasurement, CheckBoxRow };
