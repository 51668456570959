import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
// import { Box } from "@mui/system";

export const MainContainer = (props) => (
  <Box
    sx={{
      display: "flex",
      // bgcolor: "lightblue",
      height: "100vh",
      overflowY: "auto",
      // border: "1px solid red",
    }}
    {...props}
  />
);

export const MainContent = (props) => (
  <Box
    sx={{
      bgcolor: "background.paper",
      // boxShadow: 1,
      // borderRadius: 1,
      p: 4,
      minWidth: 400,
      maxWidth: 700,
      // border: "1px solid green",
      my: 2,
      mx: "auto",
      // height: "100%",
      // borderBottom: 0,
      // borderTop: 0,
    }}
    {...props}
  />
);

export const BoxRow = (props) => (
  <Box
    sx={{
      // alignItems: "center",
      // textAlign: "center",
      // borderRadius: "12px",
      // boxShadow: 1,
      fontWeight: "bold",
      my: 2,
      width: "100%",
      // border: "1px solid green",
    }}
    {...props}
  />
);

export const BoxItem = (props) => (
  <Box
    sx={{
      pt: 2,
    }}
    {...props}
  />
);

export const BoxLabel = (props) => (
  <Box
    sx={{
      fontWeight: "medium",
      m: 0,
    }}
    {...props}
  />
);

export const ActionButton = (props) => (
  <Button
    sx={{
      m: 1,
      py: 1.5,
      px: 8,
      fontSize: 18,
    }}
    {...props}
  />
);

export const BoxHeader = styled("h2")({
  textAlign: "center",
});
