import { Button } from "@mui/material";
import cx from "classnames";
import React from "react";
import PayVouchers from "./PayVouchers";

const InboxOption = ({ optionText, selected, onChange }) => (
  <li className="column is-narrow voucher-status-sel clickable">
    <Button
      variant="outlined"
      style={{ border: "none" }}
      className={cx({ selected: selected })}
    >
      <span onClick={onChange}>{optionText}</span>
      {/* { status && <Typography color='primary' className="count">{ count }</Typography> } */}
    </Button>
  </li>
);

const DispenserHome = () => {
  return <PayVouchers />;
  // const [option, setOption] = useState('redeem')

  // return <>
  //     <AppBar position="static" color="inherit">
  //         <div className="voucherFilter">
  //             <Grid container justifyContent='center'>
  //                 <ul className={cx("result-filters")}>
  //                     <InboxOption optionText="Redeem" selected={option === "redeem"} onChange={() => setOption("redeem")} />
  //                     <InboxOption optionText="Pay" selected={option === "pay"} onChange={() => setOption("pay")} />
  //                 </ul>
  //             </Grid>
  //         </div>
  //     </AppBar>

  //     {option === "redeem" && <RedeemVouchers />}
  //     {option === "pay" && <PayVouchers />}
  // </>;
};

export default DispenserHome;
