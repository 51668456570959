import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import FileViewer from "react-file-viewer";
import { endpoints } from "../../../hooks/use-api";
import { useNotification } from "../../../hooks/use-notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const optionLabels = {
  snap: "SNAP Benefits",
  unemployment: "Unemployment Assistance",
  taxreturn: "2019 or 2020 Federal Tax Return",
  other: "Other",
};

const DocViewerSection = ({ id, voucher_detail }) => {
  const { set: setNotification } = useNotification();
  const { docs } = voucher_detail;
  const [open, setOpen] = React.useState(false);
  const [fileURL, setFileURL] = React.useState("");
  const [doc, setDoc] = React.useState("");
  const [fileType, setFileType] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  const showFile = (doc) => {
    setDoc(doc);
    const FileDownload = require("js-file-download");
    axios
      .get(endpoints.entity.voucher.loadProof(id, doc.key), {
          responseType: 'arraybuffer',
          headers: {
              'Accept': 'application/pdf'
          }        
        // responseType: "blob",
      })
      .then(function (response) {
        setNotification({
          message: `Downloading results`,
          type: "success",
        });

        var contentType = response.headers["content-type"]

//        FileDownload(response.data, doc.key, contentType);

        setFileType(contentType
          ? contentType.substr(contentType.search("/") + 1)
          : "pdf");

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: contentType });
        var urlPath = URL.createObjectURL(file)
        setFileURL(urlPath);
        console.log(fileURL);
        setOpen(true);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });

    // setOpen(true)
  };

  const onError = (e) => {
    console.log("I am here");
    console.log(e);
  }

  return (
    <>
      <Divider style={{ marginBottom: "12px" }} />
      <Typography variant="button" color="textSecondary">
        Proof of Need
      </Typography>
      {docs &&
        docs.map((doc) =>
          doc.key ? (
            <Box sx={{ mx: 5, my: 2 }}>
              <Button variant="outlined" onClick={() => showFile(doc)}>
                {optionLabels[doc.type]}
              </Button>
            </Box>
          ) : (
            <></>
          )
        )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="clos
              e"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {optionLabels[doc.type]}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {fileURL && <FileViewer fileType={fileType} filePath={fileURL} key={fileURL} onError={onError} />}
\      </Dialog>
    </>
  );
};

export default DocViewerSection;
