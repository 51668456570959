import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import logo from "../../images/ne-logo.png";
import { MoveNext, MultiStepProvider, useMultiStepForm } from "./MultiFormContext";
import { ActionButton, BoxHeader, BoxRow } from "./StyledFields";
import { Clear } from "@mui/icons-material";

const style = {
  margin: 0,
  top: 20,
  right: 20,
  bottom: 'auto',
  left: 'auto',
  position: 'fixed',
};

const MultiStepForm = ({ initialValues, title, showLogo, steps, children, onClose, lastStepButtonLabel }) => {
  return (
    <MultiStepProvider>
      <MultiStepFormInner initialValues={initialValues} title={title} showLogo={showLogo} steps={steps} children={children} onClose={onClose} lastStepButtonLabe={lastStepButtonLabel} />
    </MultiStepProvider>
  );
}

const MultiStepFormInner = ({ initialValues, title, showLogo, steps, children, onClose, lastStepButtonLabel }) => {
  //const multiStepState = useMultiFormProvider();
  const {
    activeStep,
    setActiveStep,
    activePage,
    setActivePage,
    enableNext,
    setEnableNext,
  } = useMultiStepForm();

  lastStepButtonLabel = lastStepButtonLabel || "Submit Application";
  const isFirstStepFirstPage = activeStep === 0 && activePage === 0;
  const isLastStep = activeStep === steps.length - 1;

  const [backPressed, setBackPressed] = useState(false);

  useEffect(() => {
    if (backPressed) {
      setBackPressed(false);
      setEnableNext(MoveNext.ValidationRequired);
      return;
    }

    console.log("enableNext=" + enableNext);

    console.log("submitting in progress");
    if (isLastStep) {
      // _submitForm(values, actions);
    } else {
      if (enableNext === MoveNext.Step) {
        setActiveStep(activeStep + 1);
        setActivePage(0);
      } else if (enableNext === MoveNext.Page) {
        setActivePage(activePage + 1);
      } else if (enableNext === MoveNext.TwoPage) {
        setActivePage(activePage + 2);
      }
      setEnableNext(MoveNext.ValidationRequired);
      //?? setEnableNext(MoveNext.Step);
      // actions.setTouched({});
      // actions.setSubmitting(false);
    }    

  }, [enableNext, backPressed]);

  function _handleSubmit(values, actions) {
//    console.log("submitting in progress");
    if (isLastStep) {
      // _submitForm(values, actions);
    } else {
      // // if (enableNext === MoveNext.Step) {
      // //   setActiveStep(activeStep + 1);
      // //   setActivePage(0);
      // // } else if (enableNext === MoveNext.Page) {
      // //   setActivePage(activePage + 1);
      // // } else if (enableNext === MoveNext.TwoPage) {
      // //   setActivePage(activePage + 2);
      // // }
      // // setEnableNext(MoveNext.ValidationRequired);
      // // //?? setEnableNext(MoveNext.Step);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setBackPressed(true);
    if (activePage > 0) {
      setActivePage(activePage - 1);
    } else {
      setActiveStep(activeStep - 1);
      // On last page of previous step
    }
  }

  //?? TODO
  const debugInfo = (props) => {
    // console.log(props.errors);
    // console.log(
    //   "isSubmitting=" + props.isSubmitting + ", enableNext=" + enableNext
    // );
  };

  return (
    <>
      <Box>
        <BoxRow>
          <BoxHeader>
            {showLogo && <img src={logo} width="100px" alt="logo" />}
            &nbsp;&nbsp;&nbsp;
            {title}
            {onClose &&
            <Fab size="small" aria-label="cancel" color="secondary"
                onClick={onClose} style={style}>
              <Clear />
            </Fab>}
          </BoxHeader>
        </BoxRow>
        <BoxRow>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </BoxRow>
        <Box>
          <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
            {(props) => (
              <Form id="createVoucherForm">
                {children(activeStep, activePage, props)}
                {debugInfo(props)}
                <BoxRow>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isFirstStepFirstPage && (
                      <Button
                        sx={{
                          m: 1,
                          py: 1.5,
                          px: 8,
                          fontSize: 18,
                        }}
                        onClick={_handleBack}
                      >
                        Back
                      </Button>
                    )}
                    {/* <Box
                      sx={{
                        margin: 1,
                        position: "relative",
                      }}
                    > */}
                    <ActionButton
                      disabled={props.isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isLastStep ? lastStepButtonLabel : "Continue"}
                    </ActionButton>
                    {props.isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      />
                    )}
                    {/* </Box> */}
                  </Box>
                </BoxRow>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default MultiStepForm;
