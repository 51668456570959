import { Button, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {NumericFormat} from "react-number-format";
import { AddressView1, AddressView2 } from "../../../common/AddressView";
import { calculateAge, UserTypes } from "../../../common/Constants";
import OutlinedDiv from "../../../common/OutlinedDiv";
import { endpoints } from "../../../hooks/use-api";
import { useAuth } from "../../../hooks/use-auth";
import { useNotification } from "../../../hooks/use-notification";
import visibly from "../../../images/visibly.svg";
import EditFinancialInfo from "../CreateVoucher/Financial/EditFinancialInfo";
import { VoucherValidator } from "../CreateVoucher/Model";
import DocViewerSection from "./DocViewerSection";
import ButtonOption from "./ButtonOption";

// function age(dateOfBirth) {
//     try {
//         let totalMonths = differenceInMonths(new Date(), parseISO(dateOfBirth))
//         let months = totalMonths % 12
//         let years = (totalMonths - months) / 12
//         return months > 0 ? `${years} years, ${months} month${months > 1 ? 's' : ''}` : `${years} years`
//     } catch(e) {
//         return ''
//     }
// }

const Applicant = ({ applicant, voucher_detail }) => {
  const {
    has_private_insurance,
    has_medicare,
    has_medicaid,
    has_other_assistance,
    is_minor,
    is_homeless,
    is_student,
    studentReceivingMealDiscount,
    eye_exam_date,
    require_vision_test,
  } = voucher_detail;
  const { birth_date, sex } = applicant;
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <AddressView2 {...applicant} title="Applicant" isHome={true} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" component="div">
            <p>
              {sex} | {calculateAge(birth_date)}
            </p>
            <Grid container>
              {is_minor && <ButtonOption title="Minor" backColor="ddf8ed" />}
              {is_homeless && <ButtonOption title="homeless" />}
              {is_student && <ButtonOption title="student" />}
              {studentReceivingMealDiscount &&
                studentReceivingMealDiscount !== "false" && (
                  <ButtonOption title="meal program" />
                )}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  Insurance
                </Typography>
              </Grid>
              <Grid container>
                {has_private_insurance && <ButtonOption title="private" />}
                {has_medicare && <ButtonOption title="medicare" />}
                {has_medicaid && <ButtonOption title="medicaid" />}
                {has_other_assistance && <ButtonOption title="other" />}
              </Grid>
            </Grid>
          </Typography>
        </Grid>
        {require_vision_test && (
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <img src={visibly} width="75px" alt="visibly" />
              &nbsp;&nbsp;&nbsp;
              <Typography variant="overline" color="textSecondary">
                <strong>Requires vision test</strong> (Last prescription:{" "}
                {calculateAge(eye_exam_date)})
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function householdMembersCount(household_adults, household_children) {
  let result = [];
  if (household_adults) {
    result.push(
      household_adults + (household_adults === 1 ? " adult" : " adults")
    );
  }
  if (household_children) {
    result.push(
      household_children + (household_children === 1 ? " child" : " children")
    );
  }
  return result.join(", ");
}

const Currency = ({ amt }) => (
  <NumericFormat
    value={amt}
    displayType={"text"}
    fixedDecimalScale
    decimalScale={2}
    thousandSeparator={true}
    prefix={"$"}
  />
);

const FinancialInfoItem = ({ name, val }) => (
  <React.Fragment>
    <Grid item xs={6} style={{ paddingLeft: "48px" }}>
      <Typography variant="body2" display="block" color="textPrimary">
        {name}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="caption" display="block" color="textPrimary">
        {val}
      </Typography>
    </Grid>
  </React.Fragment>
);

const FinancialInfo = ({
  id,
  voucher_detail,
  isChild,
  onClose,
  editable = false,
}) => {
  const { set: setNotification } = useNotification();

  const [editFinancialInfo, setEditFinancialInfo] = useState({
    household_adults: 0,
    household_children: 0,
    total_income: 0,
    total_expense: 0,
    income_note: "",
  });
  const {
    household_adults,
    household_children,
    total_income,
    total_expense,
    income_note,
  } = editFinancialInfo;
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const {
      household_adults,
      household_children,
      total_income,
      total_expense,
      income_note,
    } = voucher_detail;
    setEditFinancialInfo({
      household_adults,
      household_children,
      total_income,
      total_expense,
      income_note,
    });
  }, [voucher_detail]);

  const updateVoucher = () => {
    axios
      .put(
        endpoints.entity.voucher.updateApplicantFinancial(id),
        editFinancialInfo
      )
      .then(function (response) {
        setNotification({
          message: `Voucher updated successfully`,
          type: "success",
        });
        onClose();
        //?? onExit(response.data);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  return (
    <>
      {editMode && (
        <>
          <EditFinancialInfo
            voucher_detail={editFinancialInfo}
            isChild={isChild}
            onChange={(updatedItem) =>
              setEditFinancialInfo({ ...editFinancialInfo, ...updatedItem })
            }
          />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "24px" }}
            onClick={() => updateVoucher()}
          >
            Update
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "24px" }}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
        </>
      )}
      {!editMode && (
        <div style={{ paddingTop: "8px" }}>
          <Typography variant="caption" color="textSecondary">
            Financial Info
          </Typography>
          &nbsp;&nbsp;&nbsp;
          {editable && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          )}
          <Grid container style={{ paddingTop: "8px" }}>
            <FinancialInfoItem
              name="Household Family Members"
              val={householdMembersCount(household_adults, household_children)}
            />
            <FinancialInfoItem
              name="Total Monthly Income"
              val={<Currency amt={total_income} />}
            />
            <FinancialInfoItem
              name="Total Monthly Expense"
              val={<Currency amt={total_expense} />}
            />
            <FinancialInfoItem name="Income Note" val={income_note} />
          </Grid>
        </div>
      )}
    </>
  );
};

const VoucherDetailSection = ({
  voucher,
  agencySummary,
  onClose,
  showUserInfo = true,
}) => {
  const { user: loggedInUser } = useAuth();
  let { id, user, agency, applicant, voucher_detail, actions } = voucher;

  useEffect(() => console.log(voucher), [voucher]);

  var current;
  if (id === 0) {
    if (loggedInUser) {
      user = loggedInUser;
      agency = loggedInUser.agency;
    }
    current = { status: "New Request", datetime: new Date(), note: null };
  } else {
    current = actions[0];
  }

  return (
    <React.Fragment>
      {showUserInfo && (
        <>
          <Divider style={{ marginBottom: "12px" }} />
          {!voucher_detail.individual_application && (
            <>
              <AddressView1 {...agency} title="Agency" />
              <Divider style={{ marginBottom: "12px" }} />
              <Grid container style={{ marginTop: "24px" }}>
                <Grid item xs={6}>
                  <AddressView2 {...user} title="Case Worker" />
                </Grid>
                <Grid item xs={6}>
                  {loggedInUser.userType === UserTypes.neweyes && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="button" color="textPrimary">
                          Agency summary
                        </Typography>
                      </Grid>
                      <Grid container spacing={8}>
                        <Grid item xs={3}>
                          <OutlinedDiv label="Approved">
                            <Typography variant="caption" color="textPrimary">
                              {(agencySummary &&
                                agencySummary.vouchers_recently_approved) ||
                                0}
                            </Typography>
                          </OutlinedDiv>
                        </Grid>
                        <Grid item xs={3}>
                          <OutlinedDiv label="Redeemed">
                            <Typography variant="caption" color="textPrimary">
                              {(agencySummary &&
                                agencySummary.vouchers_recently_redeemed) ||
                                0}
                            </Typography>
                          </OutlinedDiv>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {voucher_detail.individual_application && (
            <>
              <Typography variant="h6" align="center" sx={{ pb: 2 }}>
                DIRECT APPLICANT
              </Typography>
              <DocViewerSection id={id} voucher_detail={voucher_detail} />
            </>
          )}
          <Divider style={{ marginBottom: "12px" }} />
        </>
      )}
      <Applicant applicant={applicant} voucher_detail={voucher_detail} />
      {VoucherValidator.isFinancialInfoRequied({
        voucher_detail: voucher_detail,
      }) && (
        <FinancialInfo
          id={id}
          voucher_detail={voucher_detail}
          isChild={applicant.age <= 18}
          editable={
            loggedInUser &&
            loggedInUser.userType !== UserTypes.neweyes &&
            (current.status === "pending_payment" || current.status === "pending_approval" || current.status === "rejected")
          }
          onClose={onClose}
        />
      )}
    </React.Fragment>
  );
};

export default VoucherDetailSection;
