import React from 'react';
import {Button, FormControl, Grid, Input, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Email from '@mui/icons-material/Person';
import logo from '../../images/logo.png';
import {useFormState} from 'react-use-form-state';
import {useAuth} from '../../hooks/use-auth';
import { useNavigate } from "react-router-dom";
import {NotificationType, useNotification,} from '../../hooks/use-notification';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3),
    padding: '10px',
    minWidth: '200px',
  },
  input: {
    margin: theme.spacing(2),
    padding: '10px',
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const [formState, { email }] = useFormState();
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const { set: setNotification } = useNotification();

  const handleSubmit = event => {
    event.preventDefault();

    resetPassword(formState.values.email)
      .then(data => {
        console.log(data);

        setNotification({
          type: NotificationType.success,
          message: `Success! Please check your email for further instructions.`,
        });
        navigate('/');
      })
      .catch(err => {
        console.log(err);
        setNotification({
          type: NotificationType.error,
          message: err.message,
        });
      });
  };

  return (
    <Paper>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item width="600px">
          <p>
            <img src={logo} width="300px" alt="logo" className="logo-img" />
          </p>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <Input
                {...email("email")}
                required
                className={classes.input}
                placeholder="Email"
                startAdornment={
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                }
              />
            </FormControl>
            <p>&nbsp;</p>

              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                Send Code
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => navigate("/")}
              >
                Back to Login
              </Button>
          </form>
          <p style={{height: "400px"}}>&nbsp;</p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResetPassword;
