import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { addYears, differenceInYears, format, isValid, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  EntityTypes,
  GenderOptions,
  StateOptions,
} from "../../common/Constants";
import PhoneNumberFormattedInput from "../../common/PhoneNumberFormattedInput";
import TaxIdFormattedInput from "../../common/TaxIdFormattedInput";
import { TextSelect } from "../../common/TextSelect";
import { useNotification } from "../../hooks/use-notification";
//import {default as ReactAutoComplete} from 'react-autocomplete';


const EmptyProfile = {
  id: 0,
  name: "",
  first_name: "",
  last_name: "",
  sex: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  county: "",
  country: "United States",
  zip: "",
  email: "",
  phone: "",
  phone_extension: "",
  birth_date: "",
  tax_id: "",
  age: 0,
};

const getToday = () => {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

/*
entityApi
---------
load
updateOne(id)
createOne(entity)

onAction = (saved, updatedEntity)
*/
// const EditEntity = ({ entity, entityApi, onAction }) => {
const EditEntity = ({
  item,
  onChange,
  individualApplication = false,
  visionTestRequired = false,
}) => {
  const {
    id,
    name,
    type,
    first_name,
    last_name,
    guardian,
    birth_date,
    sex,
    address1,
    address2,
    city,
    state,
    county,
    zip,
    email,
    phone,
    phone_extension,
    tax_id,
    age,
  } = { ...EmptyProfile, ...item };
  const useFirstName =
    type === EntityTypes.user || type === EntityTypes.applicant;

  const [dateError, setDateError] = useState();
  const [zipError, setZipError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [guardianRequired, setGuardianRequired] = useState(false);
  const [cityError, setCityError] = useState("");
  const [countyError, setCountyError] = useState("");
  const [taxidError, setTaxidError] = useState("");
  useEffect(() => {
    if (phone && phone.length > 2 && phone[0] === "+") {
      onChange({ phone: phone.substring(2) });
    }
  }, [phone]);

  // Guardian is required if applicant is under age who is less than equal to 18
  useEffect(() => {
    setGuardianRequired(age > 0 && age <= 18);
  }, [age]);

  const setValidDate = (el) => {
    let dtStr = el.value;
    var error = "Provide valid date in yyyy-mm-dd";
    let dt = parse(dtStr, "yyyy-MM-dd", new Date());
    if (isValid(dt)) {
      var today = getToday();
      var years = differenceInYears(today, dt);
      error =
        dt > today || years < 2 || years > 120 ? "Invalid birth date" : "";
      onChange({ birth_date: dtStr, age: years });
    } else {
      onChange({ birth_date: dtStr });
    }

    el.setCustomValidity(error);
    setDateError(error);
  };

  const setValidTaxid = (el) => {
    var format = /^0+$/;
    var error = 'Please enter the valid taxid';
    if (el.value.match(format) || el.value.length < 9) {
      setTaxidError(error)

    }
    else {
      setTaxidError("")

    }
    onChange({ tax_id: el.value })
  }

  const validateName = (name) => {
    return String(name)
      .toLowerCase()
      .match(/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i);
  };

  // const zipCodePatterCheck = (textbox) => {
  //   if (textbox.validity.patternMismatch) {
  //     textbox.setCustomValidity("Please enter a 5 digit zip code");
  //   } else {
  //     textbox.setCustomValidity("");
  //   }
  //   return true;
  // };

  return (
    <Grid container spacing={2}>
      {useFirstName && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              value={first_name}
              onChange={(e) =>
                onChange({
                  first_name: e.target.value,
                  name: `${e.target.value} ${last_name}`,
                })
              }
              disabled={individualApplication}
              margin="dense"
              fullWidth
              variant="outlined"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              value={last_name}
              disabled={individualApplication}
              onChange={(e) =>
                onChange({
                  last_name: e.target.value,
                  name: `${first_name} ${e.target.value}`,
                })
              }
              margin="dense"
              fullWidth
              variant="outlined"
              size="small"
              required
            />
          </Grid>
        </React.Fragment>
      )}
      {!useFirstName && (
        <Grid item xs={12}>
          <TextField
            label={type === EntityTypes.agency ? "Agency Name" : "Name"}
            value={name}
            onChange={(e) => onChange({ name: e.target.value })}
            margin="dense"
            fullWidth
            variant="outlined"
            size="small"
            required
          />
        </Grid>
      )}
      {type === EntityTypes.applicant && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              id="date"
              fullWidth
              label="Birth Date"
              type="date"
              size="small"
              disabled={visionTestRequired}
              value={
                birth_date && birth_date.length > 10
                  ? birth_date.substring(0, 10)
                  : birth_date
              }
              // InputProps={{ inputProps: { min: 1, max: 10 } }}
              onChange={(e) => setValidDate(e.target)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: format(addYears(new Date(), -120), "yyyy-MM-dd"),
                  max: format(addYears(new Date(), -2), "yyyy-MM-dd"),
                },
              }}
              required
            />
            {dateError && (
              <Typography variant="caption" color="error">
                {dateError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextSelect
              label="Gender"
              value={sex}
              options={GenderOptions}
              multiple={false}
              onChange={(newValue) => {
                onChange({ sex: newValue });
              }}
            />
          </Grid>
          {guardianRequired && (
            <Grid item xs={12}>
              <TextField
                label="Guardian (for 18 and under)"
                value={guardian}
                onChange={(e) => onChange({ guardian: e.target.value })}
                margin="dense"
                fullWidth
                variant="outlined"
                size="small"
                required={guardianRequired}
              />
            </Grid>
          )}
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <TextField
          label="Address Line 1"
          value={address1}
          autoComplete="off"
          onChange={(e) => onChange({ address1: e.target.value })}
          margin="dense"
          fullWidth
          variant="outlined"
          size="small"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Address Line 2"
          value={address2}
          autoComplete="off"
          onChange={(e) => onChange({ address2: e.target.value })}
          margin="dense"
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="City"
          value={city}
          autoComplete="off"
          onChange={(e) => {
            if (e.target.validity.patternMismatch) {
              setCityError(
                "Please enter a valid city name with alphabetical letter only"
              );
            } else {
              setCityError("");
            }
            onChange({ city: e.target.value });
          }}
          error={cityError.length > 0}
          helperText={cityError}
          InputProps={{
            inputProps: {
              pattern: "[A-Za-z ]{1,15}",
            },
          }}
          margin="dense"
          fullWidth
          variant="outlined"
          size="small"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextSelect
          label="State"
          value={state}
          options={StateOptions}
          disabled={visionTestRequired}
          multiple={false}
          onChange={(newValue) => {
            onChange({ state: newValue });
          }}
        />
        {/* <Autocomplete
                    id="combo-box-demo1"
                    size="small"                    
                    options={StateOptions}
                    getOptionLabel={option => option.name}
                    style={{ width: 300 }}
                    value={stateOption}
                    renderInput={params => (
                        <TextField {...params} label="State" required variant="outlined" fullWidth />
                    )}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            onChange({ state: newValue.value })
                        }
                    }}
                /> */}
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="County (NOT Country)"
          value={county}
          autoComplete="off"
          onChange={(e) => {
            if (e.target.validity.patternMismatch) {
              setCountyError(
                "Please enter a valid county name with alphabetical letter only"
              );
            } else {
              setCountyError("");
            }
            onChange({ county: e.target.value });
          }}
          error={countyError.length > 0}
          helperText={countyError}
          InputProps={{
            inputProps: {
              pattern: "[A-Za-z ]{1,15}",
            },
          }}
          margin="dense"
          fullWidth
          variant="outlined"
          size="small"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Zip"
          value={zip}
          autoComplete="off"
          onChange={(e) => {
            if (e.target.validity.patternMismatch) {
              setZipError("Please enter a 5 digit zip code");
            } else {
              setZipError("");
            }
            onChange({ zip: e.target.value });
          }}
          margin="dense"
          fullWidth
          type="text"
          error={zipError.length > 0}
          helperText={zipError}
          InputProps={{
            inputProps: {
              pattern: "[0-9]{5}",
            },
          }}
          variant="outlined"
          size="small"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => onChange({ email: e.target.value })}
          margin="dense"
          fullWidth
          type="email"
          variant="outlined"
          size="small"
          disabled={individualApplication || type === EntityTypes.user}
          required={visionTestRequired || type === EntityTypes.agency}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => {
            if (e.target.value.length !== 10) {
              setPhoneError("Please enter a valid phone number");
            } else {
              setPhoneError("");
            }
            onChange({ phone: e.target.value });
          }}
          error={phoneError.length > 0}
          helperText={phoneError}
          margin="dense"
          fullWidth
          variant="outlined"
          size="small"
          required={individualApplication || type === EntityTypes.agency}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: PhoneNumberFormattedInput,
            // inputProps: {
            //   pattern:
            //     individualApplication || type === EntityTypes.agency
            //       ? "\\([0-9]{3}\\) [0-9]{3} [0-9]{4}"
            //       : false,
            // },
          }}
        >
        </TextField>
      </Grid>
      {/* ={individualApplication || type !== EntityTypes.applicant}
      <Grid item xs={6}>
        <TextField
          label="Phone Ext"
          value={phone_extension}
          onChange={(e) => onChange({ phone_extension: e.target.value })}
          margin="dense"
          fullWidth
          type="number"
          size="small"
          variant="outlined"
        />
      </Grid> */}
      {type === EntityTypes.agency && (
        <Grid item xs={6}>
          <TextField
            label="Tax Id"
            value={tax_id}
            onChange={(e) => {
              setValidTaxid(e.target)
              // var format = /^0+$/;
              // if(e.target.value.match(format) || e.target.value.length<9) 
              // {
              //   setTaxidError('Please enter the valid taxid') 
              // } 
              // else  {    
              //   setTaxidError("") 
              // }
               //onChange({tax_id: e.target.value})
            }}
            error={taxidError.length > 0}
            helperText={taxidError}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: TaxIdFormattedInput,
              // inputProps:{
              //   pattern: '^(^0+$)'
              // }
              
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const CreateOrUpdateEntity = ({ entity, entityApi, onAction }) => {
  const { set: setNotification } = useNotification();

  // Work on a copy of the entity
  const [updatedEntity, setUpdatedEntity] = useState(EmptyProfile);
  const [createMode, setCreateMode] = useState(false);
  const [actionLabel, setActionLabel] = useState("");
  const [useFirstName, setUseFirstName] = useState(false);
  const [name, setName] = useState(false);
  const [disableActionClick, setDisableActionClick] = useState(false);

  // Update
  useEffect(() => {
    var fixedEntity = {
      ...EmptyProfile,
      ...{ type: entityApi.type },
      ...entity,
    };
    setUpdatedEntity(fixedEntity);
    setCreateMode(fixedEntity.id === 0);
    setActionLabel(fixedEntity.id === 0 ? "Create" : "Update");
    setUseFirstName(
      fixedEntity.type === EntityTypes.user ||
      fixedEntity.type === EntityTypes.applicant
    );
    setDisableActionClick(false);
  }, [entity, entityApi]);

  useEffect(() => {
    setName(
      useFirstName
        ? `${updatedEntity.first_name} ${updatedEntity.last_name}`
        : updatedEntity.name
    );
  }, [useFirstName, updatedEntity]);

  const onSaveEntity = () => {
    if (createMode) {
      axios
        .post(entityApi.createOne(), updatedEntity)
        .then(function (response) {
          setNotification({
            message: `${name} saved`,
            type: "success",
          });
          onAction(true, updatedEntity);
        })
        .catch(function (error) {
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
        });
    } else {
      axios
        .put(entityApi.updateOne(updatedEntity.id), updatedEntity)
        .then(function (response) {
          setNotification({
            message: `${name} saved`,
            type: "success",
          });
          onAction(true, updatedEntity);
        })
        .catch(function (error) {
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
        });
    }
  };

  // handleSubmit is called from two places
  // EditEntity in User/Agency is not working if not added to ValidatorForm
  // New user > Create new agency > its not working of not added to the button
  const handleSubmit = (event) => {
    event.preventDefault();
    setDisableActionClick(true);
    onSaveEntity();
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      instantValidate={false}
      onError={(errors) => console.log(errors)}
    >
      <EditEntity
        item={updatedEntity}
        onChange={(items) => setUpdatedEntity({ ...updatedEntity, ...items })}
      />
      <Grid
        container
        spacing={6}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            disabled={disableActionClick}
            color="primary"
            fullWidth
            startIcon={<SaveIcon />}
          // onSubmit={handleSubmit}
          >
            {actionLabel}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={disableActionClick}
            fullWidth
            startIcon={<CancelIcon />}
            onClick={() => onAction(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export { EditEntity, CreateOrUpdateEntity };
