import React, {useState} from 'react'
import {Button, CircularProgress, Drawer, Fab, Grid, Paper, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {endpoints, useApi} from '../../hooks/use-api';
import {useAuth} from '../../hooks/use-auth'
import SelectAgency from '../Agencies/SelectAgency'
import {Clear} from '@mui/icons-material';
import axios from 'axios';
import {useNotification} from '../../hooks/use-notification';

const PendingUserHome = (props) => {
    const [ itemsAsync, reload ] = useApi(endpoints.entity.user.loadPendingProfile());
    const { user } = useAuth();
    const [openDrawer, setOpenDrawer] = useState(false)

    const { set: setNotification } = useNotification();

    const withdrawRequest = () => {
        axios.post(endpoints.entity.user.withdraw(user.id))
            .then(function (response) {
                setNotification({
                    message: `Request withdrawn to join the agency`,
                    type: 'success',
                })
                setOpenDrawer(false)
                reload()                
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
            });        
    }

    return (
        <React.Fragment>
          {itemsAsync.loading && <CircularProgress />}
          {itemsAsync.error && <div>{itemsAsync.error.message}</div>}
          {itemsAsync.data && itemsAsync.data.length === 0 && <SelectAgency user={user} onSelect={() => reload()} /> }
          {itemsAsync.data && itemsAsync.data.length > 0 &&
            <React.Fragment>
                 <div className="confirmation-dialog">
                <Typography variant='h3' color='textPrimary' component="div">
                    { `${user.first_name} ${user.last_name}` }, your request has been sent to your supervisor.                    
                </Typography>
                <p>
                <Typography variant='h5' color='textSecondary' component="div">
                If you have selected the wrong agency and would like to withdraw your request. Click the withdraw button.
                </Typography>
                <br />
                <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CancelIcon />}
                        onClick={() => setOpenDrawer(true)}
                    >
                        Withdraw request
                    </Button>
                 </p>
                 <Typography variant='h4' color='textPrimary' component="div">
                    You <strong>will not </strong>be able to submit a New Eyes voucher application until your supervisor confirms your registration.                    
                </Typography>
                <br />
                <Typography variant='h5' color='textPrimary' component="div">
                For further assitance, please contact your supervisor:
                </Typography>
                {
                    itemsAsync.data.map(item => 
                        <div margin={50}>
                            <Typography variant='subtitle1' color='textSecondary'>{item.agency_name}</Typography>
                            <Typography variant='subtitle1' color='textSecondary'>{item.name}</Typography>
                            <Typography variant='subtitle1' color='textSecondary'>{item.email}</Typography>
                            <Typography variant='subtitle1' color='textSecondary'>{item.phone}</Typography>
                        </div>
                    )
                } 
                </div>
                <Drawer anchor='right' open={openDrawer} onClose={() => {setOpenDrawer(false)}} classes={{ paper: 'wide-drawer' }}>
                {
                    <Paper style={{ padding: '24px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <Typography variant='h6' color='textSecondary' component="div" style={{ marginBottom: '24px', textTransform: 'uppercase' }}>
                                    Withdraw Request
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Fab aria-label="cancel" onClick={e => setOpenDrawer(false)}>
                                    <Clear />
                                </Fab>
                            </Grid>
                        </Grid>
                        <div className="confirmation-dialog">
                            <Typography variant='h5' color='textSecondary' component="div">
                                Do you want to withdraw your request to join the agency?
                            </Typography>
                            <br/>
                            <React.Fragment>
                                <Button variant='contained' size="small" color='primary' onClick={() => withdrawRequest()}>Withdraw Request</Button>&nbsp;&nbsp;&nbsp;
                                <Button variant='contained' size="small" onClick={() => setOpenDrawer(false)}>Cancel</Button>
                            </React.Fragment>
                        </div>
                    </Paper>
                }
                </Drawer>
            </React.Fragment>
          }
        </React.Fragment>
    );
}

export default PendingUserHome

