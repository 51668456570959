export const aws_config = {
  // Auth: {
  //     identityPoolId: 'us-east-1:55848105-7aa6-492d-9319-344adf771dcb',
  //     region: 'us-east-1',
  //     userPoolId: 'us-east-1_wL01k67Hf',
  //     userPoolWebClientId: '2o7muruj64bepolvrm7620v3ih',
  //     mandatorySignIn: false
  // }

  Auth: {
    //identityPoolId: 'us-east-1:55848105-7aa6-492d-9319-344adf771dcb',
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USERPOOL,
    userPoolWebClientId: process.env.REACT_APP_API_WEBCLIENTID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  // Auth: {
  //     //identityPoolId: 'us-east-1:55848105-7aa6-492d-9319-344adf771dcb',
  //     region: 'us-east-1',
  //     userPoolId: 'us-east-1_Y1AAo2M9Q',
  //     userPoolWebClientId: '4kdu6r46detj8rl1h7sjfrton9',
  //     mandatorySignIn: false
  // }
};
