import React, {useEffect} from "react";
// import Preview from './Preview';
import {EditEntity} from "../../../Entities/EditEntity";
import ApplicantPanel from "./ApplicantPanel";
import {VoucherValidator} from "../Model";
import {MoveNext, useMultiStepForm,} from "../../../DataEntry/MultiFormContext";

const ApplicantForm = ({ page, formProps }) => {
  const {
    values: data,
    setFieldValue,
    isSubmitting,
    isValid,
    submitForm,
  } = formProps;
  const { setEnableNext } = useMultiStepForm();

  // const [ready, setReady] = useState(false);

  useEffect(() => {
    console.log(data);
    if (
      page === 0 &&
      isSubmitting &&
      isValid &&
      VoucherValidator.isApplicantDataComplete(data)
    ) {
      setEnableNext(MoveNext.Page);
    }
  }, [isSubmitting, isValid]);

  switch (page) {
    case 0:
      return (
        <EditEntity
          item={data.applicant}
          individualApplication={data.individualApplication}
          visionTestRequired={data.canTakeVisionTest}
          onChange={(updatedItem) =>
            setFieldValue("applicant", {
              ...data.applicant,
              ...updatedItem,
            })
          }
        />
      );
    case 1:
      return (
        <ApplicantPanel
          data={data}
          onSelect={(id) => {
            console.log(id);
            // Update applicant with entity id
            setFieldValue("applicant", {
              ...data.applicant,
              id: id,
            });
            setEnableNext(MoveNext.Step);
          }}
        />
      );
    default:
      return <React.Fragment />;
  }
};

export default ApplicantForm;
