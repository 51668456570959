import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../hooks/use-api";
import CreateVoucher from "../Vouchers/CreateVoucher";

const OrderHome = () => {
  const { order_id } = useParams();
  const [error, setError] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    if (redirectUrl.length > 0) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  useEffect(() => {
    // Validate and preload individual information
    axios
      .get(endpoints.order.load(order_id))
      .then(function (response) {
        if (response.data.valid) {
          setRedirectUrl(response.data.redirectUrl);
        } else {
          setError("The link is invalid");
        }
      })
      .catch(function (err) {
        setError("Server error. Please retry again later.");
        console.log(err.message);
      });
  }, [order_id]);

  return error ? (
    <Typography variant="h6">{error}</Typography>
  ) : (
    <Typography variant="h6">Loading...</Typography>
  );
};

export default OrderHome;
