import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { endpoints } from "../../../../hooks/use-api";
import { useNotification } from "../../../../hooks/use-notification";
// import Preview from './Preview';
import { MoveNext, useMultiStepForm } from "../../../DataEntry/MultiFormContext";
import VoucherDetailSection from "../../ViewVoucher/VoucherDetailSection";
import { VoucherValidator } from "../Model";
import Certification from "./Certification";
import { Navigate } from "react-router-dom";
import Payment from "../Payment";

const FinalPreview = ({ page, formProps, onExit }) => {
  const {
    values: data,
    setFieldValue,
    isSubmitting,
    isValid,
    errors,
    submitForm,
  } = formProps;
  const { setEnableNext } = useMultiStepForm();
  const { set: setNotification } = useNotification();

  // const [ready, setReady] = useState(false);
  const [savingVoucherFlag, setSavingVoucherFlag] = useState(false);

  useEffect(() => {
    if (!VoucherValidator.isReadyForSubmission(data)) {
      setEnableNext(MoveNext.ValidationRequired);
      return
    }
    if (isSubmitting && isValid) {
      submitVoucher();
    }
  }, [data, isSubmitting, isValid]);

  const submitVoucher = () => {
    if (!savingVoucherFlag) {
      var voucher = VoucherValidator.getVoucher(data);
      console.log(voucher);
      setSavingVoucherFlag(true);
      
      axios
        .post(data.createApi, voucher)
        .then(function (response) {
          if (response.data.success) {
            onExit(response.data.paymentNeeded, response.data.paymentUrl);
          }
        })
        .catch(function (error) {
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
          setSavingVoucherFlag(false);
        });
    }
  };

  return (
    <React.Fragment>
      <VoucherDetailSection voucher={data} showUserInfo={false} />
      <TextField
        label="Additional notes"
        value={data.voucher_detail.additional_notes}
        onChange={(e) =>
          setFieldValue("voucher_detail", {
            ...data.voucher_detail,
            ...{ additional_notes: e.target.value },
          })
        }
        margin="dense"
        variant="outlined"
        fullWidth
        rows={3}
        maxRows={12}
        multiline
        InputProps={{
          inputProps: { maxLength: 255 },
        }}
      />
      <Certification data={data} errors={errors} />
    </React.Fragment>
  );
};

export default FinalPreview;
