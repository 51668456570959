import React from 'react'

import EntitySearch from '../Entities/EntitySearch'
// import { Fab, Tooltip } from '@mui/material';
// import { Add } from '@mui/icons-material';
import {endpoints, useApi} from '../../hooks/use-api';
import {CircularProgress, Divider, Typography} from '@mui/material';
import {AddressView1} from '../../common/AddressView';
import VoucherActionList from '../Vouchers/ViewVoucher/VoucherActionList';

const ApplicantSummaryPanel = ({item}) => {
    const [ { data, loading, error } ]
            = useApi(endpoints.entity.applicant.loadSummary(item.id));

    // Show affilicated agency
    // Show latest voucher for the applicant
    return (
        <React.Fragment>
            { loading && <CircularProgress /> }
            { error &&
                <Typography variant='caption' color='error' component="div">
                    Failed to load voucher detail
                </Typography>}
            { data && data.voucher && data.voucher.agency &&
                <React.Fragment>
                    {/* <Divider style={{ marginBottom: '12px' }}/> */}
                    <AddressView1 {...data.voucher.agency} title="Affiliated Agency" />
                    { data.voucher.actions.length > 0 && 
                        <React.Fragment>
                            <Divider style={{ marginTop: '12px', marginBottom: '12px' }}/>
                            <Typography variant="button" color="textSecondary">Last Voucher# {data.voucher.id}</Typography>                           
                            <VoucherActionList actions={data.voucher.actions} />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const ApplicantHome = ({agencyId, useSearchOption = true}) => {

    return <EntitySearch
                entityApi = {endpoints.entity.applicant}
                agencyId={agencyId}
                extendPanel={({item}) => <ApplicantSummaryPanel item={item} />}
                useSearchOption={useSearchOption}
            />
}

export default ApplicantHome;
