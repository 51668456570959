import React from "react";

import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const useOutlinedDivStyles = makeStyles({
  root: ({ onClick }) => ({
    cursor: onClick ? 'pointer' : 'default'
  })
})

const OutlinedDiv = (props) => {

  let { children, label, onClick } = props

  const classes = useOutlinedDivStyles(props)

  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth={true}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        className: classes.root
      }}
      inputProps={{ children }}
      onClick={onClick}
      className={classes.root}
    />
  );
};

export default OutlinedDiv;
