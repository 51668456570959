// import { Box, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import { MainContainer, MainContent } from "../DataEntry/StyledFields";
import VerifyVoucherForm from "./VerifyVoucherForm";
import { useSearchParams } from 'react-router-dom';
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import { SystemMessages } from "../../common/Constants";

const OrderSuccessHome = () => {
  const { set: setNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get("session_id");

  const [done, setDone] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    if (session_id && !done) {
      axios.post(endpoints.order.success(), {
        session_id: session_id
      })
        .then(function (response) {
          console.log(response.data);
          setRedirectUrl(response.data.redirectUrl);
          setDone(true);
        })
        .catch(function (error) {
          console.log(error);
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
        });
    }
  }, [done, session_id]);

  return (
    <MainContainer>
      <MainContent>
        <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
          <img src={logo} width="300px" alt="logo" />
        </Box>
        {!done && <Typography variant="h6">Please wait...</Typography>}
        {done && <>
          <Typography variant="h6" style={{ margin: "20px" }}>{SystemMessages.PAYMENT_RECEIVED}</Typography>
          <Button variant="contained" style={{ margin: "20px" }} color="primary" href={redirectUrl}>Back to Home</Button>
        </>}
      </MainContent>
    </MainContainer>
  );
}

export default OrderSuccessHome;
