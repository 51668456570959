import React, {useState} from 'react'
import cx from 'classnames'
import AgencyHeader from './AgencyHeader'
import {AppBar, Button, Grid} from '@mui/material';
import Fab from '@mui/material/Fab';
import {Clear} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import {endpoints} from '../../hooks/use-api'
import {useNotification} from '../../hooks/use-notification';
import EntitySearch from '../Entities/EntitySearch';
import ApplicantHome from '../Applicants';
import {SearchVoucher} from '../Vouchers/SearchVoucher';
import UserExtendedSection from '../Users/UserExtendedSection'

const AgencyMenuSelection = ({ menu, menuSelected, onChange }) => (
    <li className="column is-narrow voucher-status-sel clickable">
        <Button variant='outlined' style={{ border: 'none' }} className={cx({ selected: menu === menuSelected })}>
            <span onClick={() => onChange(menu)}>
                { menu }
            </span>
        </Button>
    </li>
)

const useStyles = makeStyles({
    head: {
        paddingTop: 12
    },
    divider: {
      marginTop: 6,
      marginBottom: 6
    },
  });

const OneAgency = ({ agency, onExit }) => {

    const [menuSelected, setMenuSelected] = useState('USERS');
    const { set: setNotification } = useNotification();

    let { id: agencyId } = agency

    let classes = useStyles()

    const cardItemFn = (voucher) => (
        {
        title: `Voucher # ${voucher.id}`,
        t1: voucher.applicant_name,
        d1: voucher.datetime,
        t2: voucher.user_name,
        status: voucher.status,
        note: voucher.note 
        }
    );

    return (
        <React.Fragment>
            <AppBar position={"static"} color='inherit'>
                <Grid container spacing={1} className={classes.head}>
                    <Grid item xs={10} style={{ paddingLeft: '38px' }}>
                        <AgencyHeader agency={agency} />
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: '38px' }}>
                        <Fab aria-label="cancel" onClick={onExit}>
                            <Clear/>
                        </Fab>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' style={{ paddingBottom: '18px' }}>
                    <ul className={cx("result-filters")}>
                    {
                        ["USERS", "VOUCHERS", "APPLICANTS"].map(menu => (
                            <AgencyMenuSelection menu={menu} menuSelected={menuSelected} onChange={setMenuSelected} />
                        ))
                    }
                    </ul>
                </Grid>
            </AppBar>
            <div>
                { menuSelected === "USERS" &&
                    <EntitySearch
                        entityApi={endpoints.entity.user}
                        agencyId={agencyId}
                        extendPanel={({item, onClose, reload}) => <UserExtendedSection user={item} reload={() => {onClose(); reload(); }} />}
                        useSearchOption={false}
                    /> }
                { menuSelected === "VOUCHERS" && 
                    <SearchVoucher agencyId={agencyId} useSearchOption={false} />
                }
                { menuSelected === "APPLICANTS" && <ApplicantHome agencyId={agencyId} useSearchOption={false}  /> }
            </div>
        </React.Fragment>
    );
}

export default OneAgency