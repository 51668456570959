import React from 'react';
import {Button, FormControl, Grid, Input, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Email from '@mui/icons-material/Person';
import logo from '../../images/logo.png';
import {useFormState} from 'react-use-form-state';
import {useAuth} from '../../hooks/use-auth';
import { useNavigate, useSearchParams } from "react-router-dom";
import {NotificationType, useNotification,} from '../../hooks/use-notification';
// import { useRouter } from '../../hooks/use-router';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3),
    padding: '10px',
    minWidth: '200px',
  },
  input: {
    margin: theme.spacing(2),
    padding: '10px',
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { resetPasswordConfirm, resetPassword } = useAuth();
  const navigate = useNavigate();
  // const router = useRouter();
  const [searchParams, _setSearchParams] = useSearchParams();

  // const email = searchParams.get('email');
  // const code = searchParams.get('code');

  const [formState, { email, password, text }] = useFormState({
    email: searchParams.get('email') || undefined,
    code: searchParams.get('code') || undefined,
  });

  const { set: setNotification } = useNotification();

  // const sendCode = (email, message) => {
  //   resetPassword(email)
  //     .then(() => {
  //       setNotification({
  //         type: NotificationType.success,
  //         message: message,
  //       });
  //       navigate('/');
  //     })
  //     .catch(err => {
  //       setNotification({
  //         type: NotificationType.error,
  //         message: err.message,
  //       });
  //     });
  // };
  const handleSubmit = event => {
    event.preventDefault();

    if (formState.values.password !== formState.values.password_again) {
      setNotification({
        type: NotificationType.error,
        message: `Passwords don't match`,
      });
      return;
    }

    resetPasswordConfirm(
      formState.values.email,
      formState.values.code,
      formState.values.password
    )
      .then(nodata => {
        setNotification({
          type: NotificationType.success,
          message: `Password reset completed`,
        });
        navigate('/');
      })
      .catch(err => {
        if (err.code === 'ExpiredCodeException') {
          setNotification({
            type: NotificationType.error,
            message: `Verification link expired. Check email for latest code or reset password again.`,
          });           
          // sendCode(
          //   formState.values.email,
          //   `Verification link expired. Check email for a new code.`
          // );
        } else if (err.code === 'CodeMismatchException') {
          setNotification({
            type: NotificationType.error,
            message: `Verification link invalid. Check email for latest code or reset password again.`,
          });                
          // sendCode(
          //   formState.values.email,
          //   `Verification link invalid. Check email for a new code.`
          // );
        } else if (err.code === 'UserNotFoundException') {
          setNotification({
            type: NotificationType.error,
            message: `User not found`,
          });
        } else {
          setNotification({
            type: NotificationType.error,
            message: err.message,
          });
        }
      });
  };

  return (
    <Paper>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <img src={logo} width="300px" alt="logo" className="logo-img" />

        <Grid item xs={6} sm={6}>
          <form onSubmit={handleSubmit}>
            {!searchParams.get('email') ? (
              // Hide email input if query provided email
              <FormControl fullWidth>
                <Input
                  {...email('email')}
                  required
                  className={classes.input}
                  placeholder="Email"
                  startAdornment={
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            ) : (
              ''
            )}
            {!searchParams.get('code') ? (
              // Hide code input if query provided code
              <FormControl
                fullWidth
                style={{
                  visibility: searchParams.get('code') ? 'collapse' : 'visible',
                }}
              >
                <Input
                  {...text('code')}
                  required
                  className={classes.input}
                  placeholder="Verification Code"
                  startAdornment={
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            ) : (
              ''
            )}
            <FormControl fullWidth>
              <Input
                {...password('password')}
                required
                className={classes.input}
                placeholder="New Password"
                startAdornment={
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Input
                {...password('password_again')}
                required
                className={classes.input}
                placeholder="Repeat Password"
                startAdornment={
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                }
              />
            </FormControl>

            <Grid container direction="row" justifyContent="center" spacing={4}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                Reset Password
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResetPassword;
