import React from 'react'
import {Grid, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

function pushIfExists(arr, item) {
    if(item) {
        arr.push(item)
    }
}

function addressString(agency) {
    let res = []
    pushIfExists(res, agency.address1)
    pushIfExists(res, agency.address2)
    pushIfExists(res, agency.city)
    pushIfExists(res, agency.state)
    pushIfExists(res, agency.country)
    let str = res.join(', ')
    if(agency.zip) {
        str += (' - ' + agency.zip)
    }
    return str
}

const useStyles = makeStyles({
  addrDivider: {
    paddingLeft: '12px',
    paddingRight: '12px'
  },
});

const AgencyHeader = ({ agency }) => {

    let { name } = agency

    let classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ paddingLeft: '12px' }}>
                <Typography variant='h4'>{name}</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: '12px' }}>
                <Grid container>
                    <Typography variant='subtitle1'>{addressString(agency)}</Typography>
                    { agency.phone && <Typography className={classes.addrDivider} variant='subtitle1'>&#9679;</Typography> }
                    { agency.phone && <Typography variant='subtitle1'>{agency.phone}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AgencyHeader