import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../hooks/use-api";
import CreateVoucher from "../Vouchers/CreateVoucher";

const IndividualApplication = () => {
  const { individual_id } = useParams();
  const [error, setError] = useState();
  const [indUser, setIndUser] = useState({
    identifier: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const [redirctTo, setRedirctTo] = useState(false);

  useEffect(() => {
    // Validate and preload individual information
    axios
      .get(endpoints.apply.load(individual_id))
      .then(function (response) {
        if (
          response.data !== null &&
          response.data.identifier &&
          response.data.identifier.length > 10
        ) {
          setIndUser(response.data);
          setRedirctTo(true);
          console.log(response.data);
        } else {
          setError("The link is invalid");
        }
        //?? onExit(response.data);
      })
      .catch(function (err) {
        setError("Server error. Please retry again later.");
        console.log(err.message);
      });
  }, [individual_id]);

  return redirctTo ? (
    // <Redirect
    //   to={{
    //     pathname: `/apply/${individual_id}/form`,
    //     indUser: indUser,
    //   }}
    // />
    <CreateVoucher individualApplication={true} indUser={indUser} />
  ) : error ? (
    <Typography variant="h6">{error}</Typography>
  ) : (
    <Typography variant="h6">Loading...</Typography>
  );
};

export default IndividualApplication;
