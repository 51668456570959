import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useApi } from "../hooks/use-api";
import { EmptyOptions } from "./Constants";

/*
export const options = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
    { name: 'Other', value: 'other' }
]
*/

export const TextSelect = ({
  label,
  value,
  options,
  onChange,
  disabled = false,
  multiple = true,
}) => {
  const [initialValue, setInitialValue] = useState();
  const [optionValue, setOptionValue] = useState(null);

  useEffect(() => {
    // console.log("initial value: " + value)
    if (value === "") {
      setInitialValue(null);
      setOptionValue(null);
    } else {
      let option = options.find((option) => option.value === value);
      setInitialValue(option ? option : null);
      setOptionValue(option ? option : null);
    }
  }, [value]);

  // useEffect(() => console.log("useEffect: " + value), [value])

  // getOptionSelected={(option, value) => option.name === value.name}
  // variant='outlined'
  // getOptionLabel={option => option.name}

  return (
    <Autocomplete
      value={optionValue}
      defaultValue={initialValue}
      disabled={disabled}
      autoComplete={false}
      onChange={(event, newValue) => {
        setOptionValue(newValue);
        onChange(newValue.value);
      }}
      // inputValue={value}
      // onInputChange={(event, newValue) => {
      //   // console.log("new value: " + newValue)
      //   onChange(newValue);
      // }}
      options={options}
      multiple={multiple}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        value && option.name === value.name
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          variant="outlined"
          autoComplete="off"
          fullWidth
        />
      )}
      size="small"
    />
  );
};

export const TextSelect2 = ({
  label,
  value,
  options,
  onChange,
  multiple = true,
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      // variant='outlined'
      options={options}
      getOptionLabel={(option) => option.name}
      multiple={multiple}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
      size="small"
      // InputLabelProps={{
      //     shrink: true,
      // }}
    />
  );
};

// export const TextSelectApi = ({  label, value, api, onChange }) => {

//     const [ { data, loading, error } ] = useApi(api())

//     return (
//         <>
//             { loading && <div>Loading {label}</div> }
//             { error && <div>error</div>}
//             { data &&
//                 <TextSelect
//                     label = {label}
//                     value = {value}
//                     options = {data}
//                     onChange = {onChange}
//                 />
//             }
//         </>
//     );
// }

export const TextSelectPostApi = ({
  label,
  value,
  apifn,
  postData,
  showEmptyFn,
  onChange,
}) => {
  const [{ data, loading, _error }, refetch] = useApi(
    { url: apifn(), method: "post" },
    { manual: true }
  );

  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    let empty = showEmptyFn(postData);
    if (!empty) {
      refetch({ data: postData });
    }
    setShowEmpty(empty);
  }, [postData]);

  if (showEmpty) {
    return (
      <TextSelect2
        label={label}
        value={value}
        options={EmptyOptions}
        onChange={onChange}
      />
    );
  } else {
    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        multiple={true}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={data ? data : []}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
};
