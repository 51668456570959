import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useApi } from "../hooks/use-api";

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const TextSearch = ({ apiFn, dataFn, resultsFn, searchTextFn, ...rest }) => {
  const [_returnAsync, postData] = useApi(
    {
      url: apiFn(),
      method: "post",
    },
    { manual: true }
  );

  // If no search text use empty resultset
  const onChange = (value) =>
    value.length > 0
      ? postData({ data: dataFn(value) }).then((response) => {
          resultsFn({ data: response.data, loading: false, error: false });
        })
      : resultsFn({ data: [], loading: false, error: false });

  const debounceFn = React.useCallback(debounce(onChange, 400), []);

  return (
    <TextField
      fullWidth
      onChange={(e) => {
        if (searchTextFn) searchTextFn(e.target.value);
        debounceFn(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="primary" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default TextSearch;
