import {
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const AgencyPaymentSection = ({
  order_uuid,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#FFBF00",
        textAlign: "center",
        verticalAlign: "middle",
      }}
    >
      <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
      <Typography variant="h6" color="textPrimary">
        Payment Needed
      </Typography>

      <Typography variant="subtitle2" color="textPrimary" mb="20px">
        Please pay the application fee to complete your application for review!
      </Typography>
      <Link to={`/order/${order_uuid}`}>
        <Button variant="outlined" >Pay here</Button>
      </Link>

      <br />
      <br />
    </div>
  );
};

export default AgencyPaymentSection;
