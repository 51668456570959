import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import {Link} from 'react-router-dom';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const useStyles = makeStyles({
  list: {
    width: 250,
  }
});

export function SiteMenuDrawer({open, onClose}) {
  const classes = useStyles();

  const onKeyDown = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={onClose}
      onKeyDown={onKeyDown()}
    >
      <List>
        {[
          { icon: <ListAltIcon />, text: 'Vouchers', to: '/vouchers' },
          { icon: <LocationCityIcon />, text: 'Agencies', to: '/agencies' },
          { icon: <ShoppingCartIcon />, text: 'Dispensers', to: '/dispensers' },
          { icon: <PersonIcon />, text: 'Reports', to: '/reports' },
         ].map((m, index) => (
          <ListItem button key={m.text} component={props => <Link to={m.to} {...props} />}>
            <ListItemIcon>{m.icon}</ListItemIcon>
            <ListItemText primary={m.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer open={open} onClose={onClose}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default SiteMenuDrawer;

