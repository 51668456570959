import { Email, Person, Phone, VpnKey } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Grid, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState, useRef } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useFormState } from "react-use-form-state";
import PhoneNumberFormattedInput from "../../common/PhoneNumberFormattedInput";
import { useAuth } from "../../hooks/use-auth";
import {
  NotificationType,
  useNotification,
} from "../../hooks/use-notification";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { MainContainer, MainContent } from "../DataEntry/StyledFields";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3),
    padding: "10px",
    minWidth: "200px",
  },
  input: {
    margin: theme.spacing(2),
    padding: "10px",
  },
}));

const Registration = () => {
  const classes = useStyles();
  const [formState, { email: email2, text, raw, password: password2 }] =
    useFormState();
  const { register } = useAuth();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { set: setNotification } = useNotification();

  const captchaRef = useRef(null);

  useEffect(() => {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== password) {
        return false;
      }
      return true;
    });
  }, [password]);

  const handleSubmit = () => {
    // event.preventDefault();
    setErrorMsg(null);

    register(email, password, {
      name: `${firstName} ${lastName}`,
      given_name: firstName,
      family_name: lastName,
      phone_number: "+1" + phone,
    }, captchaRef.current.getValue())
      .then((data) => {
        // setSuccessMsg(data.message);
        setNotification({
          type: NotificationType.success,
          message: `Success! Please check your email to confirm your account.`,
        });
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setNotification({
          type: NotificationType.error,
          message: err.message.includes("password")
            ? "Password must be 8 characters, upper and lower case and numeric and symbol"
            : err.message,
        });
      });
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      instantValidate={false}
      onError={(errors) => console.log(errors)}
    >
      <MainContainer>
        <MainContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
                <img src={logo} width="300px" alt="logo" />
              </Box>
              <Typography variant="h5" m={2}>
                Thank you for partnering with New Eyes! Together we help those
                in need see clearly and build better lives.
              </Typography>
              <Typography variant="subtitle1" m={2}>
                Please provide your email address for registration
              </Typography>
            </div>

            {/* // style={{ minHeight: '80vh' }}> */}
            {successMsg ? (
              <span className="successSpan">{successMsg}</span>
            ) : (
              ""
            )}
            {errorMsg ? <span className="errorSpan">{errorMsg}</span> : ""}
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="dense"
                fullWidth
                variant="standard"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="dense"
                fullWidth
                variant="standard"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                margin="dense"
                fullWidth
                type="phone"
                variant="standard"
                required
                InputProps={{
                  inputComponent: PhoneNumberFormattedInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="dense"
                fullWidth
                type="email"
                variant="standard"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="dense"
                fullWidth
                type="password"
                variant="standard"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                label="Repeat Password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                margin="dense"
                fullWidth
                type="password"
                variant="standard"
                validators={["isPasswordMatch", "required"]}
                errorMessages={[
                  "password do not match",
                  "this field is required",
                ]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                ref={captchaRef}
              />
            </Grid>            
            <p>&nbsp;</p>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  Sign Up
                </Button>
              </Grid>
              <Grid item xs={2}>&nbsp;</Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CancelIcon />}
                  onClick={() => navigate("/")}
                >
                  Back to Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </MainContent>
      </MainContainer>
    </ValidatorForm>
  );
};

export default Registration;
