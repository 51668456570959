import React from 'react'

import EntitySearch from '../Entities/EntitySearch'
import {endpoints} from '../../hooks/use-api'
import UserExtendedSection from './UserExtendedSection'

const UserHome = () => {

    return <EntitySearch
                entityApi = {endpoints.entity.user}
                extendPanel={({item, onClose, reload}) => <UserExtendedSection user={item} reload={() => {onClose(); reload(); }} />}
            />

}

export default UserHome;

