import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useAuth} from '../../hooks/use-auth';
// import {useRouter} from '../../hooks/use-router';
import {NotificationType, useNotification,} from '../../hooks/use-notification';
import { useNavigate, useSearchParams } from 'react-router-dom';

// const useStyles = makeStyles(theme => ({
//   button: {
//     margin: theme.spacing(3),
//     padding: '10px',
//     minWidth: '200px',
//   },
//   input: {
//     margin: theme.spacing(2),
//     padding: '10px',
//   },
// }));

const RegistrationVerification = () => {
  // const classes = useStyles();
  // const { registerConfirm, resendSignupVerificationCode } = useAuth();
  const { registerConfirm } = useAuth();
  // const router = useRouter();
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  // const [formState, { email, password, text }] = useFormState({
  //   email: router.query.email || undefined,
  //   code: router.query.code || undefined,
  // });
  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const { set: setNotification } = useNotification();
  // const sendCode = (email, message) => {
  //   resendSignupVerificationCode(email)
  //     .then(() => {
  //       setNotification({
  //         type: NotificationType.success,
  //         message: message,
  //       });
  //       navigate('/');
  //     })
  //     .catch(err => {
  //       setNotification({
  //         type: NotificationType.error,
  //         message: err.message,
  //       });
  //     });
  // };

  useEffect(() => {
    if (email && code) {
      registerConfirm(email, code)
        .then(data => {
          setNotification({
            type: NotificationType.success,
            message: `Email Confirmed. You can now log in.`,
          });
          navigate('/');
        })
        .catch(err => {
          if (err.code === 'ExpiredCodeException') {
            setNotification({
              type: NotificationType.error,
              message: "Verification link expired. Please login and check the status of your verification.",
            });            
            // sendCode(
            //   router.query.email,
            //   `Verification link expired. Check email for a new code.`
            // );
          } else if (err.code === 'CodeMismatchException') {
            setNotification({
              type: NotificationType.error,
              message: `Verification link invalid. Check email for latest code or reset password again.`,
            });             
            // sendCode(
            //   router.query.email,
            //   `Verification link invalid. Check email for a new code.`
            // );
          } else if (err.code === 'UserNotFoundException') {
            setNotification({
              type: NotificationType.error,
              message: `User not found`,
            });
          } else {
            setNotification({
              type: NotificationType.error,
              message: err.message,
            });
          }

          navigate('/');
        });
    }
    //eslint-disable-next-line
  }, []);

  // if (router.query.email && router.query.code) {
  //   return null;
  // }

  // const handleSubmit = event => {
  //   event.preventDefault();

  //   registerConfirm(formState.values.email, formState.values.code)
  //     .then(data => {
  //       setNotification({
  //         type: NotificationType.success,
  //         message: `Email Confirmed. You can now log in.`,
  //       });
  //       navigate('/');
  //     })
  //     .catch(err => {
  //       setNotification({
  //         type: NotificationType.error,
  //         message: `Failed to validate code. Press resend to obtain a new code.`,
  //       });
  //     });
  // };

  // const handleResend = event => {
  //   event.preventDefault();

  //   resendRegisterCode(formState.values.email)
  //     .then(data => {
  //       setNotification({
  //         type: NotificationType.success,
  //         message: `Success! Please check your email to confirm your account.`,
  //       });
  //       navigate('/');
  //       // navigate({
  //       //   pathname: 'registration/verify',
  //       //   search: { email: formState.values.email },
  //       // });
  //     })
  //     .catch(err => {
  //       setNotification({
  //         type: NotificationType.error,
  //         message: err.message,
  //       });
  //     });
  // };

  return <div></div>;
};

export default RegistrationVerification;
