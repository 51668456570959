import React, {  } from 'react'
import { Button, Grid, Typography, Box } from '@mui/material';
//import NumberFormattedInput from '../../../common/NumberFormattedInput';
import logo from "../../../../images/logo.png";
import { SystemMessages } from '../../../../common/Constants';

const Payment = ({ paymentRequired, paymentUrl, onExit }) => {
 
  // let { paymentRequired = true, payment_screen = "" } = data.voucher_detail
  const payLaterClick = () => {
    if (onExit) {
      onExit();
    } else {
      window.location.href = "https://new-eyes.org/";
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        {!onExit && <Grid item xs={12}>
          <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
            <img src={logo} width="300px" alt="logo" />
          </Box>
        </Grid>
        }        
        <Grid item xs={12} padding={"20px"}>
          <Typography variant='h5' className='text-highlight'>Application Submitted</Typography>
        </Grid>
        {!paymentRequired &&
          <Grid item xs={12}>
            <Typography variant="h6">{SystemMessages.PAYMENT_INVOICE_OPTION}</Typography>
            <p />
            <a href="https://new-eyes.org/">Click here</a> to visit our home page.
          </Grid>
        }
        {paymentRequired &&
          <Grid item xs={12}>
            <Typography variant="h6">Thank you for submitting your application for an E-Voucher for a pair of New Eyes eyeglasses. A payment link for the application fee has been
              sent to your email.</Typography>
            <p />
            <Typography variant="h6">Once you have made the payment, we will send you an email with further
              instructions after we have reviewed your application.</Typography>
            <Box padding={"20px"} sx={{ fontWeight: 'bold' }}>If you are ready to pay now, you can use the "Pay now" button below to make
              the payment.</Box>
            <Button fullWidth variant="contained" color="primary" onClick={() => window.location.href = paymentUrl}>Pay Now</Button>
            <p />&nbsp;
            <Button fullWidth variant="contained" color="primary" onClick={payLaterClick}>Pay Later</Button>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default Payment;
