import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, TablePagination, Typography } from '@mui/material'
import { useApi, endpoints } from "../../hooks/use-api";
import MaterialTable from '@material-table/core';
import { format } from 'date-fns'

const FPL = (props) => {
    const [{ data, loading, error }] = useApi(endpoints.fpl.load(), { useCache: false });

    return (
        <Paper
            style={{
                maxWidth: "100%",
                padding: "16px",
                height: "90%",
                overflowX: "scroll",
            }}
        >
            <Grid item xs={12}>
                <MaterialTable
                    components={{
                        Pagination: (props) => {
                            return (
                                <td>
                                    <table
                                        style={{
                                            position: "fixed",
                                            bottom: 0,
                                            left: 0,
                                            width: "100%",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <TablePagination {...props} />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            );
                        },
                        // Toolbar: () =>
                        //     <div style={{ padding: '16px' }}>
                        //         <ColumnSelector
                        //             options={columns}
                        //             selected={selectedColumns}
                        //             setSelected={setSelectedColumns}
                        //         />
                        //     </div>
                    }}
                    columns={[
                        { title: "Year", field: "year" },
                        { title: "Single Income", field: "single_income" },
                        { title: "Additional Per Member", field: "additional_per_member" },
                        // { title: "Status", field: "status" },
                        // {
                        //     title: "Amount",
                        //     render: (rowData) =>
                        //         `$ ${rowData.voucher_financial.neweyes_amount}`,
                        // },
                    ]}
                    data={data}
                    options={{
                        exportButton: false,
                        showTitle: false,
                        search: false,
                        // exportCsv: onExport,
                        pageSize: 50,
                        pageSizeOptions: [50, 100, 200, 300],
                        paging: true,
                        
                    }}
                    isLoading={loading}
                />
            </Grid>
        </Paper>
    );
}

export default FPL;
