import React, {useEffect, useState} from 'react'
import {AddressView1} from '../../common/AddressView'
import {CreateOrUpdateEntity} from './EditEntity'
import {Fab, Grid, Paper, Typography} from '@mui/material';
import {Clear} from '@mui/icons-material';
import {EntityTypes} from '../../common/Constants';

const EntityPanel = ({title, item, showUpdate = true, entityApi, itemfn = item => item, saveUri, onClose, reload, onAutoClose, extendPanel, prepandPanel}) => {

    const [page, setPage] = useState("view")
    const [allowEdit, setAllowEdit] = useState(showUpdate)

    useEffect(() => {
        if (onAutoClose) {
            onAutoClose(page === "view")
        }
    }, [page, onAutoClose])

    useEffect(() => {
        if (showUpdate) {
            setAllowEdit(item.type !== EntityTypes.applicant)
        }
    }, [item, showUpdate])

    return (        
        <Paper style={{ padding: '24px' }}>
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Typography variant='h6' color='textSecondary' component="div" style={{ marginBottom: '24px', textTransform: 'uppercase' }}>
                        {title || entityApi.title}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Fab aria-label="cancel" onClick={onClose}>
                        <Clear />
                    </Fab>
                </Grid>            
            </Grid>
            {page === "view" &&
                <React.Fragment>
                    <AddressView1 { ...itemfn(item) }
                        onEdit={allowEdit ? (() => setPage('edit')) : null} />
                    {extendPanel &&
                        <React.Fragment>
                            <hr />
                            {extendPanel({item, onClose, reload})}
                      </React.Fragment>
                    }
                </React.Fragment>
            }
            {page === "edit" && <CreateOrUpdateEntity entity={itemfn(item)}
                                    entityApi={entityApi}
                                    onAction={(saved, newEntity) => {
                                        setPage('view')
                                        if (saved) {
                                            // onChange(newEntity)
                                            onClose()
                                            reload()
                                        } 
                                      }
                                    } /> }                                    
        </Paper>
    )
}

export default EntityPanel;
