import { Clear } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import cx from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import { UserTypes } from "../../../common/Constants";
import { endpoints, useApi } from "../../../hooks/use-api";
import { useNotification } from "../../../hooks/use-notification";
import VoucherActionList from "./VoucherActionList";
import VoucherDetailSection from "./VoucherDetailSection";
import FinancialSection from "./FinancialSection";
import DispenserSection from "./DispenserSection";
import AgencyOrderSection from "./AgencyOrderSection";
import AgencyPaymentSection from "./AgencyPaymentSection";

const VoucherPanel = ({
  voucher: voucher2,
  userType,
  onEdit,
  onClose,
  onAction,
}) => {
  // Load voucher detail page
  const [{ data: voucher, loading: voucherLoading, error: voucherError }] =
    useApi(endpoints.entity.voucher.loadOne(voucher2.id), { useCache: false });

  const [addNoteMode, setAddNoteMode] = useState({
    showNote: false,
    required: true,
    noteLabel: "Type the note",
    action: "addNoteOnly",
  });
  const [acionNote, setActionNote] = useState("");

  const { set: setNotification } = useNotification();

  const setVoucherStatus = (id, action) => {
    axios
      .put(endpoints.entity.voucher.updateOne(id), action)
      .then(function (response) {
        setNotification({
          message: `Updated voucher status`,
          type: "success",
        });
        onAction(id, action);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  //??  useEffect(() => console.log(voucher), [voucher]);

  if (voucher2.id === 0) {
    return <div>Select a voucher</div>;
  }

  if (voucherLoading) return <CircularProgress />;
  if (voucherError)
    return (
      <Typography variant="caption" color="error" component="div">
        Failed to load voucher
      </Typography>
    );
  
  var current = voucher.actions[0];

  return (
    <React.Fragment>
      {voucherLoading && <CircularProgress />}
      {voucherError && (
        <Typography variant="caption" color="error" component="div">
          Failed to load voucher
        </Typography>
      )}
      {voucher && (
        <div style={{ padding: "24px" }}>
          <Grid container>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary">
                    VOUCHER # {voucher.id}
                    {voucher.voucher_detail.is_reissued &&
                      <>&nbsp;&nbsp;
                      <Typography
                        variant="button"
                        style={{
                          marginRight: "2px",
                          padding: "6px",
                          border: "1px solid lightgray",
                          borderRadius: "4px",
                          textTransform: "uppercase",
                          backgroundColor: "#dde8ed",
                        }}
                      >
                        reissued
                      </Typography>
                      </>
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="button"
                    className={cx("voucher-status", current.status)}
                  >
                    {current.status}
                  </Typography>
                  {current.datetime && (
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ marginLeft: "24px" }}
                    >
                      {format(new Date(current.datetime), "MMM dd, yyyy")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Fab aria-label="cancel" onClick={onClose}>
                <Clear />
              </Fab>
              {/* { allowEdit && <Button variant='button' color='primary' onClick={onEdit} >EDIT</Button> } */}
            </Grid>
            {current.status === "rejected" && !!current.note && (
              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <Typography variant="caption" color="error" component="div">
                  {current.note}
                </Typography>
              </Grid>
            )}
          </Grid>
          {userType === UserTypes.neweyes &&
            (current.status === "pending_payment" ||
              current.status === "approved" ||
              current.status === "redeemed" ||
              current.status === "paid") && (
              <>
                <FinancialSection
                  voucher={voucher}
                  allowChange={current.status !== "paid" && current.status !== "redeemed"}
                  reloadfn={(id) => onAction(id, null)}
                />
                <DispenserSection
                  voucher={voucher}
                  status={current.status}
                  redeemClicked={(note) =>
                    setVoucherStatus(voucher.id, {
                      status: "redeemed",
                      note: note,
                      sendEmail: false,
                    })
                  }
                  paidClicked={(note) =>
                    setVoucherStatus(voucher.id, {
                      status: "paid",
                      note: note,
                      sendEmail: false,
                    })
                  }
                />
              </>
            )}
          {/* Show payment page */}
          {userType !== UserTypes.neweyes && current.status === "pending_payment" && (
            <AgencyPaymentSection
              individual_application={
                voucher.voucher_detail.individual_application
              }
              require_vision_test={voucher.voucher_detail.require_vision_test}
              prescription_renewed={voucher.voucher_detail.prescription_renewed}
              order_uuid={voucher.voucher_financial.order_uuid}
            />
          )}
          {/* Show glass ordering page if the voucher is approved and online vision test is done (if required) */}
          {userType !== UserTypes.neweyes && current.status === "approved" && (
            <AgencyOrderSection
              individual_application={
                voucher.voucher_detail.individual_application
              }
              require_vision_test={voucher.voucher_detail.require_vision_test}
              prescription_renewed={voucher.voucher_detail.prescription_renewed}
              order_uuid={voucher.voucher_financial.order_uuid}
            />
          )}
          <VoucherDetailSection
            voucher={voucher}
            agencySummary={voucher.agencySummary}
            onClose={onClose}
          />
          {userType === UserTypes.neweyes && (
            <React.Fragment>
              {addNoteMode.showNote ? (
                <React.Fragment>
                  {addNoteMode.confirmNote && (
                    <Typography fullWidth variant="h5" color="primary">
                      {addNoteMode.confirmNote}
                    </Typography>
                  )}
                  {addNoteMode.noteLabel && (
                    <TextField
                      label={addNoteMode.noteLabel}
                      required={addNoteMode.required}
                      fullWidth
                      multiline
                      rows="4"
                      defaultValue={acionNote}
                      onChange={(e) => setActionNote(e.target.value)}
                      margin="dense"
                      variant="outlined"
                    />
                  )}
                  {addNoteMode.action === "reissue" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "reissue",
                          note: acionNote,
                          sendEmail: true,
                        })
                      }
                    >
                      Create a duplicate voucher
                    </Button>
                  )}
                  {addNoteMode.action === "reject" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "rejected",
                          note: acionNote,
                          sendEmail: true,
                        })
                      }
                    >
                      Reject
                    </Button>
                  )}
                  {addNoteMode.action === "approve" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "approved",
                          note: acionNote,
                          sendEmail: true,
                        })
                      }
                    >
                      Yes
                    </Button>
                  )}
                  {addNoteMode.action === "skipVisionTest" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "approved",
                          note: "SKIP VISION TEST: " + acionNote,
                          sendEmail: true,
                          statusAction: "skipVisionTest",
                        })
                      }
                    >
                      Skip Vision Test
                    </Button>
                  )}                  
                  {addNoteMode.action === "resend" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "approved",
                          note: acionNote,
                          sendEmail: true,
                        })
                      }
                    >
                      Send approval email
                    </Button>
                  )}
                  {addNoteMode.action === "cancel" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: "cancelled",
                          note: acionNote,
                          sendEmail: false,
                        })
                      }
                    >
                      Confirm cancel voucher
                    </Button>
                  )}
                  {addNoteMode.action === "addNoteOnly" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      disabled={addNoteMode.required && acionNote.length === 0}
                      onClick={(e) =>
                        setVoucherStatus(voucher.id, {
                          status: current.status,
                          note: acionNote,
                          sendEmail: false,
                        })
                      }
                    >
                      Add Note
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    style={{ marginTop: "24px" }}
                    onClick={(e) => setAddNoteMode({ showNote: false })}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {(current.status === "paid" || current.status === "cancelled") && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      onClick={(e) =>
                        setAddNoteMode({
                          showNote: true,
                          required: true,
                          noteLabel: "What is the reason to re-issue voucher?",
                          action: "reissue",
                        })
                      }
                    >
                      Re-issue Voucher
                    </Button>
                  )}
                  {current.status === "rejected" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      onClick={(e) =>
                        setAddNoteMode({
                          showNote: true,
                          required: true,
                          noteLabel: "Why change of mind?",
                          action: "approve",
                        })
                      }
                    >
                      Change to Approve
                    </Button>
                  )}
                  {current.status === "approved" && (
                      <>
                        {voucher.voucher_detail.require_vision_test && (
                          <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            style={{ marginTop: "24px" }}
                            onClick={(e) =>
                              setAddNoteMode({
                                showNote: true,
                                required: true,
                                noteLabel: "Why skip vision test?",
                                action: "skipVisionTest",
                              })
                            }
                          >
                            Skip Vision Test
                          </Button>
                        )}
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        style={{ marginTop: "24px" }}
                        onClick={(e) =>
                          setAddNoteMode({
                            showNote: true,
                            required: false,
                            noteLabel: "Add an optional note",
                            action: "resend",
                          })
                        }
                      >
                        Re-send approval Email
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        style={{ marginTop: "24px" }}
                        onClick={(e) =>
                          setAddNoteMode({
                            showNote: true,
                            required: true,
                            noteLabel: "Record a reason for the cancellation",
                            action: "cancel",
                          })
                        }
                      >
                        Cancel Voucher
                      </Button>
                    </>
                  )}
                    {current.status === "pending_approval" && (
                    <>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        style={{ marginTop: "24px" }}
                        onClick={(e) =>
                          setAddNoteMode({
                            showNote: true,
                            confirmNote:
                              "Are you sure you want to approve this voucher? You cannot undo this later.",
                            action: "approve",
                          })
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        style={{ marginTop: "24px" }}
                        onClick={(e) =>
                          setAddNoteMode({
                            showNote: true,
                            required: true,
                            noteLabel: "Reason for rejection",
                            action: "reject",
                          })
                        }
                      >
                        Reject
                      </Button>
                    </>
                  )}
                  {current.status !== "deleted" && current.status !== "cancelled" && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ marginTop: "24px" }}
                      onClick={(e) =>
                        setAddNoteMode({
                          showNote: true,
                          required: true,
                          noteLabel: "Type the note",
                          action: "addNoteOnly",
                        })
                      }
                    >
                      Add Note
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {userType === UserTypes.neweyes && voucher.actions.length > 0 && (
            <React.Fragment>
              <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
              <Typography variant="button" color="textSecondary">
                VOUCHER HISTORY
              </Typography>
              <VoucherActionList actions={voucher.actions} />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default VoucherPanel;
