// import { Box, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import { MainContainer, MainContent } from "../DataEntry/StyledFields";
import Requirement from "./Requirement";
import UserForm from "./UserForm";

const IndividualHome = ({ showRequirement = true }) => {
  const [userForm, setUserForm] = useState(false);

  useEffect(() => {
    setUserForm(!showRequirement);
  }, [showRequirement]);

  return (
    <MainContainer>
      <MainContent>
        <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
          <img src={logo} width="300px" alt="logo" />
        </Box>
        {showRequirement && !userForm && <Requirement onAccept={setUserForm} />}
        {userForm && <UserForm />}
      </MainContent>
    </MainContainer>
  );
};

export default IndividualHome;
