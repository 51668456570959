import React from 'react'
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography} from '@mui/material';

const HealthAndFinancialStatus = ({ applicant, voucher_detail = {}, onChange }) => {

    let {
        has_private_insurance = false,
        has_medicare = false,
        has_medicaid = false,
        has_other_assistance = false,
        is_homeless = false,
        is_student = false,
        studentReceivingMealDiscount = false
    } = voucher_detail

    return (
        <div>
            <Typography variant='h5' className='text-highlight more-vertical-padding'>Health &amp; Financial Information</Typography>

            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Select all that apply</FormLabel>
                        <FormGroup
                        >
                            <FormControlLabel label="Private Health" 
                                control={
                                    <Checkbox 
                                        color='primary'
                                        checked={has_private_insurance} 
                                        onChange={e => onChange({ has_private_insurance: e.target.checked })} 
                                    />
                                } 
                            />
                            <FormControlLabel label="Medicare" 
                                control={
                                    <Checkbox 
                                        color='primary'
                                        checked={has_medicare} 
                                        onChange={e => onChange({ has_medicare: e.target.checked })} 
                                    />
                                } 
                            />
                            <FormControlLabel label="Medicaid" 
                                control={
                                    <Checkbox 
                                        color='primary'
                                        checked={has_medicaid} 
                                        onChange={e => onChange({ has_medicaid: e.target.checked })} 
                                    />
                                } 
                            />
                            <FormControlLabel label="Other Public Assistance" 
                                control={
                                    <Checkbox 
                                        color='primary'
                                        checked={has_other_assistance} 
                                        onChange={e => onChange({ has_other_assistance: e.target.checked })} 
                                    />
                                } 
                            />                                                                                    
                        </FormGroup>
                    </FormControl>
                </Grid>
            
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Select all that apply</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        color='primary'
                                        checked={is_homeless} 
                                        onChange={e => onChange({ is_homeless: e.target.checked })} 
                                    />
                                }
                                label="Homeless"
                            />
                            { applicant.age <= 18 &&
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color='primary'
                                            checked={studentReceivingMealDiscount} 
                                            onChange={e => onChange({ is_student: e.target.checked, studentReceivingMealDiscount: e.target.checked })} 
                                        />
                                    }
                                    label="Student receives free or reduced fee meals"
                                />
                            }
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}

export default HealthAndFinancialStatus