import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { EntityTypes } from "../common/Constants";
// import axios from 'axios';
// import { Auth } from 'aws-amplify';

// https://c0mr5mqmee.execute-api.us-east-1.amazonaws.com/prod

const endpoints = {
  order: {
    load: (order_id) => `order/${order_id}`,
    new: () => "order/new",
    success: () => "order/success",
  },

  apply: {
    type: EntityTypes.applicant,
    new: () => "apply/new",
    load: (individual_id) => `apply/${individual_id}`,
    proof: (individual_id, type) => `apply/${individual_id}/proof/${type}`,
    match: (individual_id) => `apply/${individual_id}/match`,
    createOne: (individual_id) => `apply/${individual_id}/voucher`,
    visionTest: (voucher_id) => `apply/${voucher_id}/vision-test`,
    },
    
  fpl: {
      load: () => "api/fpl",
  },

  entity: {
    agency: {
      type: EntityTypes.agency,
      title: "Agency",
      load: () => "api/agency",
      loadOne: (id) => `api/agency/${id}`,
      loadSummary: (id) => `api/agency/${id}/summary`,
      updateOne: (id) => `api/agency/${id}`,
      createOne: () => "api/agency",
      join: (id) => `api/agency/${id}/join`,
      rejectUser: (id) => `api/agency/${id}/rejectuser`,
      approveUser: (agencyId) => `api/agency/${agencyId}/approveuser`,
      removeUser: (agencyId, userId) =>
        `api/agency/${agencyId}/removeuser/${userId}`,
      adminAccess: (agencyId, userId) =>
        `api/agency/${agencyId}/adminaccess/${userId}`,
      search: () => "api/agency/search",
      deleteOne: (id) => `api/agency/${id}`,
      migrateAgency: (agencyId, toAgencyId) =>
        `api/agency/${agencyId}/migrate/${toAgencyId}`,
      updateAgencyNote: (id) => `api/agency/${id}/updatenote`,
      updateInvoiceMode: (id) => `api/agency/${id}/updateinvoicemode`,
    },
     entityhistory :{
      update : (id) => `api/entityhistory/${id}/addnote`,
      get : (id) => `api/entityhistory/${id}`
     },
    applicant: {
      type: EntityTypes.applicant,
      title: "Applicant",
      load: () => "api/applicant",
      // loadOne: id => `api/applicant/${id}`,
      loadSummary: (id) => `api/applicant/${id}/summary`,
      updateOne: (id) => `api/applicant/${id}`,
      createOne: () => "api/applicant",
      search: (agencyId) =>
        agencyId ? `api/agency/${agencyId}/applicant` : "api/applicant/search",
      match: () => "api/applicant/match",
    },

    user: {
      type: EntityTypes.user,
      title: "User",
      load: () => "api/user",
      // loadOne: id => `api/user/${id}`,
      loadProfile: () => `api/user/profile`,
      loadPendingProfile: () => `api/user/pending`,
      updateOne: (id) => `api/user/${id}`,
      createOne: () => "api/user",
      withdraw: (id) => `api/user/${id}/withdraw`,
      approveAgency: () => `api/user/approveagency`,
      rejectAgency: () => `api/user/rejectagency`,
      deleteUser: (email) => `api/user/${email}`,
      search: (agencyId) =>
        agencyId ? `api/agency/${agencyId}/user` : "api/user/search",
      inbox: () => "api/user/inbox",
    },

    pendinguser: {
      type: EntityTypes.pendingUser,
      title: "Pending User",
      load: () => "api/user",
      // loadOne: id => `api/user/${id}`,
      updateOne: (id) => `api/user/${id}`,
      createOne: () => "api/user",
      search: (agencyId) => `api/agency/${agencyId}/pending`,
    },

    pendingagency: {
      type: EntityTypes.agency,
      title: "Pending Agency",
      load: () => "api/agency",
      // loadOne: id => `api/agency/${id}`,
      updateOne: (id) => `api/agency/${id}`,
      createOne: () => "api/agency",
      search: () => "api/agency/pending",
    },

    dispenser: {
      type: EntityTypes.dispenser,
      title: "Dispenser",
      load: () => "api/dispenser",
      // loadOne: id => `api/dispenser/${id}`,
      updateOne: (id) => `api/dispenser/${id}`,
      createOne: () => "api/dispenser",
      search: () => "api/dispenser/search",
      uploadRedeemFile: () => "api/dispenser/upload-redeem-file",
      redeemVouchers: () => "api/dispenser/redeem-matched-vouchers",
      payVouchers: () => "api/dispenser/pay-matched-vouchers",
    },

    visibly: {
      type: EntityTypes.visibly,
      preQual: () => "apply/pre-qual",
    },

    voucher: {
      type: EntityTypes.voucher,
      title: "Voucher",
      load: () => "api/voucher",
      loadOne: (id) => `api/voucher/${id}`,
      updateOne: (id) => `api/voucher/${id}`,
      updateFinancial: (id) => `api/voucher/${id}/financial`,
      updateApplicantFinancial: (id) => `api/voucher/${id}/applicantfinancial`,
      upgradeAmount: (id) => `api/voucher/${id}/upgradeAmount`,
      refund: (id) => `api/voucher/${id}/refund`,
      createOne: () => "api/voucher",
      pending_approval: () => "api/voucher/pending_approval",
      pending_fee: () => "api/voucher/pending_fee",
      redeemsummary: () => "api/voucher/redeemsummary",
      loadProof: (id, key) => `api/voucher/${id}/proof/${key}`,
      search: (agencyId) =>
        agencyId && agencyId > 0
          ? `api/agency/${agencyId}/voucher`
          : "api/voucher/search",
      },
    },
  



  agency: {
    entity_type: EntityTypes.agency,
    title: "Agency",
    get: () => "api/agency",
    user: {
      entity_type: EntityTypes.user,
      title: "Users",
      get: (agencyId) => `api/agency/${agencyId}/user`,
    },
    pendingUser: {
      entity_type: EntityTypes.user,
      title: "Pending Users",
      get: (agencyId) => `api/agency/${agencyId}/pending`,
    },
    voucher: {
      entity_type: EntityTypes.voucher,
      title: "Voucher",
      get: (agencyId) => `api/agency/${agencyId}/voucher`,
    },
    applicant: {
      entity_type: EntityTypes.applicant,
      title: "Applicant",
      get: (agencyId) => `api/agency/${agencyId}/applicant`,
    },
    search: {
      entity_type: EntityTypes.agency,
      title: "Agency",
      searchApi: () => "api/agency/search",
    },
    pendingAgency: {
      entity_type: EntityTypes.agency,
      title: "Pending Agencies",
      get: (agencyId) => `api/agency/pending`,
    },
  },

  search: {
    counties: () => "api/search/counties",
    post: () => "api/search",
    download: () => "api/search/download",
    downloadFull: () => "api/search/downloadFull",
    download2: () => "api/search/download2",
  },
};

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const useDebouncePost = (axiosConfig, searchOption, dataFn) => {
  // Handle the input text state
  const [{ ...rest }, postData] = useAxios(axiosConfig, { manual: true });
  const [returnAsync, setReturnAsync] = useState({
    loading: null,
    error: null,
    data: null,
  });

  const onChange = (value) => {
    // consoile.log("onchange called")
    // consoile.log(value)
    setReturnAsync({ data: [], loading: true, error: false });
    postData({ data: dataFn(value) })
      .then((response) => {
        // consoile.log("respoonse received")
        // consoile.log(response)
        setReturnAsync({ data: response.data, loading: false, error: false });
      })
      .catch((error) => {
        // consoile.log(error)
        setReturnAsync({ data: null, loading: false, error: error });
      });
  };

  const debounceFn = React.useCallback(debounce(onChange, 1500), []);

  useEffect(() => {
    // consoile.log(searchOption)
    debounceFn(searchOption);
  }, [debounceFn, searchOption]);

  // Return everything needed for the hook consumer
  return {
    returnAsync,
    postData,
  };
};

export { useAxios as useApi, useDebouncePost, endpoints };
