import React, {useEffect, useState} from 'react'
import {Button, Grid, Paper, TablePagination, Typography} from '@mui/material'
import * as Constants from '../../common/Constants'
import {endpoints} from '../../hooks/use-api'
import axios from 'axios';
import {useNotification} from '../../hooks/use-notification';
import MaterialTable from '@material-table/core';
import {format} from 'date-fns'
import {NumericFormat} from 'react-number-format'

const today = () => {
    return format(new Date(), "yyyy-MM-dd");
}

const ReportView = ({filter, dateRange, additionalButtons}) => {
    const { set: setNotification } = useNotification();

    const [loading, setLoading] = useState(false)
    const [downloadLabel, setDownloadLabel] = useState('Download Report');
    
    const postFilterData = () => {
        const {reportType, state, counties, cities, gender, fromAge, toAge, agency} = filter;

        return {
            fromDate: dateRange.from,
            toDate: dateRange.to,
            reportType: reportType.value, // ? reportType.value : null,
            states: (state ? [state.value] : []),
            counties: counties.map(cty => cty.value),
            cities: cities.map(city => city.value),
            gender: gender ? gender.value : "",
            fromAge: fromAge,
            toAge: toAge,
            agency: agency ? agency.id : 0
        };
    }

    // const [ countyPostData, setCountyPostData ] = useState()

    const [data, setData] = useState()
    const [summary, setSummary] = useState()

    useEffect(() => {
        console.log(filter)
    }, [filter])

    useEffect(() => {
        if (loading) {
            setDownloadLabel("Downloading...");
        } else {
            setDownloadLabel("Download Report");
        }
    }, [loading])

    const getReport = () => {
        console.log(filter)
        console.log(filter.state)

        // Make state optional if asking data going far back to 3 months

        // if (filter.state) {
            setLoading(true)
            axios.post(endpoints.search.post(), postFilterData())
                .then(function (response) {
                    setNotification({
                        message: `Displaying results`,
                        type: 'success',
                    });         
                    console.log(response.data);
                    setData(response.data.vouchers);
                    setSummary(response.data.summary);
                    setLoading(false)
                })
                .catch(function (error) {
                    setNotification({
                        message: `Error ${error.message}`,
                        type: 'error',
                    });
                    setLoading(false)
                });
            // } else {
            //     setNotification({
            //         message: 'Please select a state',
            //         type: 'error',
            //     })
            // }
    }

    const downloadExcel = () => {
        console.log(filter)
        console.log(filter.state)

        setLoading(true);
        const FileDownload = require("js-file-download");
        axios.post(endpoints.search.download(), postFilterData(), {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': Constants.ExcelMimeType
                }
            })
            .then(function (response) {
                setNotification({
                    message: `Downloading results`,
                    type: 'success',
                });         
                console.log(response.data.length)
                console.log(response.data)
                FileDownload(response.data, "Report.xlsx", Constants.ExcelMimeType);
                setLoading(false);
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
                setLoading(false);
            });     
    } 

    const showEmptyFn = postData => !postData || !postData.states || postData.states.length === 0;

    return (
      <Paper
        style={{
          maxWidth: "100%",
          padding: "16px",
          height: "90%",
          overflowX: "scroll",
        }}
      >
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={loading}
            onClick={downloadExcel}
          >
            {downloadLabel}
          </Button>
          {additionalButtons && (
            <>
              &nbsp;&nbsp;&nbsp;{" "}
              <Button
                variant="outlined"
                size="small"
                color="primary"
                disabled={loading}
                onClick={getReport}
              >
                Load Data
              </Button>
              {/* &nbsp;&nbsp;&nbsp;<Button variant='outlined' size="small" color='primary' onClick={downloadStatusExcel}>Export with Status</Button> */}
              &nbsp;&nbsp;&nbsp;{additionalButtons(data)}
            </>
          )}
        </Grid>

        {summary && (
          <>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="h5" color="textPrimary">
                  Total Voucher # {summary.voucherCount}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5" color="textPrimary">
                  Total ${" "}
                  <NumericFormat
                    value={summary.totalAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {data && (
          <MaterialTable
            components={{
              Pagination: (props) => {
                return (
                  <td>
                    <table
                      style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </td>
                );
              },
              // Toolbar: () =>
              //     <div style={{ padding: '16px' }}>
              //         <ColumnSelector
              //             options={columns}
              //             selected={selectedColumns}
              //             setSelected={setSelectedColumns}
              //         />
              //     </div>
            }}
            columns={[
              {
                title: "Date",
                render: (rowData) =>
                  format(new Date(rowData.datetime + ".000Z"), "MM/dd/yyyy"),
              },
              { title: "Voucher #", field: "id" },
              { title: "Applicant", field: "applicant_name" },
              { title: "Age", render: (rowData) => rowData.voucher_detail.age },
              {
                title: "County",
                render: (rowData) => rowData.voucher_detail.county,
              },
              {
                title: "City",
                render: (rowData) => rowData.voucher_detail.city,
              },
              {
                title: "State",
                render: (rowData) => rowData.voucher_detail.state,
              },
              { title: "Zip", render: (rowData) => rowData.voucher_detail.zip },
              { title: "Status", field: "status" },
              {
                title: "Amount",
                render: (rowData) =>
                  `$ ${rowData.voucher_financial.neweyes_amount}`,
              },
            ]}
            data={data}
            options={{
              exportButton: false,
              showTitle: false,
              search: false,
              // exportCsv: onExport,
              pageSize: 50,
              pageSizeOptions: [50, 100, 200, 300],
              paging: true,
            }}
            isLoading={loading}
          />
        )}
      </Paper>
    );

}

export default ReportView

