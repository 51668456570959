import {
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const AgencyOrderSection = ({
  individual_application,
  require_vision_test,
  prescription_renewed,
  order_uuid,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#82E0AA",
        textAlign: "center",
        verticalAlign: "middle",
      }}
    >
      <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
      <Typography variant="h6" color="textPrimary">
        Your voucher has been approved!
      </Typography>

      {require_vision_test && !prescription_renewed && (
        <>
          <Typography variant="subtitle2" color="textPrimary" mb="20px">
            The link to online vision test is emailed to you and the applicant. Please have the applicant take the online vision
            test. Once they receive the new prescription. Please order your glasses by clicking the following link:
          </Typography>
          <Button variant="outlined" color="primary" href="http://www.neweyesglasses.org/">Order here</Button>
        </>
      )}
      {(!require_vision_test || prescription_renewed) && (
        <>
          <Typography variant="subtitle2" color="textPrimary" mb="20px">
            Please order your glasses by clicking the following link:
          </Typography>
          <Button variant="outlined" color="primary" href="http://www.neweyesglasses.org/">Order here</Button>
        </>
      )}
      <br />
      <br />
    </div>
  );
};

export default AgencyOrderSection;
