import React, {useState} from 'react'
import {Drawer} from '@mui/material';
import {useApi} from '../../hooks/use-api';
import CardGrid from '../Cards'
import EntityPanel from './EntityPanel'
// import { getApiFor } from '../../hooks/useApi';

const EntityHome = ({ title, entity_type, itemfn = data => data, showUpdate=true,
                        saveUri, entityAsync, extendPanel, reload, overridePanel,
                        onSelect }) => {
    
    // Auto close works only for the first view when the grid is clicked. If user is walking
    // through some screens inside then we do not want auto close
    const [autoClose, setAutoClose] = useState(true)

    const [openDrawer, setOpenDrawer] = useState(false)
    const [currentItem, setCurrentItem] = useState()

    const onSelectFn = item => {
        if (onSelect) {
            onSelect(item)
        } else {
            setCurrentItem(item)
            setOpenDrawer(true)
        }
    }

    return (
        <div>
            <CardGrid itemsAsync={entityAsync}
                itemTypeFn = {() => entity_type}
                itemfn = {itemfn}
                onSelect={onSelectFn}
            />               
            <Drawer anchor='right' open={openDrawer} onClose={() => {if(autoClose) setOpenDrawer(false)}} classes={{ paper: 'wide-drawer' }}>
            { overridePanel &&
                <React.Fragment>
                    {overridePanel({item: currentItem, onClose: () => setOpenDrawer(false), reload: reload})}
                </React.Fragment>
            }
            {!overridePanel &&
                <EntityPanel 
                    title = {title}
                    item={currentItem}  
                    onClose={() => setOpenDrawer(false)}
                    showUpdate={showUpdate}
                    onAutoClose={setAutoClose}
                    onChange={items => setCurrentItem({...currentItem, ...items})}
                    saveUri={saveUri}
                    reload={reload}
                    onAction={() => {
                        // saveAgency(currentAgency)
                        setOpenDrawer(false)
                    }}
                    extendPanel={extendPanel}
                />
            }
            </Drawer>
        </div>
    )

}

const EntityHomeApi= ({ api, id, itemfn = data => data, extendPanel }) => {
    const [ entityAsync, reload ] = useApi(api.get(id));
    return <EntityHome title={api.title} itemfn={itemfn} entity_type={api.entity_type} entityAsync={entityAsync} reload={reload} extendPanel={extendPanel} />
}

export { EntityHomeApi, EntityHome };

