const routes = {
    inbox: { name: "Inbox", route: "/inbox" },
    vouchers: { name: "Vouchers", route: "/vouchers" },
    agencies: { name: "Agencies", route: "/agencies" },
    dispensers: { name: "Dispensers", route: "/dispensers" },
    applicants: { name: "Applicants", route: "/applicants" },
    users: { name: "Users", route: "/users" },
    pendingvouchers: { name: "Pending Vouchers", route: "/pendingvouchers" },
    pendingusers: { name: "Pending Users", route: "/pendingusers" },
    pendingagencies: { name: "Pending Agencies", route: "/pendingagencies" },
    reports: { name: "Reports", route: "/reports" },
    logout: { name: "Sign Off", route: "/logout" }
}

export default routes
