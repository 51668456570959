import React, {useState} from 'react'
import {Drawer} from '@mui/material';
import {useDebouncePost} from '../../hooks/use-api';
import CardGrid from '../Cards'
import EntityPanel from './EntityPanel'

// NOTE: itemfn extract the entity for this common component to operate on for viewing, editing etc.
// To all outside callback the actual item object is send, for example to the extendPanel component
const EntitySearchCore = ({ entityApi, agencyId,
        showUpdate = false, itemfn = data => data,
        extendPanel, searchOption, setSearchOption }) => {

    // Auto close works only for the first view when the grid is clicked. If user is walking
    // through some screens inside then we do not want auto close
    const [autoClose, setAutoClose] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [currentItem, setCurrentItem] = useState()

    const {returnAsync: entityAsync }
           = useDebouncePost(
                {
                  url: entityApi.search(agencyId),
                  method: 'post'
                },
                searchOption,
                searchOption => searchOption
              )

    // Force reload the grid after any update
    const reload = () => setSearchOption({...searchOption})

    return (
        <div>
            <CardGrid itemsAsync={entityAsync}
                itemTypeFn = {() => entityApi.type}
                itemfn = {itemfn}
                onSelect={item => {
                    setCurrentItem(item)
                    setOpenDrawer(true)
                }}
            />               
            <Drawer anchor='right' open={openDrawer} onClose={() => {if(autoClose) setOpenDrawer(false)}} classes={{ paper: 'wide-drawer' }}>
                <EntityPanel 
                    item={currentItem}
                    entityApi={entityApi}
                    itemfn={itemfn}
                    showUpdate={showUpdate}
                    onClose={() => setOpenDrawer(false)}
                    onAutoClose={setAutoClose}
                    reload={reload}
                    onAction={() => {
                        // saveAgency(currentAgency)
                        setOpenDrawer(false)
                    }}
                    extendPanel={extendPanel}
                />
            </Drawer>            
        </div>
    )
}

export default EntitySearchCore;
