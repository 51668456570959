import { Typography } from "@mui/material";

const ButtonOption = ({ title, backColor }) => (
  <Typography
    variant="button"
    component="div"
    style={{
      marginRight: "2px",
      padding: "1px 6px",
      border: "1px solid lightgray",
      borderRadius: "4px",
      textTransform: "uppercase",
      backgroundColor: backColor ? "#dde8ed" : backColor,
    }}
  >
    {title}
  </Typography>
);

export default ButtonOption;
