import { Clear } from "@mui/icons-material";
import { Button, Drawer, Fab, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { EntityTypes } from "../../common/Constants";
import TextSearch from "../../common/TextSearch";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import { EntityHome } from "../Entities";
import { CreateOrUpdateEntity } from "../Entities/EditEntity";

const AddressCard = ({
  id,
  name,
  first_name,
  last_name,
  address1,
  city,
  state,
  zip,
  phone,
  phone_extension,
}) => {
  name = first_name ? `${first_name} ${last_name}` : name;
  return (
    <Grid container className="address">
      <Grid item xs={12}>
        <Typography variant="subtitle2">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {address1}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {city}, {state} {zip}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {phone}
          {phone_extension ? " Ext. " + phone_extension : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

const AgencySelectPanel = ({ agency, onClick, onClose }) => {
  const [showYesNo, setShowYesNo] = useState(true);

  return (
    <div className="confirmation-dialog">
      {showYesNo && (
        <Typography variant="h4" color="textSecondary" component="div">
          Is this your agency?
        </Typography>
      )}
      {!showYesNo && (
        <Typography variant="h4" color="textSecondary" component="div">
          Registration
        </Typography>
      )}
      <AddressCard {...agency} />
      {showYesNo && (
        <React.Fragment>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setShowYesNo(false)}
          >
            Yes
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" size="small" onClick={onClose}>
            No
          </Button>
        </React.Fragment>
      )}
      {!showYesNo && (
        <React.Fragment>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onClick}
          >
            Click here
          </Button>
          &nbsp;
          <Typography variant="h6" color="textSecondary" component="span">
            to join New Eyes registered user list.
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle2" color="textSecondary" component="div">
            Your supervisor will recieve an email asking to confirm your
            registration in the New Eyes program.
          </Typography>
          <br />
          <Button variant="contained" size="small" onClick={onClose}>
            Cancel
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

const SelectAgency = ({ user, onSelect }) => {
  // const { returnAsync: agencyAsync, postData } = useApi({
  //     url: endpoints.entity.agency.search(),
  //     method: 'post'
  // }, { manual: true})
  const [agencyAsync, setAgencyAsync] = useState({
    loading: null,
    error: null,
    data: null,
  });

  const { set: setNotification } = useNotification();

  const [showNewAgencyButton, setShowNewAgencyButton] = useState(false);

  const [newAgency, setNewAgency] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setNewAgency({
      type: EntityTypes.agency,
      email: user.email,
      phone: user.phone,
    });
  }, [user]);

  // const saveAgency = () => {
  //     axios.post(endpoints.saveNewAgency(), agency)
  //         .then(function (response) {
  //             setNotification({
  //                 message: `Agency request sent`,
  //                 type: 'success',
  //             })
  //             setOpenDrawer(false)
  //             onSelect()
  //         })
  //         .catch(function (error) {
  //             setNotification({
  //                 message: `Error ${error.message}`,
  //                 type: 'error',
  //             });
  //         });
  //     }

  const sendRequest = (agency, onClose) => {
    axios
      .post(endpoints.entity.agency.join(agency.id), agency)
      .then(function (response) {
        setNotification({
          message: `Request to join agency sent`,
          type: "success",
        });
        onSelect();
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
    //?? onClose
  };

  return (
    <React.Fragment>
      <div style={{ padding: "20px 40px" }}>
        <Typography variant="h3" style={{ color: "#bbb" }}>
          Type in your agency to see if it is already in our system/or already
          registered with New Eyes
        </Typography>
      </div>
      <Fab
        variant="extended"
        onClick={(e) => setOpenDrawer(true)}
        size="small"
        color="secondary"
        style={{
          position: "fixed",
          top: 80,
          right: 48,
          zIndex: 1150,
          visibility: showNewAgencyButton ? "visible" : "hidden",
        }}
      >
        Create New Agency
      </Fab>
      <div style={{ padding: "20px 40px" }}>
        <TextSearch
          placeholder="Agency name"
          label="Search agency by name, city, zip, street..."
          apiFn={endpoints.entity.agency.search}
          dataFn={(text) => {
            return { searchText: text };
          }}
          resultsFn={(itemsAsync) => setAgencyAsync(itemsAsync)}
          searchTextFn={() => {
            if (!showNewAgencyButton) setShowNewAgencyButton(true);
          }}
        />
      </div>
      <EntityHome
        title="Select Agency"
        entity_type={EntityTypes.agency}
        entityAsync={agencyAsync}
        overridePanel={({ item, onClose }) => (
          <AgencySelectPanel
            agency={item}
            onClick={() => sendRequest(item, onClose)}
            onClose={onClose}
          />
        )}
      />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        classes={{ paper: "wide-drawer" }}
      >
        {
          <Paper style={{ padding: "24px" }}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  component="div"
                  style={{ marginBottom: "24px", textTransform: "uppercase" }}
                >
                  New Agency
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Fab aria-label="cancel" onClick={(e) => setOpenDrawer(false)}>
                  <Clear />
                </Fab>
              </Grid>
            </Grid>
            <CreateOrUpdateEntity
              entity={newAgency}
              entityApi={endpoints.entity.agency}
              onAction={(saved, updatedAgency) => {
                setOpenDrawer(false);
                //onSelect()
                // Reload the page
                window.location.reload();
              }}
            />
          </Paper>
        }
      </Drawer>
    </React.Fragment>
  );
};

export default SelectAgency;
