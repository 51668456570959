import { makeStyles } from "@mui/styles";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes as Routes1, useNavigate } from "react-router-dom";
import Routes from "./common/Routes";
import AgencyHome from "./components/Agencies";
import PendingAgencies from "./components/Agencies/PendingAgenies";
import PendingUsers from "./components/Agencies/PendingUsers";
import ApplicantHome from "./components/Applicants";
import DispenserHome from "./components/Dispensers";
import Header from "./components/Header";
import InboxPage from "./components/Inbox";
import IndividualHome from "./components/Individual";
import IndividualApplication from "./components/Individual/Application";
import ThankYouForApplying from "./components/Individual/ThankYou";
import VisionTest from "./components/Individual/VisionTest";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Registration_Verification from "./components/Registration/Verification";
import Reports from "./components/Reports";
import ResetPassword from "./components/ResetPassword";
import ResetPassword_Verification from "./components/ResetPassword/Verification";
import Users from "./components/Users";
import PendingUserHome from "./components/Users/PendingUserHome";
import { SearchVoucher } from "./components/Vouchers/SearchVoucher";
import { useAuth } from "./hooks/use-auth";
import OrderHome from "./components/Order";
import OrderHomeOld from "./components/Order/OrderHomeOld";
import OrderSuccessHome from "./components/Order/Success";

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  logout().then((_) => {
    navigate("/login");
  });

  return null;
};

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();

  return user && user.agency.id === 0
    ? <><Header user={user} />
      <div className="page-container">
        <PendingUserHome />
      </div></>
    : (user && user.agency.id !== 0 ?
      <><Header user={user} />
        <div className="page-container">{children}</div></>
      : <Navigate to="/login" />);
}

// const PrivateRoutes = ({ children }) => {
//   const { isLoading, user } = useAuth();

//   // Assume there is a valid session going on before showing login page
//   const [sessionValid, setSessionValid] = useState(true);

//   useEffect(() => {
//     Auth.currentSession()
//       .then((session) => {
//         setSessionValid(true);
//         console.log(session);
//       })
//       .catch((e) => {
//         setSessionValid(false);
//         console.log(e);
//       });
//   }, [isLoading, user]);

//   if (isLoading) {
//     return null;
//   }

//   return (
//     <>
//       {!user && !sessionValid && <Navigate to="/login" />}
//       {user && user.agency.id === 0 && (
//         <React.Fragment>
//           <Header user={user} />
//           <div className="page-container">
//             <PendingUserHome />
//           </div>
//         </React.Fragment>
//       )}
//       {user && user.agency.id !== 0 && (
//         <React.Fragment>
//           <Header user={user} />
//           <div className="page-container">{children}</div>
//         </React.Fragment>
//       )}
//     </>
//   );
// };

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.red,
  },
}));

const Router = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <Routes1>
          <Route exact path="/order/:order_id" element={<OrderHome />} />
          <Route exact path="/order" element={<OrderHomeOld />} />
          <Route exact path="/order/success" element={<OrderSuccessHome />} />
          <Route exact path="/order/cancel" element={<OrderHome />} />

          <Route exact path="/apply" element={<IndividualHome />} />
          <Route exact path="/apply/continue" element={
            <IndividualHome showRequirement={false} />
          }>
          </Route>

          <Route
            exact
            path="/apply/thank-you"
            element={<ThankYouForApplying />}
          />

          <Route
            exact
            path="/apply/:individual_id"
            element={<IndividualApplication />}
          />

          <Route exact path="/apply/:identifier/vision-test" element={<VisionTest />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/reset-password/verify"
            element={<ResetPassword_Verification />}
          />
          <Route exact path="/registration" element={<Registration />} />
          <Route
            exact
            path="/registration/verify"
            element={<Registration_Verification />}
          />

          {/* Temporary ?? */}
          <Route exact path="/logout" element={<Logout />} />

          {/* Private routes ?? */}

          {/* <PrivateRoutes> */}
          <Route exact path="/" element={<Navigate to={Routes.inbox.route} />} />
          <Route exact path={Routes.inbox.route} element={<PrivateRoute><InboxPage /></PrivateRoute>} />
          <Route
            exact
            path={Routes.vouchers.route}
            element={<PrivateRoute><SearchVoucher /></PrivateRoute>}
          />
          <Route exact path={Routes.agencies.route} element={<PrivateRoute><AgencyHome /></PrivateRoute>} />
          <Route path={Routes.dispensers.route} element={<PrivateRoute><DispenserHome /></PrivateRoute>} />
          <Route path={Routes.applicants.route} element={<PrivateRoute><ApplicantHome /></PrivateRoute>} />
          <Route path={Routes.users.route} element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route
            path={Routes.pendingvouchers.route}
            element={<PrivateRoute><SearchVoucher /></PrivateRoute>}
          />
          <Route path={Routes.pendingusers.route} element={<PrivateRoute><PendingUsers /></PrivateRoute>} />
          <Route
            path={Routes.pendingagencies.route}
            element={<PrivateRoute><PendingAgencies /></PrivateRoute>}
          />
          <Route path={Routes.reports.route} element={<PrivateRoute><Reports /></PrivateRoute>} />
          <Route exact path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />
          {/* </PrivateRoutes> */}
        </Routes1>
      </BrowserRouter>
    </div>
  );
};

export default Router;
