import React, { useEffect } from "react";
import {
  MoveNext,
  useMultiStepForm,
} from "../../../DataEntry/MultiFormContext";
// import Preview from './Preview';
import { VoucherValidator } from "../Model";
import EditFinancialInfo from "./EditFinancialInfo";
import HealthAndFinancialStatus from "./HealthAndFinancialStatus";
import UploadDocument from "./UploadDocument";

const ApplicantFinancial = ({ page, formProps }) => {
  const {
    values: data,
    setFieldValue,
    isSubmitting,
    isValid,
    submitForm,
  } = formProps;
  const { setEnableNext } = useMultiStepForm();

  // const [ready, setReady] = useState(false);

  useEffect(() => {
    console.log(data);
    if (isSubmitting) {
      if (page === 0) {
        if (VoucherValidator.isFinancialInfoRequied(data)) {
          setEnableNext(MoveNext.Page);
        } else if (data.individualApplication) {
          setEnableNext(MoveNext.TwoPage);
        } else {
          setEnableNext(MoveNext.Step);
        }
      } else if (page === 1) {
        //??  && isValid
        if (data.individualApplication) {
          setEnableNext(MoveNext.Page);
        } else {
          setEnableNext(MoveNext.Step);
        }
      } else if (page === 2) {
        if (VoucherValidator.isDocUploaded(data)) {
          setEnableNext(MoveNext.Step);
        } else {
          setEnableNext(MoveNext.ValidationRequired);
        }        
      }
    }
  }, [isSubmitting]); //?? , isValid

  switch (page) {
    case 0:
      return (
        <HealthAndFinancialStatus
          applicant={data.applicant}
          voucher_detail={data.voucher_detail}
          onChange={(updatedItem) =>
            setFieldValue("voucher_detail", {
              ...data.voucher_detail,
              ...updatedItem,
            })
          }
        />
      );
    case 1:
      return (
        <EditFinancialInfo
          isChild={VoucherValidator.isChild(data)}
          voucher_detail={data.voucher_detail}
          onChange={(updatedItem) =>
            setFieldValue("voucher_detail", {
              ...data.voucher_detail,
              ...updatedItem,
            })
          }
        />
      );
    case 2:
      return (
        <UploadDocument
          data={data}
          formProps={formProps}
          onChange={(updatedItem) =>
            setFieldValue("voucher_detail", {
              ...data.voucher_detail,
              ...updatedItem,
            })
          }
        />
      );
    default:
      return <React.Fragment />;
  }
};

export default ApplicantFinancial;
