import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const NoteConfirmation = ({
  showNote = true,
  noteRequired = false,
  label,
  buttonLabel,
  confirmButtonLabel,
  onClick,
  onCancel,
  onConfirm,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [note, setNote] = useState("");

  confirmButtonLabel = confirmButtonLabel || buttonLabel;
  if (!label) label = noteRequired ? "Add a note" : "Optional note";

  return (
    <>
      {confirm ? (
        <>
          {showNote ? (
            <TextField
              label={label}
              required={noteRequired}
              fullWidth
              multiline
              rows="4"
              defaultValue={note}
              onChange={(e) => setNote(e.target.value)}
              margin="dense"
              variant="outlined"
            />
          ) : (
            <Typography fullWidth variant="h6">
              {label}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "24px" }}
            disabled={noteRequired && note.length === 0}
            onClick={(_e) => {
              onConfirm(note);
              setConfirm(false);
            }}
          >
            {confirmButtonLabel}
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "24px" }}
            onClick={(_e) => {
              if (onCancel) onCancel();
              setConfirm(false);
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          style={{ marginTop: "24px" }}
            onClick={(_e) => {
              if (onClick) onClick();
              setConfirm(true);
            }}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );
};

export default NoteConfirmation;
