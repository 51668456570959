import React from 'react'
import {NumericFormat} from 'react-number-format';

export const TaxIdFormattedInput = (props) => {

  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format='##-#######'
      mask='#'
    />
  );
}

export default TaxIdFormattedInput