import { Grid, NativeSelect, TextField } from "@mui/material";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import React, { useMemo } from "react";

const today = () => {
  return format(new Date(), "yyyy-MM-dd");
};

const dateRangeFunctions = {
  today: () => ({ from: today(), to: today() }),
  currentWeek: () => ({
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: today(),
  }),
  lastWeek: () => ({
    from: format(startOfWeek(addWeeks(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfWeek(addWeeks(new Date(), -1)), "yyyy-MM-dd"),
  }),
  currentMonth: () => ({
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: today(),
  }),
  lastMonth: () => ({
    from: format(startOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
  }),
  last90days: () => ({
    from: format(addDays(new Date(), -90), "yyyy-MM-dd"),
    to: today(),
  }),
  currentQuarter: () => ({
    from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
    to: today(),
  }),
  lastQuarter: () => ({
    from: format(startOfQuarter(addQuarters(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfQuarter(addQuarters(new Date(), -1)), "yyyy-MM-dd"),
  }),
  currentYear: () => ({
    from: format(startOfYear(new Date()), "yyyy-MM-dd"),
    to: today(),
  }),
  lastYear: () => ({
    from: format(startOfYear(addYears(new Date(), -1)), "yyyy-MM-dd"),
    to: format(endOfYear(addYears(new Date(), -1)), "yyyy-MM-dd"),
  }),
  currentFiscalYear: () => ["start date", "end date"],
  lastFiscalYear: () => ["start date", "end date"],
};

const rangeItems = [
  {
    label: "Custom",
    ariaLabel: "Fiscal year selection",
    choices: [],
  },
  {
    label: "Daily",
    ariaLabel: "Daily reports",
    choices: [
      {
        label: "Today",
        value: "today",
      },
    ],
  },
  {
    label: "Week",
    ariaLabel: "Weekly reports",
    choices: [
      {
        label: "Current Week",
        value: "currentWeek",
      },
      {
        label: "Last week",
        value: "lastWeek",
      },
    ],
  },
  {
    label: "Month",
    ariaLabel: "Monthly reports",
    choices: [
      {
        label: "Current month",
        value: "currentMonth",
      },
      {
        label: "Last month",
        value: "lastMonth",
      },
      {
        label: "Last 90 days",
        value: "last90days",
      },
    ],
  },
  {
    label: "Quarter",
    ariaLabel: "Quarterly reports",
    choices: [
      {
        label: "Current Quarter",
        value: "currentQuarter",
      },
      {
        label: "Last Quarter",
        value: "lastQuarter",
      },
    ],
  },
  {
    label: "Year",
    ariaLabel: "Yearly reports",
    choices: [
      {
        label: "Current Year",
        value: "currentYear",
      },
      {
        label: "Last Year",
        value: "lastYear",
      },
    ],
  },
  {
    label: "Fiscal Year",
    ariaLabel: "Fiscal year selection",
    choices: [
      {
        label: "Current Fiscal Year",
        value: "currentFiscalYear",
      },
      {
        label: "Last Fiscal Year",
        value: "lastFiscalYear",
      },
    ],
  },
];

// dateRange: { from: , to: }
const ReportDateRangeSelect = ({ dateRange, onChange }) => {
  const today = useMemo(() => new Date().toJSON().slice(0, 10), []);

  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={4}>
        <NativeSelect
          onChange={(e) => onChange(dateRangeFunctions[e.target.value]())}
        >
          {rangeItems &&
            rangeItems.map((item, _index) => (
              <optgroup label={item.label}>
                {item.choices &&
                  item.choices.map((choice, _jindex) => (
                    <option value={choice.value}>{choice.label}</option>
                  ))}
              </optgroup>
            ))}
        </NativeSelect>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextField
          id="startdate"
          label="From Date"
          type="date"
          defaultValue={today}
          value={dateRange.from}
          onChange={(e) => onChange({ from: e.target.value, to: dateRange.to })}
          // value={birth_date ? birth_date.substring(0, 10) : birth_date}
          // onChange={(e) => setValidDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextField
          id="enddate"
          label="End Date"
          type="date"
          defaultValue={today}
          value={dateRange.to}
          onChange={(e) =>
            onChange({ from: dateRange.from, to: e.target.value })
          }
          // value={dateRange.to}
          // value={birth_date ? birth_date.substring(0, 10) : birth_date}
          // onChange={(e) => setValidDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </Grid>
    </Grid>
  );
};

export default ReportDateRangeSelect;
