import { differenceInYears, isPast, isValid } from "date-fns";
// import {
//   useNotification,
//   NotificationType,
// } from "../../../../hooks/use-notification";
import { isEqual } from "lodash";
import { EntityTypes } from "../../../common/Constants";
import { endpoints } from "../../../hooks/use-api";

export const getEmptyVoucher = (individualApplication, indUser) => ({
  ...VoucherInitialValues,
  ...{
    individualApplication: individualApplication,
    individualId: indUser.identifier,
    applicant: {
      ...VoucherInitialValues.applicant,
      ...{
        first_name: indUser.firstName,
        last_name: indUser.lastName,
        email: indUser.email,
      },
    },
    matchApi: individualApplication
      ? endpoints.apply.match(indUser.identifier)
      : endpoints.entity.applicant.match(),
    createApi: individualApplication
    ? endpoints.apply.createOne(indUser.identifier)
    : endpoints.entity.voucher.createOne(),
  },
});

export const VoucherInitialValues = {
  id: 0,
  individualApplication: false,
  individualId: 0,
  hasRecentPrescription: "",
  eye_exam_date: "",
  prescriptionHasPdMeasurement: false,
  canTakeVisionTest: false,
  visionQualResults: {
    isVisionTestQualifies: false,
    payload: null,
  },

  birth_date: "",
  state: { name: "", value: "" },

  sph_left: "",
  cyl_left: "",
  axis_left: "",
  add_left: "",
  pd_left: "",
  sph_right: "",
  cyl_right: "",
  axis_right: "",
  add_right: "",
  pd_right: "",

  applicant: {
    id: -1,
    type: EntityTypes.applicant,
    first_name: "",
    last_name: "",
    sex: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    county: "",
    country: "US",
    zip: "",
    email: "",
    phone: "",
    phone_extension: "",
    birth_date: "",
    tax_id: "",
//    age: 0,
  },

  voucher_detail: {
    has_private_insurance: false,
    has_medicare: false,
    has_medicaid: false,
    has_other_assistance: false,
    is_homeless: false,
    studentReceivingMealDiscount: false,
    household_adults: 0,
    household_children: 0,
    total_income: 0.0,
    total_expense: 0.0,
    income_note: "",
    document_later: false,
    docs: [
      { type: "snap", key: null, file: null },
      { type: "unemployment", key: null, file: null },
      { type: "taxreturn", key: null, file: null },
      { type: "other", key: null, file: null },
    ],
    snap: "",
    unemployment: "",
    taxreturn: "",
    other: "",
    additional_notes: "",
  },

  cert1: false,
  cert2: false,
  cert3: false,
  cert4: false,
  
};

export const VoucherValidator = {
  hasRecentPrescription: (data) => data["hasRecentPrescription"] === "true",
  hasValidEyeExamDate: (data) => {
    // E.g. 2009-02-02T05:00:00.000Z
    let dt = new Date(data["eye_exam_date"]); // parse(data["eye_exam_date"], "yyyy-MM-dd", new Date());
    return isValid(dt) && isPast(dt);
  },
  hasEyeExamWithin2years: (data) => {
    var years = differenceInYears(new Date(), new Date(data["eye_exam_date"]));
    return years >= 0 && years < 2;
  },
  hasEyeExamOver2years: (data) => {
    var years = differenceInYears(new Date(), new Date(data["eye_exam_date"]));
    return years >= 2;
  },
  hasPdMeasurement: (data) => data["prescriptionHasPdMeasurement"] === true,
  canTakeVisionTest: (data) => data["canTakeVisionTest"] === true,

  hasValidBirthDate: (data) => {
    // E.g. 2009-02-02T05:00:00.000Z
    let dt = new Date(data["birth_date"]); // parse(data["eye_exam_date"], "yyyy-MM-dd", new Date());
    return isValid(dt) && isPast(dt);
  },

  hasValidState: (data) => data["state"].value !== "",
  hasSph: (data) => data["sph_left"] !== "" && data["sph_right"] !== "",

  isChild: (data) => {
    var years = differenceInYears(new Date(), new Date(data["birth_date"]));
    return years <= 18;
  },

  getVisiblyQualPayload: (data) => ({
    user: {
      birthdate: data.birth_date.substring(0, 10),
      region: data.state.value,
    },
    // prescriptions: [
    //   {
    //     type: "glasses",
    //     verification: "unverified",
    //     right_eye: {
    //       sphere: data.sph_right,
    //       cylinder: data.cyl_right,
    //       axis: data.axis_right,
    //       add: data.add_right,
    //       pupillary_distance: data.pd_right,
    //     },
    //     left_eye: {
    //       sphere: data.sph_left,
    //       cylinder: data.cyl_left,
    //       axis: data.axis_left,
    //       add: data.add_left,
    //       pupillary_distance: data.pd_left,
    //     },
    //   },
    // ],
  }),

  isVisionQualAlreadyRan: (data) => {
    // Check if vision test qualified. If any of the fields have changed then reset to false
    if (
      // data.visionQualResults.isVisionTestQualifies &&
      data.visionQualResults.payload !== null
    ) {
      var payload = VoucherValidator.getVisiblyQualPayload(data);
      if (isEqual(payload, data.visionQualResults.payload)) {
        return true;
      }
    }

    return false;
  },

  isApplicantDataComplete: (data) =>
    data.applicant &&
    data.applicant["zip"] &&
    data.applicant["zip"].length >= 5,

  isFinancialInfoRequied: (data) =>
    data.voucher_detail &&
    !data.voucher_detail["is_homeless"] &&
    (!data.voucher_detail["studentReceivingMealDiscount"] || data.voucher_detail["studentReceivingMealDiscount"] == "false"),

  isDocUploaded: (data) => !data.individualApplication
    || data.voucher_detail.docs[0].file != null
    || data.voucher_detail.docs[1].file != null
    || data.voucher_detail.docs[2].file != null
    || data.voucher_detail.docs[3].file != null,

  isReadyForSubmission: (data) =>
    (!data.individualApplication && data.cert1 && data.cert2) ||
    (data.individualApplication &&
      data.cert1 && data.cert2 && data.cert3 && data.cert4),
    

  getVoucher: (data) => ({
    visibly_qual: data.visionQualResults.payload,
    applicant: data.applicant,
    voucher_detail: {
      ...data.voucher_detail,
      ...{
        eye_exam_date: data.eye_exam_date,
        individual_application: data.individualApplication,
        require_vision_test: data.canTakeVisionTest,
      },
    },
  }),
};
