import { Clear } from "@mui/icons-material";
import { Fab, Grid, Paper, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AddressView1 } from "../../common/AddressView";
import * as Constants from "../../common/Constants";
import { EntityTypes } from "../../common/Constants";
import ReportDateRangeSelect from "../../common/ReportDateRangeSelect";
import TextSearch from "../../common/TextSearch";
import { TextSelect2, TextSelectPostApi } from "../../common/TextSelect";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import { EntityHome } from "../Entities";
import ReportView from "./ReportView";

const today = () => {
  return format(new Date(), "yyyy-MM-dd");
};

const ReportHome = () => {
  const { set: setNotification } = useNotification();

  const [dateRange, setDateRange] = useState({ from: today(), to: today() });
  const [loading, setLoading] = useState(false);
  const [agencyAsync, setAgencyAsync] = useState({
    loading: null,
    error: null,
    data: null,
  });

  const [filter, setFilter] = useState({
    // dateRange: [],
    reportType: Constants.ReportOptions[1],
    state: null,
    counties: [],
    cities: [],
    gender: null,
    fromAge: 0,
    toAge: 0,
    agency: null,
  });

  const [showDataFilter, setShowDataFilter] = useState(true);

  // const postFilterData = () => {
  //     const {reportType, state, counties, cities, gender, fromAge, toAge} = filter;

  //     return {
  //         fromDate: dateRange.from,
  //         toDate: dateRange.to,
  //         reportType: reportType.value, // ? reportType.value : null,
  //         states: (state ? [state.value] : []),
  //         counties: counties.map(cty => cty.value),
  //         cities: cities.map(city => city.value),
  //         gender: gender ? gender.value : "",
  //         fromAge: fromAge,
  //         toAge: toAge
  //     };
  // }

  const [countyPostData, setCountyPostData] = useState();

  const reportTypeChange = (item) => {
    setFilter({ ...filter, reportType: item });
    setShowDataFilter(
      item.value !== "liabilityReport" &&
        item.value !== "user" &&
        item.value !== "agency" &&
        item.value !== "individualapplicantsource"
    );
  };

  useEffect(() => {
    setFilter({ ...filter, counties: [] });
    if (filter.state) {
      // setCounties([])
      setCountyPostData({
        reportType: "voucherIssued",
        states: [filter.state.value],
        counties: [],
        cities: [],
        gender: "",
      });
    }
  }, [filter.state]);

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);

  const showEmptyFn = (postData) =>
    !postData || !postData.states || postData.states.length === 0;

  return (
    <Paper
      style={{
        maxWidth: "100%",
        padding: "16px",
        height: "90%",
        overflowX: "scroll",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textPrimary">
            Select the type of report:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextSelect2
            label="Reports"
            value={filter.reportType}
            options={Constants.ReportOptions}
            onChange={reportTypeChange}
            multiple={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="textPrimary">
            Select the date range for the report:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReportDateRangeSelect
            dateRange={dateRange}
            onChange={(dateRange) => setDateRange(dateRange)}
          />
        </Grid>
        {showDataFilter && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5" color="textPrimary">
                Select data filters for the report
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <TextSelect2
                label="State"
                value={filter.state}
                options={Constants.StateOptions}
                onChange={(item) => setFilter({ ...filter, state: item })}
                multiple={false}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <TextSelectPostApi
                label="County"
                value={filter.counties}
                apifn={endpoints.search.counties}
                postData={countyPostData}
                showEmptyFn={showEmptyFn}
                onChange={(item) => setFilter({ ...filter, counties: item })}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <TextSelectPostApi
                label="City"
                value={filter.cities}
                apifn={endpoints.search.counties}
                postData={countyPostData}
                showEmptyFn={showEmptyFn}
                onChange={(item) => setFilter({ ...filter, cities: item })}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <TextSelect2
                label="Gender"
                value={filter.gender}
                options={Constants.GenderOptions}
                onChange={(item) => setFilter({ ...filter, gender: item })}
                multiple={false}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Age >="
                    type="number"
                    value={filter.fromAge}
                    onChange={(e) =>
                      setFilter({ ...filter, fromAge: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Age <="
                    type="number"
                    value={filter.toAge}
                    onChange={(e) =>
                      setFilter({ ...filter, toAge: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="textPrimary">
                Select agency for the report (By default All agencies)
              </Typography>
            </Grid>
            {!filter.agency && (
              <Grid item xs={12}>
                <TextSearch
                  placeholder="Agency name"
                  label="Search agency by name, city, zip, street..."
                  apiFn={endpoints.entity.agency.search}
                  dataFn={(text) => {
                    return { searchText: text };
                  }}
                  resultsFn={(itemsAsync) => setAgencyAsync(itemsAsync)}
                />
                <EntityHome
                  title="Select Agency"
                  entity_type={EntityTypes.agency}
                  entityAsync={agencyAsync}
                  onSelect={(item) => setFilter({ ...filter, agency: item })}
                />
              </Grid>
            )}
            {filter.agency && (
              <Grid container>
                <Grid item xs={1}>
                  <Fab
                    aria-label="cancel"
                    onClick={() => setFilter({ ...filter, agency: null })}
                  >
                    <Clear />
                  </Fab>
                </Grid>
                <Grid item xs={5}>
                  <AddressView1 {...filter.agency} />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <ReportView filter={filter} dateRange={dateRange} />
    </Paper>
  );
};

export default ReportHome;
