import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { AddressView1 } from "../../common/AddressView";
import { UserTypes } from "../../common/Constants";
import { endpoints, useApi } from "../../hooks/use-api";
import { useAuth } from "../../hooks/use-auth";
import { useNotification } from "../../hooks/use-notification";
import { useNavigate } from "react-router-dom";

const UserExtendedSection = ({ user, reload }) => {
  const { id, name, email, parent: agency_id, is_admin } = user;
  const navigate = useNavigate();

  const { set: setNotification } = useNotification();
  const { user: authUser, userType, setOverrideEmail, logout } = useAuth();
  const [{ data: agency, loading, error }] = useApi(
    endpoints.entity.agency.loadOne(agency_id)
  );

  const [showAdminAccessYesNo, setShowAdminAccessYesNo] = useState(false);
  const [showRemoveUserYesNo, setShowRemoveUserYesNo] = useState(false);
  const [acionNote, setActionNote] = useState("");

  // User is not allowed to self delete or self grant/revoke admin previledge and only admin/new eyes allowed
  const showActionItems = () =>
    authUser.id !== id &&
    (authUser.is_admin || userType === UserTypes.neweyes) &&
    !showAdminAccessYesNo &&
    !showRemoveUserYesNo;

  const updateAdminAccess = () => {
    setShowAdminAccessYesNo(false);
    axios
      .post(endpoints.entity.agency.adminAccess(agency_id, id), {
        is_admin: !is_admin,
      })
      .then(function (response) {
        setNotification({
          message: `Updated admin access`,
          type: "success",
        });
        reload();
        // onAction(id, action);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  const removeUser = () => {
    setShowRemoveUserYesNo(false);
    axios
      .post(endpoints.entity.agency.removeUser(agency_id, id), {
        note: acionNote,
      })
      .then(function (response) {
        setNotification({
          message: `User removed`,
          type: "success",
        });
        reload();
        // onAction(id, action);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  //    console.log(user)

  if (agency_id === 0) {
    return <div>Not attach to any agency</div>;
  }

  const loginAsUser = () => {
    console.log(user);
    setOverrideEmail(email);

    logout().then((_) => {
      navigate("/login");
    });
  };

  return (
    <>
      {loading && <CircularProgress />}
      {error && <div>{error}</div>}
      {agency && (
        <>
          {userType === UserTypes.neweyes && (
            <AddressView1 {...agency} title="Affiliated Agency" />
          )}
          <Divider style={{ margin: "12px 0" }} />
          <Typography variant="subtitle2" color="textSecondary" component="div">
            {is_admin ? `${name} has admin access` : `${name} is a case worker`}
          </Typography>
          {/* New eyes and agency admin user can appoint anyone as admin user and also delete it */}
          {showActionItems() && (
            <>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => setShowAdminAccessYesNo(true)}
              >
                {is_admin ? "Revoke admin access" : "Grant admin access"}
              </Button>
              <Divider style={{ margin: "12px 0" }} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="div"
              >
                You can remove {name} from the agency here. Please note that
                once the user is removed from the agency, you can not add them
                back. User will need to reapply to join the agency.
              </Typography>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => setShowRemoveUserYesNo(true)}
              >
                Remove from agency
              </Button>
              <Divider style={{ margin: "12px 0" }} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="div"
              >
                Sudo login as the user - to see what the user see.
              </Typography>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => loginAsUser()}
              >
                Login as this user
              </Button>
            </>
          )}
          {showAdminAccessYesNo && (
            <>
              <Typography variant="h4" color="textSecondary" component="div">
                Are you sure you want to {is_admin ? "revoke" : "grant"} admin
                access for {name}?
              </Typography>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={updateAdminAccess}
              >
                Yes
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowAdminAccessYesNo(false)}
              >
                No
              </Button>
            </>
          )}
          {showRemoveUserYesNo && (
            <>
              <Typography variant="h4" color="textSecondary" component="div">
                Please record a reason for removing {name} from the agency
              </Typography>
              <TextField
                label="Reason"
                required={true}
                fullWidth
                multiline
                rows="4"
                defaultValue={acionNote}
                onChange={(e) => setActionNote(e.target.value)}
                margin="dense"
                variant="outlined"
              />
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={acionNote.length === 0}
                onClick={removeUser}
              >
                Remove User
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowRemoveUserYesNo(false)}
              >
                Cancel
              </Button>
            </>
          )}
          <Divider style={{ margin: "12px 0" }} />
        </>
      )}
    </>
  );
};

export default UserExtendedSection;
