import React, {useEffect, useState} from 'react'
import cx from 'classnames'
import {AppBar, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import {SearchVoucherPending} from '../Vouchers/SearchVoucher';
import PendingUsers from '../Agencies/PendingUsers';
import {useAuth} from '../../hooks/use-auth';
import {endpoints, useApi} from '../../hooks/use-api';
import {UserTypes} from '../../common/Constants'
import RedeemSummary from './RedeemSummary';
import CognitoUsers from '../Users/CognitoUsers';
import FPL from './fpl';

const InboxOption = ({ optionText, count, selected, onChange }) => (
    <li className="column is-narrow voucher-status-sel clickable">
        <Badge
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            badgeContent={count}
            color="primary"
        >
            <Button variant='outlined' style={{ border: 'none' }} className={cx({ selected: selected })}>
                <span onClick={onChange}>{ optionText }</span>
                {/* { status && <Typography color='primary' className="count">{ count }</Typography> } */}
            </Button>
        </Badge>
    </li>
)

const InboxPage = (props) => {
    const { user } = useAuth();
    const [option, setOption] = useState('none')
    const [ { data, loading, error }, refetch ] = useApi(endpoints.entity.user.inbox(), {useCache: false})
    {/* Commented as per March Enhancement */ }
    // useEffect(() => setOption(user.userType === UserTypes.neweyes ? 'summary' : 'voucher'), [user])
    {/* inserted as per March Enhancement */ }
       useEffect(()=> setOption('voucher'),[user])

    if (!user) {
        return <React.Fragment>&nbsp;</React.Fragment>
    }

    return <>
    { data &&
        <>
            <AppBar position="static" color="inherit">
                <div className="voucherFilter">
                    <Grid container justifyContent='center'>
                        <ul className={cx("result-filters")}>
                            <InboxOption optionText={ user.userType === 1 ? "Pending Approval" : "Approved Voucher" } count={data.vouchers} selected={option === "voucher"} onChange={() => setOption("voucher")} />
                            <InboxOption optionText="pending payment" selected={option === "payment"} count={data.payments} onChange={() => setOption("payment")} />
                            {user.userType !== 3 &&
                                <InboxOption optionText="Pending Users" selected={option === "user"} count={data.users} onChange={() => setOption("user")} /> }
                            {user.userType === UserTypes.neweyes &&
                                <>
                                    <InboxOption optionText="Reset Password" selected={option === "cognito"} onChange={() => setOption("cognito")} />
                                    <InboxOption optionText="FPL" selected={option === "fpl"} onChange={() => setOption("fpl")} />
                                </> }
                        </ul>
                    </Grid>
                </div>
            </AppBar>
            
            {option === "voucher" && <SearchVoucherPending reload={refetch} /> }
        {option === "payment" && <SearchVoucherPending reload={refetch} url={endpoints.entity.voucher.pending_fee()} />}
            {option === "user" && <PendingUsers reload={refetch} agencyId={user.agency.id} /> }
            {option === "cognito" && <CognitoUsers /> }
            {option === "fpl" && <FPL /> }
        </>
    }
    </>;
}

export default InboxPage;


