import {
  Divider,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DiscountTypes } from "../../../common/Constants";
import NoteConfirmation from "../../../common/NoteConfirmation";
import { endpoints } from "../../../hooks/use-api";
import { useNotification } from "../../../hooks/use-notification";

const parseFloatOrDefault = (value) => {
  let floatValue = parseFloat(value);
  return isNaN(floatValue) ? 0.0 : floatValue;
};

const FinancialSection = ({ voucher, allowChange, reloadfn }) => {
  const [financial, setFinancial] = useState({
    base_amount: 0.0,
    upgrade_amount: 0.0,
    program_fee: 0.0,
    patient_paid: 0.0,
    discount_pct: 0.0,
    total_patient_cost: 0.0,
  });
  const [amountDisable, setAmountDisable] = useState(true);
  const [upgradeAmountDisable, setUpgradeAmountDisable] = useState(true);
  const [refundClicked, setRefundClicked] = useState(false);
  //?? const {base_amount, upgrade_amount, patient_paid, dispenser_cost} = financial

  const [computedFinancial, setComputedFinancial] = useState({
    discount_amount: 0.0,
    dispenser_cost: 0.0,
    patient_cost: 0.0,
    total_patient_cost: 0.0,
  });

  const { set: setNotification } = useNotification();
  const [financialForReset, setFinancialForReset] = useState({});

  useEffect(() => {
    setFinancial(voucher.voucher_financial);
  }, [voucher]);

  useEffect(() => {
    var discount = parseFloatOrDefault(financial.program_fee) * financial.discount_pct;
    setComputedFinancial({
      ...computedFinancial,
      discount_amount: discount,
      dispenser_cost:
        parseFloatOrDefault(financial.base_amount) +
        parseFloatOrDefault(financial.upgrade_amount),
      patient_cost:
        parseFloatOrDefault(financial.program_fee) +
        parseFloatOrDefault(financial.reissue_fee) -
        parseFloatOrDefault(discount),
      total_patient_cost:
        parseFloatOrDefault(financial.program_fee) +
        parseFloatOrDefault(financial.reissue_fee) +
        parseFloatOrDefault(financial.upgrade_amount) -
        parseFloatOrDefault(discount),
    });
  }, [financial]);

  const updateFinancial = (note) => {
    axios
      .put(endpoints.entity.voucher.updateFinancial(voucher.id), {
        base_amount: parseFloatOrDefault(financial.base_amount),
        upgrade_amount: parseFloatOrDefault(financial.upgrade_amount),
        program_fee: parseFloatOrDefault(financial.program_fee),
        patient_paid: parseFloatOrDefault(financial.patient_paid),
        dispenser_cost: parseFloatOrDefault(computedFinancial.dispenser_cost),
        program_fee: parseFloatOrDefault(financial.program_fee),
        reissue_fee: parseFloatOrDefault(financial.reissue_fee),
        discount_pct: parseFloatOrDefault(financial.discount_pct),
        note: note,
      })
      .then(function (response) {
        setNotification({
          message: `Updated voucher status`,
          type: "success",
        });
        reloadfn(voucher.id);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  const onChangeAmount = () => {
    setFinancialForReset({ ...financial });
    setAmountDisable(false);
  }

  const onChangeUpgradeAmountCancel = () => {
    setFinancial({
      ...financial,
      upgrade_amount: financialForReset.upgrade_amount,
    });
    setUpgradeAmountDisable(true);
  }

  const onClickUpgradeAmount = (note) => {
    setFinancialForReset({ ...financial });
    setUpgradeAmountDisable(false);
  }

  const onChangeUpgradeAmount = (note) => {
    axios
      .put(endpoints.entity.voucher.upgradeAmount(voucher.id), {
        upgradeAmount: financial.upgrade_amount,
        note: note,
      })
      .then(function (response) {
        setNotification({
          message: `Upgrade amount successful`,
          type: "success",
        });
        reloadfn(voucher.id);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  }

  const onRefundAmount = (note) => {
    axios
      .put(endpoints.entity.voucher.refund(voucher.id), {
        note: note,
      })
      .then(function (response) {
        setNotification({
          message: `Refund successful`,
          type: "success",
        });
        reloadfn(voucher.id);
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  }

  const onChangeAmountCancel = () => {
    setFinancial({
      ...financial,
      reissue_fee: financialForReset.reissue_fee,
      patient_paid: financialForReset.patient_paid,
      discount_pct: financialForReset.discount_pct,
    });
    setAmountDisable(true);
  }

  // const handleDiscountChange = (e) => {
  //   var discount = parseFloatOrDefault(financial.program_fee) * e.target.value;
  //   setFinancial({
  //     ...financial,
  //     discount_pct: e.target.value,
  //     discount_amount: discount,
  //     patient_cost:
  //       parseFloatOrDefault(financial.program_fee) +
  //       parseFloatOrDefault(financial.reissue_fee) +
  //       parseFloatOrDefault(financial.upgrade_amount) -
  //       parseFloatOrDefault(discount),
  //   });
  // }

  if (!financial) {
    return <div></div>;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <Typography variant="button" color="textSecondary">
          Financial
        </Typography>
      </Grid>

      <Grid container item xs={6} style={{ borderRight: '2px solid gray', paddingRight: '10px' }}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="caption" color="textPrimary" fontWeight="bold">
              Cost of Eyeglass($):
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginTop: "12px", marginBottom: "12px", bgcolor: "ActiveBorder", borderBottomWidth: 2 }} />
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Base Price:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Input
            size="small"
            fullWidth
            value={financial.base_amount}
            inputProps={{ style: { textAlign: 'right', marginRight: '10px' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Upgrade Price:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            hiddenLabel
            fullWidth
            size="small"
            value={financial.upgrade_amount}
            disabled={upgradeAmountDisable}
            inputProps={{ style: { textAlign: 'right' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            variant="filled"
            onChange={(e) => setFinancial({ ...financial, upgrade_amount: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" size="small" color="GrayText">
            * Upgrade price is collected outside of this system
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="caption" color="textPrimary" fontWeight="bold">
              Applicant Dues collected by New Eyes($):
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginTop: "12px", marginBottom: "12px", bgcolor: "ActiveBorder", borderBottomWidth: 2 }} />
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Application Fee:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            hiddenLabel
            fullWidth
            size="small"
            value={financial.program_fee}
            disabled={amountDisable}
            inputProps={{ style: { textAlign: 'right' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            variant="filled"
            onChange={(e) => setFinancial({ ...financial, program_fee: e.target.value })}
          />
        </Grid>

        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Application Fee Discount:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            hiddenLabel
            fullWidth
            label="Fee Discount (%)"
            value={financial.discount_pct}
            size="small"
            onChange={(e) => setFinancial({ ...financial, discount_pct: e.target.value })}
            disabled={amountDisable}
            variant="filled"
          >
            {DiscountTypes.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Reissue Price:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            hiddenLabel
            fullWidth
            size="small"
            value={financial.reissue_fee}
            disabled={amountDisable}
            inputProps={{ style: { textAlign: 'right' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            variant="filled"
            onChange={(e) => setFinancial({ ...financial, reissue_fee: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ marginTop: "12px", marginBottom: "12px", bgcolor: "ActiveBorder", borderBottomWidth: 2 }} />
      </Grid>

      <Grid container item xs={6} style={{ borderRight: '2px solid gray', paddingRight: '10px' }}>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Cost of Eyeglass($):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Input
            fullWidth
            value={computedFinancial.dispenser_cost}
            inputProps={{ style: { textAlign: 'right', marginRight: '10px' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Total Cost to Applicant($):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Input
            fullWidth
            value={computedFinancial.total_patient_cost}
            inputProps={{ style: { textAlign: 'right', marginRight: '10px' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={true}
          />
        </Grid>

      </Grid>
      <Grid container item xs={6} spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Discount($):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Input
            fullWidth
            value={-computedFinancial.discount_amount}
            inputProps={{ style: { textAlign: 'right', marginRight: '10px' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Applicant dues ($):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Input
            fullWidth
            value={computedFinancial.patient_cost}
            inputProps={{ style: { textAlign: 'right', marginRight: '10px' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" color="textPrimary">
            Received From Applicant($):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            hiddenLabel
            fullWidth
            value={financial.patient_paid}
            inputProps={{ style: { textAlign: 'right' } }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={amountDisable}
            size="small"
            variant="filled"
            error={parseFloatOrDefault(financial.patient_paid) < parseFloatOrDefault(computedFinancial.patient_cost)}
            onChange={(e) =>
              setFinancial({
                ...financial,
                patient_paid: e.target.value
              })
            }
          />
        </Grid>
      </Grid>
      {allowChange && (
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
          {amountDisable && financial.patient_paid > 0 && upgradeAmountDisable && (
            <NoteConfirmation
              buttonLabel="Refund to applicant"
              confirmButtonLabel="Refund to original payment method"
              noteRequired={true}
              onClick={() => setRefundClicked(true)}
              onCancel={() => setRefundClicked(false)}
              onConfirm={(note) => onRefundAmount(note)}
            />
          )}
          {voucher.actions[0].status === "pending_payment" && upgradeAmountDisable && (
            <NoteConfirmation
              buttonLabel="Update Amount"
              confirmButtonLabel="Update"
              label="Why changed the amount?"
              noteRequired={true}
              onClick={onChangeAmount}
              onCancel={onChangeAmountCancel}
              onConfirm={(note) => updateFinancial(note)}
            />
          )}
          {/* {!refundClicked && amountDisable && (
            <NoteConfirmation
              buttonLabel="Update Upgrade Price Only"
              confirmButtonLabel="Update Upgrade Price"
              label="What are the upgrades offered to the applicant?"
              noteRequired={true}
              onClick={onClickUpgradeAmount}
              onCancel={onChangeUpgradeAmountCancel}
              onConfirm={(note) => onChangeUpgradeAmount(note)}
            />
          )} */}
        </Grid>
      )}
    </Grid>
  );
};

export default FinancialSection;
