import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useRef } from "react";
import { HowFoundNewEyesOptions } from "../../common/Constants";
import { TextSelect } from "../../common/TextSelect";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import { BoxRow } from "../DataEntry/StyledFields";
import ReCAPTCHA from "react-google-recaptcha";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateName = (name) => {
  return String(name)
    .toLowerCase()
    .match(/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i);
};

const UserForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const { set: setNotification } = useNotification();
  const [saving, setSaving] = useState(false);
  const [done, setDone] = useState(false);
  const [howFoundNewEyes, setHowFoundNewEyes] = useState("");
  const [specificInfo, setSpecificInfo] = useState("");
  const [error, setError] = useState("");

  const captchaRef = useRef(null);

  const sendRequest = () => {
    // Remove leading and trailing spaces.
    const fName = firstName.trim();
    const lName = lastName.trim();
    const email2 = email.trim();

    // Check if all required field is populated.
    if (fName.length === 0) {
      setError("First name is a required field.");
    } else if (!validateName(fName)) {
      setError("Please provide a valid first name.");
    } else if (lName.length === 0) {
      setError("Last name is a required field.");
    } else if (!validateName(lName)) {
      setError("Please provide a valid last name.");
    } else if (email2.length === 0) {
      setError("Email is a required field.");
    } else if (!validateEmail(email2)) {
      setError("Please provide a valid email.");
    } else if (howFoundNewEyes.length === 0) {
      setError("How did you hear from us is a required field.");
    } else if (howFoundNewEyes === "other" && specificInfo.length === 0) {
      setError("You selected `Other`. Please provide additional details");
    } else if (!captchaRef.current.getValue()) {
      setError("Please complete the reCAPTCHA.");
    } else {
      setSaving(true);
      axios
        .post(endpoints.apply.new(), {
          email: email2,
          firstName: fName,
          lastName: lName,
          howFoundNewEyes: howFoundNewEyes,
          additionalInfo: specificInfo,
          reCaptchaToken: captchaRef.current.getValue(),
        })
        .then(function (response) {
          if (response.data) {
            setDone(true);
          } else {
            setError("Invalid reCAPTCHA.");
            setNotification({
              message: `Error ${response.status}: ${response.statusText}`,
              type: "error",
            });
            setSaving(false);
            captchaRef.current.reset();
          }
          //?? onExit(response.data);
        })
        .catch(function (error) {
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
          setSaving(false);
        });
    }
  };

  return (
    <>
      {done && (
        <Typography variant="h5" sx={{ my: 10 }}>
          We have sent you an email with a secure link to continue with your
          application. You can close this browser.
        </Typography>
      )}
      {!done && (
        <>
          <BoxRow>
            <Typography variant="h5">
              Please provide the following information and we will email you a
              secure link to complete your application OR check the status of
              your existing application:
            </Typography>
          </BoxRow>
          <BoxRow>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>
          <BoxRow>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>
          <BoxRow>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
              required
            />
          </BoxRow>
          <BoxRow>
            <TextSelect
              label="How did you hear about us"
              value={howFoundNewEyes}
              options={HowFoundNewEyesOptions}
              multiple={false}
              onChange={(newValue) => setHowFoundNewEyes(newValue)}
            />
          </BoxRow>
          <BoxRow>
            <TextField
              label="Please share specific information if any"
              value={specificInfo}
              onChange={(e) => setSpecificInfo(e.target.value)}
              margin="dense"
              fullWidth
              variant="outlined"
            />
          </BoxRow>
          <BoxRow>
            <Typography
              variant="error"
              color="error"
              style={{ marginTop: "12px" }}
            >
              &nbsp;&nbsp;{error}
            </Typography>
          </BoxRow>
          <BoxRow>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA}
              ref={captchaRef}
              onChange={() => setError("")}
            />
          </BoxRow>
          <BoxRow>
            <Button
              fullWidth
              type="button"
              variant="contained"
              disabled={saving}
              onClick={(e) => sendRequest()}
            >
              Submit
            </Button>
          </BoxRow>
        </>
      )}
    </>
  );
};

export default UserForm;
