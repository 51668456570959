export const validate = inputs => {
  const errors = [];

  Object.keys(inputs).forEach(elemName => {
    let element = inputs[elemName];
    let value = element.value;
    let label = element.label;
    let rules = element.rules;
    errors[elemName] = "";

    for (let index = 0; index < rules.length; index++) {
      const rule = rules[index];
      switch (rule) {
        case "required":
          if (!value) errors[elemName] = `${label} is required`;
          break;

        case "email":
          let regex = /^([0-9a-zA-Z]([-_\\.\\+]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,})$/;
          if (!regex.test(value)) errors[elemName] = `${label} is invalid`;
          break;

        case "whitespace":
          let whiteSpaceReg = new RegExp("\\s");
          if (whiteSpaceReg.test(value))
            errors[elemName] = "White space is not allowed";
          break;

        case "phone":
          let phoneRegex = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
          if (!phoneRegex.test(value)) errors[elemName] = `${label} is invalid`;
          break;

        case "email_phone":
          let ER = /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
          let PR = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

          if (!ER.test(value) && !PR.test(value))
            errors[elemName] = `${label} is invalid`;
          break;

        default:
          break;
      }

      if (errors[elemName] !== "") {
        break;
      }
    }
  });

  let isValid = true;

  Object.keys(errors).forEach(error => {
    if (errors[error] !== "") isValid = false;
  });

  return { isValid: isValid, errors: errors };
};
