import {
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import NoteConfirmation from "../../../common/NoteConfirmation";

const DispenserSection = ({ voucher, status, redeemClicked, paidClicked }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <Typography variant="button" color="textSecondary">
          Dispenser
        </Typography>
        <Typography variant="h6">FEA Industries Inc.</Typography>
        {status === "approved" && (
          <NoteConfirmation
            buttonLabel="Mark as Redeemed"
            onConfirm={(note) => redeemClicked(note)}
          />
        )}
        {status === "redeemed" && (
          <>
            {/* Shipping info:<br />
                        <UspsTrack /> */}
            <NoteConfirmation
              buttonLabel="Mark as Paid"
              onConfirm={(note) => paidClicked(note)}
            />
          </>
        )}
        {status === "paid" && <Typography variant="button">Paid</Typography>}
      </Grid>
    </Grid>
  );
};

export default DispenserSection;
