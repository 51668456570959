// import Preview from './Preview';
import { differenceInYears, isValid, parse } from "date-fns";
import _ from "lodash";
import React, { useEffect } from "react";
import { VoucherAppPages } from "../../../../common/Constants";
import {
  MoveNext,
  useMultiStepForm,
} from "../../../DataEntry/MultiFormContext";
import { ConfirmPdMeasurement } from "../AcceptTerms";
import { VoucherValidator } from "../Model";
import { WizardPage } from "../WizardPage";

const getToday = () => {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

const preQualData = {
  prevfn: (data) => false,
  nextfn: (data) => {
    return (
      VoucherValidator.hasRecentPrescription(data) &&
      VoucherValidator.hasValidEyeExamDate(data) &&
      (VoucherValidator.hasEyeExamOver2years(data) ||
        VoucherValidator.hasPdMeasurement(data))
    );
  },
  nextPageFn: (data) => {
    let dt = parse(data["eye_exam_date"], "yyyy-MM-dd", new Date());
    var today = getToday();
    var years = differenceInYears(today, dt);
    return years >= 2 ? VoucherAppPages.visionTest : VoucherAppPages.applicant;
  },
  items: [
    {
      id: 1,
      type: "oneOf",
      visiblefn: (_data) => true,
      name: "hasRecentPrescription",
      label: (data) =>
        data.individualApplication
          ? "Do you have an eyeglass prescription?"
          : "Does the applicant have an eyeglass prescription?",
      property: "hasRecentPrescription",
      valuefn: (data) => data["hasRecentPrescription"],
      // "datafn": (data, value) => data["hasRecentPrescription"] = value,
      options: [
        {
          label: "Yes",
          value: "true",
        },
        {
          label: "No",
          value: "false",
        },
      ],
    },

    {
      id: 2,
      type: "date",
      visiblefn: (data) => data["hasRecentPrescription"] === "true",
      name: "eye_exam_date",
      min: 15,
      label: "When was the prescription issued?",
      inputLabel: "Date on eyeglass prescription (mm/dd/yyyy)",
      property: "eye_exam_date",
      valuefn: (data) => data["eye_exam_date"],
      //      "valueObj": { value: null, future: false, inRange: true, outOfRange: false },
    },

    {
      id: 3,
      type: "error",
      visiblefn: (data) => data["hasRecentPrescription"] === "false",
      label: (
        <p>
          Please see your eye doctor for an exam and to get a prescription. You
          can click{" "}
          <a href="https://new-eyes.org/source-of-free-eye-exams">here</a> to
          search for free or low-cost eye exams in your area.
        </p>
      ),
    },

    {
      id: 4,
      type: "checkbox",
      visiblefn: (data) => {
        if (data["hasRecentPrescription"] === "false") return false;

        let dt = new Date(data["eye_exam_date"]); // parse(data["eye_exam_date"], "yyyy-MM-dd", new Date());
        var today = getToday();
        if (!isValid(dt) || dt > today) return false;
        var years = differenceInYears(today, dt);
        return years >= 0 && years < 2;
      },
      label: <ConfirmPdMeasurement />,
      property: "prescriptionHasPdMeasurement",
      valuefn: (data) => data["prescriptionHasPdMeasurement"],
    },

    {
      id: 5,
      type: "error",
      visiblefn: (data) => {
        if (
          data["hasRecentPrescription"] === "false" ||
          !data["eye_exam_date"] ||
          data["eye_exam_date"] === ""
        )
          return false;
        let dt = new Date(data["eye_exam_date"]); // parse(data["eye_exam_date"], "yyyy-MM-dd", new Date());
        var today = getToday();
        if (!isValid(dt) || dt > today) return false;
        var years = differenceInYears(today, dt);
        return years >= 2;
      },
      label: (
        <>
          <p>
            This prescription is older than 24 months. We need a more recent
            prescription. The applicant may be eligible to renew the
            prescription via the New Eyes Online Vision Test.
          </p>
          <p>
            Please note, the online visibility test does not replace a full eye
            exam and eligibility for the test is based on age and state of
            residence.
          </p>
        </>
      ),
    },
  ],
};

const RecentPrescription = ({ data, isSubmitting, isValidating, errors, multiStepState }) => {
  //?? const multistep = useMultiStepForm();
  const { activeStep,
    setActiveStep, enableNext, setEnableNext } = multiStepState;

  useEffect(() => {
    console.log("1 multistep=" + JSON.stringify(multiStepState));
  }, [multiStepState]);

  useEffect(() => {
    console.log("2 enableNext=" + enableNext);
  }, [enableNext]);

  // Evaluate whenever data is updated
  useEffect(() => {
    // console.log(errors);
    if (isSubmitting && !isValidating && _.isEmpty(errors)) {
      console.log("submit the form");
      if (
        VoucherValidator.hasRecentPrescription(data) &&
        VoucherValidator.hasValidEyeExamDate(data)
      ) {
        //??setActiveStep(activeStep + 1);
        //?? setEnableNext(1);
        if (VoucherValidator.hasEyeExamOver2years(data)) {
          setEnableNext(MoveNext.Page);
        } else if (VoucherValidator.hasPdMeasurement(data)) {
          setEnableNext(MoveNext.Step);
        }
      }
    }

    // if (isSubmitting) {
    //   if (data["hasRecentPrescription"] === "") {
    //   }
    // }
  }, [isSubmitting, isValidating]);

  // useEffect(() => console.log(data), [data]);

  return (
    <WizardPage
      items={preQualData.items}
      data={data}
      errors={errors}
      setData={(c) => c}
    />
  );
};

export default RecentPrescription;
