// import { Box, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import { MainContainer, MainContent } from "../DataEntry/StyledFields";
import VerifyVoucherForm from "./VerifyVoucherForm";

const OrderHomeOld = () => {
  return (
    <MainContainer>
      <MainContent>
        <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
          <img src={logo} width="300px" alt="logo" />
        </Box>
        <VerifyVoucherForm />
      </MainContent>
    </MainContainer>
  );
};

export default OrderHomeOld;
