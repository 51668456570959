import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { endpoints } from "../../../../hooks/use-api";
import { MoveNext, useMultiStepForm } from "../../../DataEntry/MultiFormContext";
import { BoxLabel, BoxRow } from "../../../DataEntry/StyledFields";
import { VoucherValidator } from "../Model";

const DocumentUploaderDialog = (props) => {
  const { individualId, type, title, onClose, open } = props;
  const [fileInfo, setFileInfo] = useState();

  const onDropAccepted = (acceptedFiles) => {
    let file = acceptedFiles[0];
    setFileInfo(`${file.path} (${file.size})`);
    var formData = new FormData();
    formData.append("file", file);

    axios({
      method: "post",
      url: endpoints.apply.proof(individualId, type),
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success) {
          onClose(response.data.name, file.path, file.size);
          setFileInfo();
        } else {
          setFileInfo(`${file.path} (${file.size}) - FAILED`);
        }
      })
      .catch(function (response) {
        setFileInfo(`${file.path} (${file.size}) - FAILED`);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    multiple: false,
    accept: "application/pdf, image/jpeg, image/png",
  });

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      // TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {!fileInfo && (
          <Box
            {...getRootProps()}
            sx={{
              border: "dashed 1px black",
              m: 1,
              width: "100%",
              height: 200,
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
            <em>(Only *.pdf, *.jpeg and *.png images will be accepted)</em>
          </Box>
        )}
        {fileInfo && (
          <Typography
            variant="caption"
            color="Highlight"
            sx={{
              ml: 2,
            }}
          >
            Uploading {fileInfo}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            onClose(null, null, 0);
            setFileInfo(null);
          }}
        >
          Cancel
        </Button>
        {/* <Button onClick={handleOk}>Ok</Button> */}
      </DialogActions>
    </Dialog>
  );
};

const DocumentOption = ({ item, label, individualId, onChange }) => {
  const { type, file, size } = item;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (file && file.length > 0) {
      setChecked(true);
    }
  }, [file]);

  return (
    <Box>
      <Checkbox
        id={type}
        disabled={file != null}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        inputProps={{ "aria-label": "controlled" }}
      />
      <label htmlFor={type}>{label}</label>
      {checked && !file && (
        <Button onClick={() => setOpen(true)}>Upload</Button>
      )}
      {checked && file && (
        <>
          <Typography
            variant="caption"
            color="Highlight"
            sx={{
              ml: 2,
            }}
          >
            {`${file} (${size})`}
          </Typography>
          <Button onClick={() => onChange(type, { key: null, file: null })}>
            Remove
          </Button>
        </>
      )}
      <DocumentUploaderDialog
        id={`dlg_{type}`}
        individualId={individualId}
        type={type}
        title={label}
        keepMounted
        open={open}
        onClose={(key, name, size) => {
          onChange(type, { key: key, file: name, size: size });
          setOpen(false);
        }}
      />
    </Box>
  );
};

// const docOptions = [
//   { id: "snap", label: "SNAP Benefits" },
//   { id: "unemployment", label: "Unemployment Assistance" },
//   { id: "taxreturn", label: "2019 or 2020 Federal Tax Return" },
//   { id: "other", label: "Other" },
// ];

const optionLabels = {
  snap: "SNAP Benefits",
  unemployment: "Unemployment Assistance",
  taxreturn: "2019 or 2020 Federal Tax Return",
  other: "Other",
};

const UploadDocument = ({ data, formProps, onChange }) => {
  const { individualId, voucher_detail } = data;
  const items = voucher_detail.docs;
  // const [items, setItems] = useState(voucher_detail.docs);

  return (
    <BoxRow>
      <Box sx={{ fontWeight: "bold", my: 4 }}>Proof of Need</Box>
      <BoxLabel>Check all documents you will be uploading now</BoxLabel>
      {items.map((item, index) => (
        <DocumentOption
          key={index}
          individualId={individualId}
          item={item}
          label={optionLabels[item.type]}
          onChange={(type, updateItem) => {
            let newItems = [...items];
            for (var i = 0; i < newItems.length; ++i) {
              if (newItems[i].type === type) {
                newItems[i] = { ...newItems[i], ...updateItem };
                break;
              }
            }
            onChange({ docs: newItems });
          }}
        />
      ))}
      <p />
      <BoxLabel color="red">To continue you must upload at least one document.</BoxLabel>
    </BoxRow>
  );
};

export default UploadDocument;
