import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { EntityTypes } from "../../common/Constants";
import { endpoints } from "../../hooks/use-api";
import { useAuth } from "../../hooks/use-auth";
import { useNotification } from "../../hooks/use-notification";
import { EntityHome } from "../Entities";

const UserPoolId_Const = "us-east-1_ibKk20WZs";

const CognitoUserPanel = ({ cognitoProvider, user }) => {
  const { set: setNotification } = useNotification();

  const [resetPasswordDisabled, setResetPasswordDisabled] = useState(false);

  const [tempPassword, setTempPassword] = useState("Abcthom123@");
  const [tempPasswordDisabled, setTempPasswordDisabled] = useState(false);

  const resetPasswordCall = () => {
    var params = {
      UserPoolId: UserPoolId_Const,
      Username: user.email,
    };

    cognitoProvider.adminResetUserPassword(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        setNotification({
          message: `Failed to reset password: ${err}`,
          type: "error",
        });
      } else {
        console.log(data);
        setNotification({
          message: "Reset password successful",
          type: "success",
        });
      }
    });
  };

  const verifyEmail = () => {
    var params = {
      UserPoolId: UserPoolId_Const,
      Username: user.email,
      UserAttributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
      ],
    };

    cognitoProvider.adminUpdateUserAttributes(params, function (err, data) {
      if (err) console.log(err, err.stack);
      // an error occurred
      else {
        console.log(data);
        resetPasswordCall();
      }
    });
  };

  const resetPassword = (e) => {
    console.log("user unconfirmed");
    console.log(user);
    setResetPasswordDisabled(true);
    // Confirm the user if not confirmed before sending reset password email
    if (user.user_status === "UNCONFIRMED") {
      var params = {
        UserPoolId: UserPoolId_Const,
        Username: user.email,
      };

      cognitoProvider.adminConfirmSignUp(params, function (err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          verifyEmail();
          console.log(data);
        }
      });
    } else if (user.email_verified === "false") {
      verifyEmail();
    } else {
      resetPasswordCall();
    }
  };

  const setTemporaryPasswordFn = () => {
    setTempPasswordDisabled(true);
    var params = {
      Password: tempPassword,
      UserPoolId: UserPoolId_Const,
      Username: user.email,
      Permanent: false,
    };

    cognitoProvider.adminSetUserPassword(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        setNotification({
          message: `Failed to set password: ${err}`,
          type: "error",
        });
      } else {
        console.log(data);
        setNotification({
          message: "Temporary password setup successful",
          type: "success",
        });
      }
    });
  };

  const DeleteFromBackend = () => {
    // Delete from backend
    axios
      .delete(endpoints.entity.user.deleteUser(user.email))
      .then(function (response) {
        setNotification({
          message: `User is deleted`,
          type: "success",
        });
      })
      .catch(function (error) {
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
      });
  };

  const deleteUserFn = () => {
    var params = {
      UserPoolId: UserPoolId_Const,
      Username: user.email,
    };

    cognitoProvider.adminDeleteUser(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        setNotification({
          message: `Failed to delete user: ${err}`,
          type: "error",
        });
      } else {
        console.log(data);
        DeleteFromBackend();
      }
    });
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Typography variant="body1" color="textPrimary">
            Send password reset email to <strong>{user.email}</strong>
          </Typography>
          <br />
          <br />
          <Button
            disabled={resetPasswordDisabled}
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
            onClick={resetPassword}
          >
            Reset Password
          </Button>
          <br />
          <br />
          <br />
          <Typography variant="body1" color="error">
            Note that you must try the Reset Password first before trying this
            option.
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            Set temporary password for <strong>{user.email}</strong>
          </Typography>
          <br />
          <Typography variant="body1" color="textSecondary">
            Use this option when the case worker is not getting email because of
            their IT setup or any other issues.{" "}
          </Typography>
          <TextField
            label="Temporary password"
            size="large"
            value={tempPassword}
            onChange={(e) => setTempPassword(e.target.value)}
            margin="dense"
            fullWidth
            type="text"
            variant="outlined"
          />
          <Button
            disabled={tempPasswordDisabled}
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
            onClick={setTemporaryPasswordFn}
          >
            Set temporary password
          </Button>

          <br />
          <br />
          <br />
          <Typography variant="body1" color="error">
            Note that this will remove user from any agency, remove their signin
            and account. Please know what you are doing.
          </Typography>
          <br />
          <Button
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
            onClick={deleteUserFn}
          >
            Delete User
          </Button>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
};

const CognitoUsers = (props) => {
  const { isLoading, user } = useAuth();

  const [cognitoProvider, setCognitoProvider] = useState();
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [entityAsync, setEntityAsync] = useState({ loading: false, data: [] });

  useEffect(() => {
    Auth.currentSession().then((session) => {
      AWS.config.region = "us-east-1";

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "us-east-1:8c8d5620-7440-47ca-b86c-ba531459eaeb",
        Logins: {
          // Change the key below according to the specific region your user pool is in.
          "cognito-idp.us-east-1.amazonaws.com/us-east-1_ibKk20WZs": session
            .getIdToken()
            .getJwtToken(),
        },
      });

      setCognitoProvider(new AWS.CognitoIdentityServiceProvider());
    });
    // .catch(e => {
    //     setSessionValid(false)
    //     console.log(e);
    // });
  }, [user]);

  const findValue = (attrs, name) => {
    var attr = attrs.find((attr) => attr.Name === name);
    return attr ? attr.Value : "";
  };

  const searchEmail = (e) => {
    var params = {
      UserPoolId: UserPoolId_Const,
      Filter: `email = '${email}'`,
      Limit: 0,
    };

    cognitoProvider.listUsers(params, function (err, data) {
      if (err) console.log(err, err.stack);
      // an error occurred
      else {
        console.log(data.Users);
        setEntityAsync({
          loading: false,
          data: data.Users.map((u) => ({
            user_name: u.Username,
            name: findValue(u.Attributes, "name"),
            email: findValue(u.Attributes, "email"),
            phone_number: findValue(u.Attributes, "phone_number"),
            email_verified: findValue(u.Attributes, "email_verified"),
            user_status: u.UserStatus,
            updatedOn: u.UserLastModifiedDate,
          })),
        });
      }
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            fullWidth
            type="email"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={searchEmail}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <EntityHome
        title="Email Search Results"
        entity_type={EntityTypes.cognito}
        entityAsync={entityAsync}
        showUpdate={false}
        extendPanel={({ item }) => (
          <CognitoUserPanel cognitoProvider={cognitoProvider} user={item} />
        )}
      />

      {/* <h1>Unconfirmed Users</h1>
            {cognitoUsers.map(o => <p>{o.name} | {o.email} | {o.updatedOn.toString()}</p>)} */}
    </div>
  );
};

export default CognitoUsers;
