import { AccountCircle, Search } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { InputAdornment, TextField, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { UserTypes } from "../common/Constants";
import Routes from "../common/Routes";
import { useAuth } from "../hooks/use-auth";
import SiteMenuDrawer from "./SiteMenuDrawer";
import { useNavigate } from "react-router-dom";

const userMenu = {
  [UserTypes.neweyes]: [
    Routes.inbox,
    Routes.vouchers,
    Routes.agencies,
    Routes.reports,
  ],
  [UserTypes.agency]: [
    Routes.inbox,
    Routes.vouchers,
    Routes.users,
    Routes.applicants,
  ],
  [UserTypes.caseworker]: [Routes.inbox, Routes.vouchers],
  [UserTypes.registered]: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Header = ({ user }) => {
  const navigate = useNavigate();

  const classes = useStyles();

  // todo: add auth check to the 'onChange' handler
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);

  const [curTab, setCurTab] = useState(Routes.inbox.route);

  const {
    userType,
    setUserView,
    searchOpen,
    setSearchOpen,
    searchOption,
    setSearchOption,
  } = useAuth();
  const { title, searchText, cannedDateRange, sortBy, startDate, endDate } =
    searchOption;

  useEffect(() => {
    setUserView(user.userType);
  }, [user, setUserView]);

  const [open, setOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOption = (route) => {
    setAnchorEl(null);
    navigate(route);
    setCurTab(false);
  };

  const handleProfileMenuOption = (route) => {
    setProfileAnchorEl(null);
    navigate(route);
    setCurTab(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
    // handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={() => handleMenuOption(Routes.users.route)}>
        {Routes.users.name}
      </MenuItem>
      <MenuItem onClick={() => handleMenuOption(Routes.applicants.route)}>
        {Routes.applicants.name}
      </MenuItem>
      <MenuItem onClick={() => handleMenuOption(Routes.dispensers.route)}>
        {Routes.dispensers.name}
      </MenuItem>
    </Menu>
  );

  const profileMenuId = "primary-search-account-profilr-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(profileAnchorEl)}
      onClose={handleProfileMenuOption}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem>
        <Typography variant="caption" display="block" gutterBottom>
          {user.name}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleMenuOption(Routes.logout.route)}>
        {Routes.logout.name}
      </MenuItem>
    </Menu>
  );

  return (
    // <HideOnScroll>
    <React.Fragment>
      <AppBar position="sticky">
        <Toolbar style={{ minHeight: 70, backgroundColor: "#141414" }}>
          <a style={{ cursor: "pointer", width: "100px" }} href="/">
            <img src="images/ne-logo-white.png" alt="logo" />
          </a>
          <Box width="100%">
            <Tabs
              centered
              value={curTab}
              onChange={(e, value) => {
                navigate(value);
                setCurTab(value);
              }}
            >
              {userMenu[userType].map(({ name, route }, index) => (
                <Tab
                  key={index}
                  sx={{ color: "white" }}
                  label={name}
                  value={route}
                />
              ))}
            </Tabs>
          </Box>

          {user.userType === UserTypes.neweyes && (
            <IconButton
              edge="start"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}

          {user.userType !== UserTypes.registered && (
            <div className={classes.search}>
              <TextField
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                placeholder="Search…"
                color="primary"
                onChange={(e) =>
                  setSearchOption({ searchText: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "#fff" }} />
                    </InputAdornment>
                  ),
                  style: { color: "#fff" },
                }}
              />
            </div>
          )}

          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={profileMenuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
        <SiteMenuDrawer open={open} onClose={(event) => setOpen(false)} />
      </AppBar>
      {renderMenu}
      {renderProfileMenu}
    </React.Fragment>
  );
};

export default Header;
