import { CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BoxRow } from "../../../../components/DataEntry/StyledFields";
import { endpoints } from "../../../../hooks/use-api";
import { useNotification } from "../../../../hooks/use-notification";
import {
  MoveNext,
  useMultiStepForm,
} from "../../../DataEntry/MultiFormContext";
import { VoucherValidator } from "../Model";

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: ['"Times New Roman"'].join(","),
    fontSize: "1.3em",
    fontWeight: "bold",
    marginBottom: "24px",
  },
  splText: {
    fontFamily: ['"Times New Roman"'].join(","),
    marginBottom: "16px",
    fontWeight: "510",
  },
  subHeader: {
    fontFamily: ['"Times New Roman"'].join(","),
    fontSize: "0.9em",
    marginTop: "8px",
    marginBottom: "8px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
  },
}));

const VisionPreQualificationResults = ({
  data,
  setFieldValue,
  isSubmitting,
}) => {
  const classes = useStyles({});
  const { setEnableNext } = useMultiStepForm();
  const [qualChecking, setQualChecking] = useState(false);
  const { set: setNotification } = useNotification();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!qualChecking) {
      // If payload did not change then we wont run the qual again
      if (!VoucherValidator.isVisionQualAlreadyRan(data)) {
        callVisiblyPrequal();
      } else {
        // if (data.visionQualResults.isVisionTestQualifies) {
        //   setEnableNext(MoveNext.Step);
        // }
        setReady(true);
      }
    }
  }, [qualChecking]);

  useEffect(() => {
    if (isSubmitting) {
      if (data.visionQualResults.isVisionTestQualifies) {
        setEnableNext(MoveNext.Step);
      }
    }
  }, [isSubmitting]);

  const callVisiblyPrequal = () => {
    var payload = VoucherValidator.getVisiblyQualPayload(data);
    setQualChecking(true);
    axios
      .post(endpoints.entity.visibly.preQual(), payload)
      .then(function (response) {
        setQualChecking(false);
        console.log(response);
        setReady(true);
        // setNotification({
        //   message: `PreQual succeeded at partner site, qualifies=${response.data.qualifies}`,
        //   type: "success",
        // });

        setFieldValue("visionQualResults", {
          isVisionTestQualifies: response.data.qualifies,
          payload: payload,
        });

        if (response.data.qualifies === true) {
          setEnableNext(MoveNext.Step);
        }
      })
      .catch(function (error) {
        setQualChecking(false);
        console.log(error);
        setNotification({
          message: `Error ${error.message}`,
          type: "error",
        });
        //moveNextFailedFn();
        //        setSavingVoucherFlag(false);
      });
  };

  // useEffect(() => {
  //   if (!isSubmitting && isVisionTestQualifies) {
  //     console.log("results");
  //     setEnableNext(MoveNext.Step);
  //   }
  // }, [isVisionTestQualifies]);

  if (!ready) {
    return <CircularProgress />;
  }

  return data.visionQualResults.isVisionTestQualifies ? (
    <BoxRow>
      <Typography className={classes.pageHeader} variant="h6" color="primary">
        The applicant qualifies for this program. Let’s get started!
      </Typography>
    </BoxRow>
  ) : (
    <>
      <p>&nbsp;</p>
      <Typography className={classes.pageHeader} variant="h6" color="error">
        Unfortunately, due to age or state of residence, the applicant does
        not qualify for the online vision test for prescription renewal. The
        applicant will need to have an eye examination to get a current
        eyeglass prescription. Please come back and complete the application
        for New Eyes eyeglasses when applicant has a current prescription,
        including pupillary distance (PD).
      </Typography>
      <p>
        You can find out more about the eligibility for the online vision test
        by clicking&nbsp;
        <a href="https://new-eyes.org/visibly">https://new-eyes.org/visibly</a>
      </p>
      <p>
        Click <a href="https://new-eyes.org/source-of-free-eye-exams">here</a>
        &nbsp; for resources that can help you find a free or low-cost eye exam
        option in your area.
      </p>
    </>
  );
};

export default VisionPreQualificationResults;
