import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import axios from "axios";
import React from "react";
import "./App.scss";
// import { createBrowserHistory as history } from "history";
import { AuthProvider } from "./hooks/use-auth";
import { NotificationProvider } from "./hooks/use-notification";
import Router from "./Router";

var runAsUser = "";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config) => {
    // Auth not required for pre-qual call
    if (!config.url.startsWith("apply/") && !config.url.startsWith("order/")) {
      const jwt = await Auth.currentSession();
      if (jwt) {
        config.headers.Authorization = `Bearer ${jwt.idToken.jwtToken}`;
        console.log(runAsUser);
        if (runAsUser && runAsUser.length > 0) {
          config.headers["override-email"] = runAsUser;
        }
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      contrastText: "white",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider onAuth={(auth) => (runAsUser = auth.overrideEmail)}>
        <React.Suspense fallback={<div> Loading... </div>}>
          <NotificationProvider>
            <Router />
          </NotificationProvider>
        </React.Suspense>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
