import React, {useState} from 'react'
import {Button, Divider, TextField, Typography} from '@mui/material';

import {endpoints} from '../../hooks/use-api'
import axios from 'axios';
import {useNotification} from '../../hooks/use-notification';
import {useAuth} from '../../hooks/use-auth';
import EntitySearch from '../Entities/EntitySearch';
import {AddressView1} from '../../common/AddressView'
import {UserTypes} from '../../common/Constants';

const PendingUserPanel = ({pendingUser, onApprove, onReject}) => {

    const { userType } = useAuth()

    const [rejectMode, setRejectMode] = useState(false)
    const [reasonNote, setReasonNote] = useState('')

    return (
        <React.Fragment>
            {userType === UserTypes.neweyes && <>
                <AddressView1 {...pendingUser.agency} title="Agency" />
                <Divider style={{ marginTop: '12px', marginBottom: '12px' }}/>
            </>}
            { rejectMode ?
                (
                    <React.Fragment>
                        <br /><br/>
                        <Typography variant='h6' color='textSecondary' component="div">
                            Please provide a brief courteous message to inform <em>{pendingUser.name}</em> of your decision to reject.
                        </Typography>
                        <br /><br/>                        
                        <TextField
                            label="Reason for rejection"
                            fullWidth
                            multiline
                            rows="4"
                            defaultValue={reasonNote}
                            onChange={e => setReasonNote(e.target.value)}
                            margin="dense"
                            variant="outlined"
                            />
                        <Button variant="contained" fullWidth color="primary" style={{ marginTop: '24px' }}
                                onClick={e => onReject(reasonNote)}>Reject</Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <br /><br/>
                        <Typography variant='h6' color='textSecondary' component="div">
                            Click "Approve" if <em>{pendingUser.name}</em> works for your agency. Click "Reject" if you do not recognize
                            <em>{pendingUser.name}</em>
                        </Typography>
                        <br /><br/>
                        <Button variant='outlined' size="small" color='primary' onClick={onApprove}>Approve</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant='outlined' size="small" color='primary' onClick={e => setRejectMode(true)}>Reject</Button>                        
                    </React.Fragment>
                )
            }            
        </React.Fragment>

    )
}

const PendingUsers = ({ agencyId, reload: reloadParent, onExit }) => {
    const { user } = useAuth();
    const { set: setNotification } = useNotification();

    const approveUser = (pendingUser, onClose, reload) => {
        axios.post(endpoints.entity.agency.approveUser(user.agency.id), pendingUser)
            .then(function (response) {
                setNotification({
                    message: `User approved to join agency`,
                    type: 'success',
                })
                onClose()
                reload()
                reloadParent()
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
            });
            //?? onClose
    }

    const rejectUser = (pendingUser, reason, onClose, reload) => {
        axios.post(endpoints.entity.agency.rejectUser(user.agency.id), { user: pendingUser, reason: reason })
            .then(function (response) {
                setNotification({
                    message: `User is rejected`,
                    type: 'success',
                })
                onClose()
                reload()
            })
            .catch(function (error) {
                setNotification({
                    message: `Error ${error.message}`,
                    type: 'error',
                });
            });
            //?? onClose
    }

    return (
        <EntitySearch
            entityApi={endpoints.entity.pendinguser}
            agencyId={agencyId}
            showUpdate={false}
            extendPanel={
                ({item, onClose, reload}) =>
                    <PendingUserPanel
                        pendingUser={item}
                        onApprove={() => approveUser(item, onClose, reload)}
                        onReject={(reason) => rejectUser(item, reason, onClose, reload)} />
            }
        />
        // <EntityHomeApi
        //     api={endpoints.agency.pendingUser}
        //     id={user.agency.id}
        //     extendPanel={({item, onClose, reload}) => <PendingUserPanel pendingUser={item} onClick={() => approveUser(item, onClose, reload)} onClose={onClose} />}
        // />
    )
}

export default PendingUsers
