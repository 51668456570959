import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../hooks/use-api";
import { useNotification } from "../../hooks/use-notification";
import logo from "../../images/logo.png";
import { BoxRow, MainContainer, MainContent } from "../DataEntry/StyledFields";

const VisionTest = (props) => {
  const { identifier } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const { set: setNotification } = useNotification();
  const [saving, setSaving] = useState(false);

  const sendRequest = () => {
    if (!saving) {
      setSaving(true);
      axios
        .post(endpoints.apply.visionTest(identifier), {
          voucherNumber: voucherNumber,
          firstName: firstName,
          lastName: lastName,
        })
        .then(function (response) {
          if (response.data.valid) {
            window.location.href = response.data.url;
          } else {
            setNotification({
              message: "Failed to create link",
              type: "error",
            });
            setSaving(false);
          }
          //?? onExit(response.data);
        })
        .catch(function (error) {
          setNotification({
            message: `Error ${error.message}`,
            type: "error",
          });
          setSaving(false);
        });
    }
  };

  return (
    <MainContainer>
      <MainContent>
        <BoxRow>
          <img src={logo} width="300px" alt="logo" />
        </BoxRow>
        <BoxRow>
          <Typography variant="h6">
            Please confirm the following information when you are ready:
          </Typography>
        </BoxRow>
        <BoxRow>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            required
          />
        </BoxRow>
        <BoxRow>
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            required
          />
        </BoxRow>
        <BoxRow>
          <TextField
            label="Voucher Number"
            value={voucherNumber}
            onChange={(e) => setVoucherNumber(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            required
          />
        </BoxRow>
        <BoxRow>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={saving}
            onClick={(e) => sendRequest()}
            sx={{ p: 2, fontSize: 24 }}
          >
            Take me to online vision test
          </Button>
        </BoxRow>
      </MainContent>
    </MainContainer>
  );
};

export default VisionTest;
