import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BoxRow } from "../../../../components/DataEntry/StyledFields";
import {
  MoveNext,
  useMultiStepForm,
} from "../../../DataEntry/MultiFormContext";
import { VoucherValidator } from "../Model";
import { CheckBoxRow } from "../WizardPage";

const item = {
  id: 4,
  type: "checkbox",
  label: "I confirm the applicant is able to meet the above requirements.",
  property: "canTakeVisionTest",
  valuefn: (data) => data["canTakeVisionTest"],
};

const VistionTestInstructions = ({ data, isSubmitting, isValidating }) => {
  const { setEnableNext } = useMultiStepForm();

  useEffect(() => {
    if (isSubmitting && !isValidating) {        
      if (VoucherValidator.canTakeVisionTest(data)) {
        setEnableNext(MoveNext.Page);
      } else {
        setEnableNext(MoveNext.ValidationRequired);
      }
    }
  }, [data, isSubmitting, isValidating]);

  return (
    <>
      <BoxRow>
        <Typography variant="h6" color="textSecondary" component="div">
          In order to perform the Vision test the applicant requires:
        </Typography>
        <Typography component="div" style={{ lineHeight: "34px" }}>
          <ul>
            <li>Email address of applicant</li>

            <li>A computer and smartphone</li>

            <li>A stable internet connection</li>

            <li>Approximately 10 feet of space</li>

            <li>A prior prescription</li>

            <li>Approximately 10-15 minutes of time</li>
          </ul>
        </Typography>
        {/*<div style={{ marginBotton: '16px' }}>
                <Link href="https://www.youtube.com/watch?v=uhZr6Wq5H60" onClick={() => {}} target='_blank' underline='always'>
                    Learn more, see a video of the test
                </Link>
</div>*/}
        Please view short video before proceeding
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/uhZr6Wq5H60"
          title="Visibly Demo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </BoxRow>

      <CheckBoxRow item={item} />
    </>
  );
};

export default VistionTestInstructions;
