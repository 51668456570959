import { Grid, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { addYears } from "date-fns";
import { Field, useField } from "formik";
import React from "react";
import { BoxItem, BoxRow } from "../../../components/DataEntry/StyledFields";
import DatePickerField from "../../DataEntry/FormFields/DatePickerField";

// const useStyles = makeStyles((theme) => ({
//   splText: {
//     fontFamily: ['"Times New Roman"'].join(","),
//   },
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));

const RadioGroupRow = ({ item: { id, property, label, options }, data }) => {
  const [field, meta, helper] = useField(property);
  const { touched, error } = meta;
  //?? const isError = touched && error && true;
  // const classes = useStyles({});
  var labelName = `label_${id}`;

  return (
    <BoxRow>
      <Typography variant="h6">
        {typeof label === "function" ? label(data) : label}
      </Typography>
      <BoxItem>
        <div role="group" aria-labelledby="my-radio-group">
          {options.map((m, index) => (
            <label
              key={"optionlabel_" + index}
              style={{ "paddingRight": "16px" }}
            >
              <Field
                {...field}
                type="radio"
                value={m.value}
                validate={(value) =>
                  value === "" ? "Please select one option" : ""
                }
              />
              {m.label}
            </label>
          ))}
        </div>
        {error && <ErrorRow label={error} />}
      </BoxItem>
    </BoxRow>
  );
};

const DateRow = ({
  item: { id, label, inputLabel, property },
  min = 100,
  onChangeOverride,
}) => {
  // const classes = useStyles({});
  var labelName = `label_${id}`;
  return (
    <BoxRow>
      <Typography variant="h6">{label}</Typography>
      <BoxItem>
        <DatePickerField
          name={property}
          label={inputLabel}
          format="MM/dd/yyyy"
          openTo="year"
          views={["year", "month", "date"]}
          minDate={addYears(new Date(), -min)}
          maxDate={new Date()}
          fullWidth
          onChangeOverride={onChangeOverride}
          helperText={"Date Format (mm/dd/yyyy)"}
        />
      </BoxItem>
    </BoxRow>
  );
};

const AutoCompleteRow = ({
  item: { id, label, property },
  options,
  onChange,
}) => {
  // const classes = useStyles({});
  const [field, meta, helper] = useField(property);
  const { initialValue, value } = meta;
  const { setValue } = helper;

  const _onChange = (e, value) => {
    // if onChange is provided then call that else set the value of the property
    var newValue = value !== null ? value : initialValue;
    if (onChange) {
      onChange({ [property]: newValue });
    } else {
      setValue(newValue);
    }
  };

  var labelName = `label_${id}`;
  return (
    <BoxRow>
      <Typography variant="h6">{label}</Typography>
      <BoxItem>
        <Autocomplete
          {...field}
          // id={property}
          // value={value}
          // name={property}
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          size="small"
          onChange={_onChange}
          renderInput={(params) => (
            <TextField
              margin="normal"
              label={property}
              fullWidth
              name={property}
              {...params}
            />
          )}
        />
      </BoxItem>
    </BoxRow>
  );
};

const ErrorRow = ({ label }) => {
  // const classes = useStyles({});
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Typography variant="h6" color="error" style={{ marginTop: "12px" }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

// const ShowErrorRow = ({ errors, property }) => {
//   return (
//     <>
//       {errors && errors[property] && (
//         <Grid container direction="row">
//           <Grid item xs={12}>
//             <Typography
//               variant="subtitle2"
//               color="error"
//               style={{ marginTop: "12px" }}
//             >
//               {errors[property]}
//             </Typography>
//           </Grid>
//         </Grid>
//       )}
//     </>
//   );
// };

const CheckBoxRow = ({ item: { id, property, label, pre, style } }) => {

  style = style || {};

  // const classes = useStyles({});
  var labelName = `label_${id}`;
  return (
    <Grid
      container
      direction="row"
      // style={{ marginTop: "54px", marginBottom: "54px" }}
      style={{ marginTop: "27px" }}
    >
      {pre && (
        <Grid item xs={12}>
          {pre}
        </Grid>
      )}
      <Grid item xs={1}>
        <Field
          type="checkbox"
          name={property}
          label={property}
          required
          id={labelName}
          //
          // checked={value}
          // onChange={(e, checked) => onChange({ [property]: checked })}
        />
      </Grid>
      <Grid item xs={11}>
        <label htmlFor={labelName} style={style}>{label}</label>
      </Grid>
    </Grid>
  );
};

const WizardPage = ({ items, data, errors, setData, enableNextFn }) => {
  return (
    <Box>
      {items.map((m, index) => {
        if (m.visiblefn(data)) {
          switch (m.type) {
            case "oneOf":
              return <RadioGroupRow key={index} item={m} data={data} />;
            case "date":
              return (
                <DateRow
                  key={index}
                  item={m}
                  min={m.min}
                  value={m.valuefn(data)}
                  onChange={(item) => setData({ ...data, ...item })}
                />
              );
            case "error":
              return <ErrorRow key={index} label={m.label} />;
            case "checkbox":
              return (
                <CheckBoxRow
                  key={index}
                  item={m}
                  value={m.valuefn(data)}
                  onChange={(item) => setData({ ...data, ...item })}
                />
              );
            case "component":
              return m.component({
                key: index,
                data: data,
                onChange: (item) => setData({ ...data, ...item }),
              });
          }
        }
      })}
    </Box>
  );
};

export { WizardPage, CheckBoxRow, DateRow, AutoCompleteRow };
