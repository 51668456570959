import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DollarIcon from '@mui/icons-material/AttachMoney';
import {Typography} from '@mui/material';
import cx from 'classnames'
import {format} from 'date-fns'

const getIcon = status => {
    if(status === 'approved') {
        return <CheckIcon />
    } else if(status === 'paid' || status === 'redeemed') {
        return <DollarIcon />
    } else if(status === 'rejected') {
        return <ClearIcon />
    } else {
        return <NavigateNextIcon />
    }
}

const VoucherAction = ({ datetime, status, note = '' }) => {

    // useEffect(() => {
    //     // GMT
    //     console.log(datetime)
    //     // GMT to local
    //     console.log(format(new Date(datetime + ".000Z"), 'MMM dd, yyyy hh:mm aa'))
    // }, [datetime])

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    {getIcon(status)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText 
                secondary={
                    <Typography variant='body2' component="div" style={{ wordBreak: 'break-word', color: status === 'rejected' ? 'red' : 'inherit' }}>
                        { note }
                    </Typography>
                }
            >
                <Typography variant='button' className={cx("voucher-status", status)}>
                    {status}
                </Typography>
                <Typography variant='body2' component="span" style={{ marginLeft: '24px' }}>
                    { format(new Date(datetime + ".000Z"), 'MMM dd, yyyy hh:mm aa') }
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

const VoucherActionList = ({ actions = [] }) => (
    <List>
        {actions.map((action, idx) => <VoucherAction key={idx} {...action} />)}
    </List>
)

export default VoucherActionList