import React, {useEffect, useState} from "react";
import {Field, useField} from "formik";
import Grid from "@mui/material/Grid";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker} from "formik-material-ui-pickers";

export default function DatePickerField(props) {
  const { onChangeOverride, ...restProps } = props;
  const [field, meta, helper] = useField(props);
  // const { touched, error } = meta;
  const { setValue } = helper;
  // const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  // useEffect(() => {
  //   console.log("isError=" + isError + ", error=" + error);
  // }, [isError, error]);

  useEffect(() => {
    //console.log("value=" + value);
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  function updateValue(newValue) {
    if (onChangeOverride) {
      onChangeOverride({ [props.name]: newValue });
    } else {
      setValue(newValue);
    }
  }

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        updateValue(ISODateString);
      } catch (error) {
        updateValue(date);
      }
    } else {
      updateValue(date);
    }
  }

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
          component={KeyboardDatePicker}
          {...restProps}
          value={selectedDate}
          onChange={_onChange}
          required
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
