import React, {createContext, useContext, useState} from "react";

import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import {amber, green} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import makeStyles from "@mui/styles/makeStyles";

const NotificationType = {
  success: "success",
  warning: "warning",
  error: "error",
  info: "info",
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.black,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const Notification = ({ classes, message, type, onClose }) => {
  const Icon = variantIcon[type];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={message && message.length > 0}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};
const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const classes = useStyles();
  const [message, setMessage] = useState();
  const [type, setType] = useState(NotificationType.info);

  const set = ({ type = NotificationType.info, message }) => {
    setMessage(message);
    setType(type);
  };

  const clear = () => setMessage(null);

  return (
    <NotificationContext.Provider value={{ set, clear }}>
      <Notification
        classes={classes}
        message={message}
        type={type}
        onClose={clear}
      />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, NotificationType, useNotification };
