import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import logo from "../../images/logo.png";
import { MainContainer, MainContent } from '../DataEntry/StyledFields';
import { SystemMessages } from '../../common/Constants';

const ThankYouForApplying = (props) => {

    useEffect(() => {
        console.log("ThankYouForApplying component")
    }, [])

    return (
        <MainContainer>
            <MainContent>
                <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
                    <img src={logo} width="300px" alt="logo" />
                </Box>

          <Typography variant="h6">{SystemMessages.PAYMENT_RECEIVED}</Typography>
                <p />
                <a href="https://new-eyes.org/">Click here</a> to visit our home page.
            </MainContent>
        </MainContainer>
    )

}

export default ThankYouForApplying;
