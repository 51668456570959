import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { calculateAge, displayDate } from "./Constants";

const ContactView = ({ email, phone, phone_extension }) => (
  <React.Fragment>
    {email && (
      <Grid container>
        <Grid item xs={2}>
          <AlternateEmailIcon color="primary" />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="caption" display="block" color="textPrimary">
            {email}
          </Typography>
        </Grid>
      </Grid>
    )}
    {phone && (
      <Grid container>
        <Grid item xs={2}>
          <PhoneIcon color="primary" />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="caption" display="block" color="textPrimary">
            {phone}{" "}
            {phone_extension && (
              <React.Fragment>Ext. {phone_extension}</React.Fragment>
            )}{" "}
          </Typography>
        </Grid>
      </Grid>
    )}
  </React.Fragment>
);

const AgeComponent = ({ sex, birth_date }) => {
  useEffect(() => {
    console.log(`AgeComponent.birth_date.${birth_date}`);
    console.log(`AgeComponent.birth_date.${new Date(birth_date)}`);
  }, [birth_date]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box borderLeft={1} paddingLeft={1} borderColor="#A8A4A3">
          <Typography variant="caption" display="block" color="textPrimary">
            SEX: {sex}
          </Typography>
          <Typography variant="caption" display="block" color="textPrimary">
            AGE: {calculateAge(birth_date)}
          </Typography>
          <Typography variant="caption" display="block" color="textPrimary">
            DOB: {displayDate(birth_date)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const AgeComponent2 = ({ sex, birth_date }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="caption" display="block" color="textPrimary">
          SEX: {sex}, AGE: {calculateAge(birth_date)}, DOB:{" "}
          {displayDate(birth_date)}
        </Typography>
      </Grid>
    </Grid>
  );
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

const AddressView = ({
  address1,
  address2,
  city,
  state,
  county,
  zip,
  phone,
  isHome = false,
}) => (
  <React.Fragment>
    {(address1 || address2 || city || state || zip) && (
      <Grid container>
        <Grid item xs={2}>
          {isHome ? (
            <HomeIcon color="primary" />
          ) : (
            <BusinessIcon color="primary" />
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography variant="caption" display="block" color="textPrimary">
            {address1}
          </Typography>
          {address2 && address2 !== "" && (
            <Typography variant="caption" display="block" color="textPrimary">
              {address2}
            </Typography>
          )}
          <Typography variant="caption" display="block" color="textPrimary">
            {city}, {state} - {zip}
          </Typography>
          {county && (
            <Typography variant="caption" display="block" color="textPrimary">
              {county} county
            </Typography>
          )}
          {phone && (
            <Typography variant="caption" display="block" color="textPrimary">
              {formatPhoneNumber(phone)}
            </Typography>
          )}
        </Grid>
      </Grid>
    )}
  </React.Fragment>
);

const AddressView1 = ({
  name,
  title,
  address1,
  address2,
  city,
  state,
  county,
  zip,
  email,
  phone,
  phone_extension,
  guardian,
  sex,
  birth_date,
  tax_id,
  onEdit,
}) => (
  <React.Fragment>
    <Typography variant="button" color="textSecondary">
      {title}
    </Typography>
    <Grid container>
      <Grid item xs={10}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={2}>
        {onEdit && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onEdit}
          >
            Update
          </Button>
        )}
      </Grid>
      {birth_date && (
        <Grid item xs={12}>
          <AgeComponent2 sex={sex} birth_date={birth_date} />
          {guardian && (
            <Typography variant="body2" component="div">
              Guardian: {guardian}
            </Typography>
          )}
        </Grid>
      )}
      {tax_id && (
        <Grid item xs={12}>
          <Typography variant="body2" component="div">
            Tax ID: {tax_id}
          </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <AddressView
          address1={address1}
          address2={address2}
          city={city}
          state={state}
          zip={zip}
          county={county}
          isHome={birth_date ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <ContactView
          email={email}
          phone={phone}
          phone_extension={phone_extension}
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

const AddressView2 = ({
  name,
  title,
  address1,
  address2,
  city,
  state,
  zip,
  email,
  phone,
  phone_extension,
  isHome = false,
}) => (
  <React.Fragment>
    <Typography variant="button" color="textSecondary">
      {title}
    </Typography>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <AddressView
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        isHome={isHome}
      />
      <ContactView
        email={email}
        phone={phone}
        phone_extension={phone_extension}
      />
    </Grid>
  </React.Fragment>
);

const ApplicantCard = ({
  name,
  title,
  address1,
  address2,
  city,
  state,
  county,
  zip,
  guardian,
  sex,
  birth_date,
  phone,
  onEdit,
}) => (
  <React.Fragment>
    <Typography variant="button" color="textSecondary">
      {title}
    </Typography>
    <Grid container>
      <Grid item xs={10}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={2}>
        {onEdit && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onEdit}
          >
            Update
          </Button>
        )}
      </Grid>
      {guardian && (
        <Grid item xs={12}>
          <Typography variant="body2" component="div">
            Guardian: {guardian}
          </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <AddressView
          address1={address1}
          address2={address2}
          city={city}
          state={state}
          zip={zip}
          county={county}
          phone={phone}
          isHome={true}
        />
      </Grid>
      <Grid item xs={6}>
        <AgeComponent sex={sex} birth_date={birth_date} />
      </Grid>
    </Grid>
  </React.Fragment>
);

export { AddressView1, AddressView2, ApplicantCard, AgeComponent };
