import { CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import {
  MainContainer,
  MainContent,
} from "../../../components/DataEntry/StyledFields";
// import logo from "../../../images/ne-logo.png";
import MainForm from "./MainForm";

const EmptyIndUser = {
  identifier: "",
  firstName: "",
  lastName: "",
  email: "",
};

const CreateVoucher = ({
  individualApplication = false,
  indUser = EmptyIndUser,
  onExit,
  onClose,
}) => {
  useEffect(() => {
    if (!individualApplication) {
      // Redirect to path without the form at the end
    }
  }, [individualApplication]);

  return (
    <React.Fragment>
      <CssBaseline />
      <MainContainer>
        <MainContent>
          {/* <BoxRow>
            <img src={logo} width="100px" alt="logo" />
          </BoxRow> */}
          <MainForm
            individualApplication={individualApplication}
            indUser={indUser}
            onExit={onExit}
            onClose={onClose}
          />
        </MainContent>
      </MainContainer>
    </React.Fragment>
  );
};

export default CreateVoucher;
