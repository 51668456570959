import React, {createContext, useContext, useEffect, useState,} from 'react';
import {useAsync} from 'react-async';
import { Amplify, Auth, Hub } from 'aws-amplify';
import {aws_config} from '../aws_config';
import {UserTypes} from '../common/Constants';
import axios from 'axios';
import {endpoints} from './use-api';

Amplify.configure(aws_config);
Amplify.Logger.LOG_LEVEL = 'DEBUG';

const EmptySearchOption = {
  voucherStatus: 'pending',
  cannedDateRange: 5,
  startDate: new Date(),
  endDate: new Date(),
  sortBy: 'date',
  searchText: '',
}

const AuthContext = createContext();

const AuthProvider = props => {
  const { onAuth } = props;
  const auth = useAuthProvider();
  if (onAuth) {
    onAuth(auth)
  }
 
  return <AuthContext.Provider value={auth} {...props} />;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const useAuthProvider = () => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userType, setUserView] = useState(UserTypes.neweyes);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchOption, setSearchOption] = useState(EmptySearchOption);

  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "autoSignIn":
          setCognitoUser(data);
          break;
        case "signIn":
          setCognitoUser(data);
          break;
        case 'signIn_failure':
          console.log('Sign in failure', data);
          setCognitoUser(null);
          break;        
        case "signOut":
          setCognitoUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  const getUser = async () => {
    try {
      console.log("Getting user");
      const currentUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(currentUser);
    } catch (error) {
      console.error(error);
      console.log("Not signed in");
    }
  };  

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    if (cognitoUser) {
      setIsLoading(true);
      axios.get(endpoints.entity.user.loadProfile()).then(function(response) {
        setUser(response.data);
        setIsLoading(false);
        setApiError(null);
      })
      .catch(function (error) {
        setUser(null);
        setApiError(error);
        if (error.code && error.code === "ERR_NETWORK") {
          console.log("Unable to connect to server. Please check your internet connection.");
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
    } else {
      setUser(null);
      setIsLoading(false);
      setApiError(null);
    }
  }, [cognitoUser]);

  // Methods
  const login = (usernameOrEmail, password, reCaptchaToken) => 
    Auth.signIn(usernameOrEmail, password, {
      captcha: reCaptchaToken,
    })
      .then(getUser)
      .catch(err => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password';
        }

        throw err;
      });

  const logout = () =>
    Auth.signOut().then(getUser);

  const register = (email, password, attributes, reCaptchaToken) => {

    const signUpData = {
      username: email,
      password: password,
      attributes: attributes,
      validationData: {
        "captcha": reCaptchaToken,
      },
    };

    return Auth.signUp(signUpData);
    // Auth.signUp(signUpData).then(data => {
    //   console.log('Sign-up successful', data);
    // })
    // .catch(error => {
    //   console.error('Error signing up', error);
    // });
  }

  const registerConfirm = (email, code) => Auth.confirmSignUp(email, code);

  const resendSignupVerificationCode = email => Auth.resendSignUp(email);

  const resetPassword = email => Auth.forgotPassword(email);

  const resetPasswordConfirm = (email, code, password) =>
    Auth.forgotPasswordSubmit(email, code, password);  

  const completeNewPassword = (cognitoUser, password, attributes) =>
    Auth.completeNewPassword(cognitoUser, password, attributes);

  const [overrideEmail, setOverrideEmail] = useState("");

  return {
    user: user,
    userType,
    setUserView,
    searchOpen,
    setSearchOpen,
    searchOption,
    setSearchOption,
    // isAuthenticated,
    isLoading,
    login,
    logout,
    completeNewPassword,
    register,
    registerConfirm,
    resendSignupVerificationCode,
    resetPassword,
    resetPasswordConfirm,
    overrideEmail,
    setOverrideEmail,
    apiError,
  };
};

export { AuthProvider, useAuth, EmptySearchOption };
