import React, {useEffect, useState} from 'react'
import {FormGroup, FormLabel, Grid, TextField, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
//import NumberFormattedInput from '../../../common/NumberFormattedInput';

const useStyles = makeStyles(theme => ({
    textField: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '40%',
    },
  }));

const EditFinancialInfo = ({ voucher_detail, isChild, onChange }) => {

    let { household_adults = "", household_children = "", total_income = "", total_expense = "", income_note = "" } = voucher_detail

    const classes = useStyles();

    const [incomeNotesRequired, setIncomeNotesRequired] = useState(false)

    useEffect(() => {
        // If the income is 0 then ask them to put some notes
        setIncomeNotesRequired(total_income <= 0.0)
    }, [total_income])

 
    return (
        <div>            
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h5' className='text-highlight'>Financial Information</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend" color="primary"><b>Number of family members living in household</b></FormLabel>
                    <FormGroup row>
                        <TextField 
                            label='Adults'
                            value={household_adults}
                            type="number"
                            onChange={(e) => onChange({ household_adults: e.target.value })}
                            InputProps={{ inputProps: { min: 1, max: 10 } }}
                            margin="dense"
                            variant="outlined"
                            className={classes.textField}
                            required
                        />
                        <TextField
                            label='Children'
                            value={household_children}
                            type="number"
                            InputProps={{ inputProps: { min: isChild ? 1 : 0, max: 10 } }}
                            onChange={(e) => onChange({ household_children: e.target.value })}
                            margin="dense"
                            variant="outlined"
                            className={classes.textField}
                            required
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend" color="primary"><b>Monthly household income and expenditure</b></FormLabel>
                    <FormGroup row>
                        <TextField 
                            label='Income'
                            value={total_income}
                            onChange={e => onChange({ total_income:  parseFloat(e.target.value) })}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            className={classes.textField}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                // inputComponent: NumberFormattedInput,
                                inputProps: { min: 0, max: 1000000, step: "0.01" }
                            }}
                            required
                        />
                        <TextField 
                            label='Expenditure'
                            value={total_expense}
                            onChange={(e) => onChange({ total_expense: parseFloat(e.target.value) })}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            className={classes.textField}                         
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                // inputComponent: NumberFormattedInput,
                                inputProps: { min: 0, max: 1000000, step: "0.01" }
                            }}
                            required
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Income notes'
                        placeholder={incomeNotesRequired
                                        ? 'Applications cannot be evaluated without household income/expenses details. Please provide an explanation for zero income in "income notes"'
                                        : ''}
                        value={income_note}
                        onChange={(e) => onChange({ income_note: e.target.value })}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        rows={6}
                        maxRows={12}
                        required={incomeNotesRequired}
                        InputProps={{
                            inputProps: { maxLength: 255 }
                        }}
                        multiline
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default EditFinancialInfo